import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAdjustment'
})
export class TimeAdjustmentPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case '00:00:00Z':
        return '00:00';
      case '01:00:00Z':
        return '01:00';
      case '02:00:00Z':
        return '02:00';
      case '03:00:00Z':
        return '03:00';
      case '04:00:00Z':
        return '04:00';
      case '05:00:00Z':
        return '05:00';
      case '06:00:00Z':
        return '06:00';
      case '07:00:00Z':
        return '07:00';
      case '08:00:00Z':
        return '08:00';
      case '09:00:00Z':
        return '09:00';
      case '10:00:00Z':
        return '10:00';
      case '11:00:00Z':
        return '11:00';
      case '12:00:00Z':
        return '12:00';
      case '13:00:00Z':
        return '13:00';
      case '14:00:00Z':
        return '14:00';
      case '15:00:00Z':
        return '15:00';
      case '16:00:00Z':
        return '16:00';
      case '17:00:00Z':
        return '17:00';
      case '18:00:00Z':
        return '18:00';
      case '19:00:00Z':
        return '19:00';
      case '20:00:00Z':
        return '20:00';
      case '21:00:00Z':
        return '21:00';
      case '22:00:00Z':
        return '22:00';
      case '23:00:00Z':
        return '23:00';
      default:
        return value;
    }
  }
}

import { NgxSpinnerService } from 'ngx-spinner';
import { Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { AutomaticWeatherReport } from 'src/app/services/automatic-weather-report/automatic-weather-report.service';
import { GenerateMannedReport, ReportCityListDataModel, ReportCityListModelAPI, WeatherParamsListDataModel, WeatherParamsListModelAPI, PreviewAdvanceCustomReport } from 'src/app/models/automatic-weather-report/automatic-weather-report.model';
import { GlobalService } from 'src/app/shared/utilities/global';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UsersService } from 'src/app/services/users/users.service';
import { NCMClientModelAPI } from 'src/app/models/users/users.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';
import { CreateUserGroupModelAPI, ListUserGroupModelAPI } from 'src/app/models/user-groups/user-groups.model';
import { EmailGroupsComponent } from 'src/app/shared/email-groups/email-groups.component';
import { log } from 'console';
import { ParametersMap, ArabianParametersMap, RedSeaParametersMap, CustomReportParametersMap, MashaerReportParametersMap } from 'src/app/models/manned-forecast/manned-forecast.model';

export class listOrganizationResponseModel {
  success: boolean;
  data: listOrganization[];
  paginationVariables: PaginationVariables;
  message: string;
  constructor(){
    this.data = [];
  }
}
export class listOrganization {
  id: number
  code: string
  organizationType: string
  nameEn: string
  nameAr: string
  email: string
  city: any
  state: any
  zip: any
  country: any
}
interface RegionModel {
  id: number;
  name_en: string;
  name_ar: string;
  longitude: string;
  latitude: string;
}

interface GovernateModel extends RegionModel {
  nameAr: string;
  nameEn: string;
}

@Component({
  selector: 'app-automatic-report',
  templateUrl: './automaticreportpage.component.html',
  styleUrls: ['./automaticreportpage.component.scss']
})
export class AutomaticReportComponent {
  private subscriptions: Subscription = new Subscription();
  @ViewChild('fileBtn', { static: false }) fileInput: ElementRef;
  fileName: string;
  base64String: any;
  today: Date;
  isUpdate: boolean = false;
  isAlreadyPublished: boolean = false;
  language:string;
  automaticdetailPage: any;

  allPreviewData: any;
  cityForm: FormGroup;
  weatherForm: FormGroup;
  statesForm: FormGroup;
  reporttitleForm: FormGroup;
  channelForm: FormGroup;
  currentdate: any;
  nextdate: any;
  oneweekdate:any;

  reporttypeid: number;
  reporttypekey: string;
  descriptionEn: string;
  descriptionAr: string;
  titleEn: string;
  titleAr: string;

  CityList: ReportCityListDataModel[];
  WeatherParamsList: WeatherParamsListDataModel[];

  groupedWeatherParamsList: { groupKey: string, parameters: WeatherParamsListDataModel[] }[] = [];
  groupedParams: any[];

  currentPageForOrganizationUsers: number = 1;
  organizationUsers: listOrganizationResponseModel =  new listOrganizationResponseModel();

  organizationarray: number[] = [];
  organizationdropdown:boolean = false;
  organizationcheck: boolean = false;

  currentPageForUserGroups: number = 1;
  listUserGroups: CreateUserGroupModelAPI[];
  ListPagination: PaginationVariables;

  enableEmailGroups: boolean = false;
  selectedEmailGroups: number[] = [];
  allRegions: any;
  governatesList: any[] = [];
  filteredGov: any[] = [];
  allGovernatesIds: string[] = [];
  searchControl = new FormControl('');
  regionPage: any;
  isAdvanceCustomReport: boolean = true;
  isMannedReport: boolean = false;
  isHistoricalDays: boolean = true;
  isForecastedDays: boolean = false;
  advanceTitleForm: FormGroup;
  selectedGovernates = new FormControl<number[]>([], {
    validators: Validators.required
  });
  // filterGovernorates = new FormControl<string>('',);
  filterGovernorates = new FormControl('');
  governates: any[] = [];
  historicalDays:number | null = null;
  forecastedDays:number | null = null;
  todayDate: any;
  historicalDate: any;
  forecastedDate: any;
  reportsForm: FormGroup;
  seaReportsForm: FormGroup;
  customReportsForm: FormGroup;
  mashaerReportsForm: FormGroup;
  alldata:any;
  currentDate: { year: number; month: number; day: number; };
  historicalMinStartDate: { year: number; month: number; day: number; };
  historicalMaxStartDate: { year: number; month: number; day: number; };
  startDate: { year: number; month: number; day: number; };
  forecastMinStartDate: { year: number; month: number; day: number; };
  forecastMaxStartDate: { year: number; month: number; day: number; };
  endDate: { year: number; month: number; day: number; };
  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private languageService: LanguageService,
    private translate: TranslateService,
    private activated: ActivatedRoute,
    private automaticWeatherReportservice: AutomaticWeatherReport,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private userGroupService:UserGroupsService,
    private userService: UsersService,
    private alertService: AlertService,
    private mannedForecastService: MannedForecast,
  ) {
    this.getParams()
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticdetailPage = res;
      });
      this.translate.get("CreateEWS").subscribe((res) => {
        this.regionPage = res;
      });
    }));
    const dateNow = new Date().toISOString()
    const fromDate = new Date(dateNow);

    fromDate.setHours(0,0,0,0);
    const toDate = new Date(fromDate.getTime() + 9 * 24 * 60 * 60 * 1000);
    toDate.setHours(0,0,0,0);
    const oneDate = new Date(fromDate.getTime() + 1 * 24 * 60 * 60 * 1000);
    oneDate.setHours(0,0,0,0);
    this.currentdate = this.datePipe.transform(fromDate, 'yyyy-MM-dd');
    localStorage.setItem("current_date",this.currentdate);
    this.nextdate = this.datePipe.transform(oneDate, 'yyyy-MM-dd','UTC');
    localStorage.setItem("next_date",this.nextdate);
    this.oneweekdate = this.datePipe.transform(toDate, 'yyyy-MM-dd','UTC');
    localStorage.setItem("oneweekdate", this.oneweekdate);
    let reportTitle: string | null = null;

    if (this.reporttypekey === 'red-sea-report') {
      reportTitle = 'Red Sea Report';
    } else if (this.reporttypekey === 'arabian-gulf-report') {
      reportTitle = 'Arabian Gulf Report';
    }
    else {
      reportTitle = null;
    }
    this.reporttitleForm = this.fb.group({
      Report_Title: [reportTitle]
    });
  }

  ngOnInit(): void {
    // this.reporttitleForm = this.fb.group({
    //   Report_Title: [this.reporttypekey === 'red-sea-report' || this.reporttypekey === 'arabian-gulf-report' ? this.reporttypekey  : null]
    // });
    this.advanceTitleForm = this.fb.group({
      titleEn: [''],
      titleAr: [''],
      descriptionEn: [''],
      descriptionAr: ['']
    });
    this.weatherForm = this.fb.group({});
    this.statesForm = this.fb.group({
      Surface_Wind: [''],
      Wave_Height: [''],
      State_of_the_Sea: [''],
      Surface_Wind_ar: [''],
      Wave_Height_ar: [''],
      State_of_the_Sea_ar: ['']
    });
    this.channelForm = this.fb.group({
      publicPortal: [null],
      ajwaaPortal: [],
      allOrganizations: [{
        value: false,
        disabled: true
      }],
      selectOrganizations: [{
        value: false,
        disabled: true
      }],
      selectOrganizationsArray: [],
      sendEmail: [],
      addEmails: [],
      addEmailGroup: [],
      count: this.fb.array([]),
      twitter: [null]
    });
    this.reportsForm = this.fb.group({
      weathersymbol:true,
      temperature: false,
      humidity: false,
      windspeed: false,
      winddirection: false,
      windpressure: false,
      precipitation: false,
      probability: false,
      slipperyroad: false,
    });
    this.seaReportsForm = this.fb.group({
      weathersymbol:true,
      temperature: false,
      humidity: false,
      windspeed: false,
      winddirection: false,
      waveheight: false,
      visibility: false,
      hightide: false,
      lowtide: false,
    });
    this.customReportsForm = this.fb.group({
      weathersymbol:true,
      temperature: false,
      humidity: false,
      windspeed: false,
      winddirection: false,
      pressure: false,
      precipitation: false,
      probability: false,
      slipperyroad: false,
    });
    this.mashaerReportsForm = this.fb.group({
      weathersymbol:true,
      temperature: false,
      humidity: false,
      windspeed: false,
      winddirection: false,
      pressure: false,
      precipitation: false,
      probability: false,
    });
    // this.getcitylist();
    if(this.reporttypekey == 'advance-custom-weather-report'){
      this.getGovernates();
      this.cityForm = this.fb.group({
        selectedGovernates: [[]]
      });
      this.filterGovernorates.valueChanges.subscribe(() => {
        this.filterGovernates();
      });
    }
    else if(this.reporttypekey == 'custom-weather-report'){
      this.cityForm = this.fb.group({});
      this.getAllRegions();
    }
    else{
      this.cityForm = this.fb.group({});
      this.getcitylist();
    }
    this.getweatherparamaters();
    this.getOrganizationUsersForDropdown();
    this.addcount();
    this.getCurrentDate();
  }
  getCurrentDate() {
    const currentDate = new Date();
    this.currentDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };

    this.initializeHistoricalDates();
    this.initializeForecastDate();
  }

  initializeHistoricalDates() {
    const currentDate = new Date();

    const minDate = new Date(currentDate);
    minDate.setDate(minDate.getDate() - 29); // 30 days including today

    this.historicalMinStartDate = {
      year: minDate.getFullYear(),
      month: minDate.getMonth() + 1,
      day: minDate.getDate()
    };

    this.historicalMaxStartDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };
  }
  initializeForecastDate() {
    const currentDate = new Date();

    const maxDate = new Date(currentDate);
    maxDate.setDate(maxDate.getDate() + 14); // 15 days including today

    this.forecastMinStartDate = {
      year: currentDate.getFullYear(),
      month: currentDate.getMonth() + 1,
      day: currentDate.getDate()
    };

    this.forecastMaxStartDate = {
      year: maxDate.getFullYear(),
      month: maxDate.getMonth() + 1,
      day: maxDate.getDate()
    };
  }
  onAdvanceClick(event: any){
    if(event.target.checked){
      this.isAdvanceCustomReport = true;
      this.isMannedReport = false;
    }
    else{
      this.isAdvanceCustomReport = false;
    }
  }
  onMannedClick(event: any){
    if(event.target.checked){
      this.isMannedReport = true;
      this.isAdvanceCustomReport = false;
    }
    else{
      this.isMannedReport = false;
    }
  }
  onHistoricalDays(event:any){
    if(event.target.checked){
      this.isHistoricalDays = true;
      this.isForecastedDays = false;
    }
    else{
      this.isHistoricalDays = false;
    }
  }
  onForecastedDays(event:any){
    if(event.target.checked){
      this.isForecastedDays = true;
      this.isHistoricalDays = false;
    }
    else{
      this.isForecastedDays = false;
    }
  }
  handleFileChange(event: any): void {
    this.spinner.show();
    const file: File = event.target.files[0];
    if (file) {
      const fileName = file.name;
      const parts = fileName.split('.');
      const filetype = parts.length > 1 ? parts.pop().toLowerCase() : '';

      if (filetype !== 'pdf') {
        this.toast.error(this.regionPage?.SelectValidFileFormat);
        this.spinner.hide();
      } else {
        if (file.size <= 10 * 1024 * 1024) {
          this.fileName = fileName;
          const reader = new FileReader();
          reader.onloadend = () => {
            this.base64String = reader.result as string;
            this.spinner.hide();3
          };
          reader.readAsDataURL(file);
        } else {
          this.spinner.hide();
          this.toast.error(this.regionPage?.MaximumFileSize);
        }
      }
    }
  }
  triggerFileInput(): void {
    this.fileInput.nativeElement.value = ''; // Clear the file input to allow selecting the same file
    this.fileInput.nativeElement.click();
  }
  check(elementRef: ElementRef | any) {
    const check = elementRef?.el?.nativeElement.querySelector('.p-datepicker-trigger');
    check.click();
  }
  onStartDateSelect(date: Date, retainValues:boolean = false) {
    const startDate = this.datePipe.transform(date, "MM/dd/yyyy");
    // this.selectedStartDate.setValue(startDate);
    if(this.isUpdate){
      if(!retainValues){
        return;
      }
      // this.selectedAlertAction.setValue([...this.selectedAlertAction.value,3])
    }
  }
  onEndDateSelect(date: Date, retainValues:boolean = false) {
    const endDate = this.datePipe.transform(date, "MM/dd/yyyy");
    // this.selectedEndDate.setValue(endDate);
    if(this.isUpdate){
      if(!retainValues){
        return;
      }
      // this.selectedAlertAction.setValue([...this.selectedAlertAction.value,3])
    }
  }
  onAjwaaChanges(event: any){
    if(event.target.checked){
      this.channelForm.controls['allOrganizations'].enable();
      this.channelForm.controls['selectOrganizations'].enable();
    }
    else{
      this.channelForm.controls['allOrganizations'].disable();
      this.channelForm.controls['selectOrganizations'].disable();
    }
  }
  onAllOrganizationChanges(event: any){
    if(event.target.checked){
      this.channelForm.controls['selectOrganizations'].disable();
    }
    else{
      this.channelForm.controls['selectOrganizations'].enable();
    }
  }
  getParams(){
    this.subscriptions.add(
      this.activated.queryParams.subscribe((params)=>{
        if(params.reporttype){
          this.reporttypeid = params.reporttype;
          this.reporttypekey = params.reportKey;
          this.descriptionEn = params.descriptionEn;
          this.descriptionAr = params.descriptionAr;
          this.titleEn = params.titleEn;
          this.titleAr = params.titleAr;
        }
        else{
          this.router.navigateByUrl('/home/automatic-weather-report')
        }
      })
    )
  }
  getcitylist(){
    const reportCityListModelAPI: ReportCityListModelAPI = new ReportCityListModelAPI()
    reportCityListModelAPI.report_type_id = this.reporttypeid
    this.automaticWeatherReportservice.getCityList(reportCityListModelAPI)
    .then((res:{success: boolean, data: ReportCityListDataModel[]})=>{
      this.CityList = res.data
      this.initializeCityForm()
    })
    .catch((error: HttpErrorResponse)=>{
      this.globalService.handleError(error)
    })
  }
  getAllRegions(){
    this.alertService.getRegions().then((res:any) => {
      if(res.success){
        this.allRegions = res.data;
      }
    })
    .catch((error: HttpErrorResponse)=>{
      this.globalService.handleError(error)
    })
  }
  getGovernatesByRegion(regionId:number){
    this.alertService.getGovernatesByRegionId(regionId)
      .then((res: { success: boolean, data: GovernateModel[] }) => {
        if (res?.success) {
          this.governates = res.data;
          this.filteredGov = this.governates
          this.allGovernatesIds = this.governates.map(group => group.id);
          if (this.governates.length > 0) {
            this.governates.forEach((gov) => {
              // this.addGovernatesLayers(gov.nameEn, gov.id);
            })
          }
        }
      })
      .catch((error) => {
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }
  getGovernates() {
    this.alertService.getAllGovernates().then((res: any) => {
      if (res.success) {
        this.governatesList = res.data;
        this.filteredGov = this.governatesList;
        this.allGovernatesIds = this.governatesList.map(group => group.id);
      }
    }).catch((error: HttpErrorResponse) => {
      this.globalService.handleError(error);
    });
  }
  filterGovernates() {
    const filterValue = this.filterGovernorates.value.toLowerCase();
    this.filteredGov = this.governatesList.filter(governate =>
      (this.language === 'en' ? governate.nameEn : governate.nameAr).toLowerCase().includes(filterValue)
    );
  }
  onSelectionChange(event: any) {
    this.cityForm.controls.selectedGovernates.setValue(event.value);
  }

  getGovernateNameById(id: string): string {
    const governate = this.governatesList.find(gov => gov.id === id);
    return this.language === 'en' ? governate?.nameEn : governate?.nameAr;
  }
  isAllSelected(): boolean {
    return this.cityForm.controls.selectedGovernates.value.length === this.allGovernatesIds.length;
  }

  toggleSelectAll() {
    if (this.isAllSelected()) {
      this.cityForm.controls.selectedGovernates.setValue([]);
    } else {
      this.cityForm.controls.selectedGovernates.setValue([...this.allGovernatesIds]);
    }
  }
  initializeGovernateForm() {
    this.governatesList.forEach(city => {
      this.cityForm.addControl(city.id.toString(), this.fb.control(false));
    });
  }

  selectAllGovernates() {
    const allSelected = this.areAllGovernatesSelected();
    this.governatesList.forEach(city => {
      this.cityForm.get(city.id.toString())?.setValue(!allSelected, { emitEvent: true, onlySelf: false });
    });
  }

  areAllGovernatesSelected(): boolean {
    return this.governatesList.every(city => this.cityForm.get(city.id.toString())?.value === true);
  }

  selectGovernate(governate: GovernateModel) {
    const control = this.cityForm.get(governate.id.toString());
    control?.setValue(!control?.value);
  }
  initializeCityForm(){
    this.CityList.forEach(city => {
      this.cityForm.addControl(city.id.toString(), this.fb.control(false));
    });
  }
  updateFilteredItems(filtered: any[], section: string) {
    if (section === 'Gov') {
      this.filteredGov = filtered;
    }
  }
  getHistoricalDays(event:any){
    this.historicalDays = event;
    if (event !== null) {
      this.forecastedDays = null;
    }
    console.log(this.historicalDays);
    const toDate = new Date()
    toDate.setDate(toDate.getDate() - this.historicalDays);
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T00:00:00.000Z';
    this.historicalDate = this.datePipe.transform(toDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
    console.log({todayDate: this.todayDate, historicalDate: this.historicalDate})
  }
  getForecastedDays(event:any){
    this.forecastedDays = event;
    if (event !== null) {
      this.historicalDays = null;
    }
    console.log(this.forecastedDays);
    const toDate = new Date()
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T00:00:00.000Z';
    if(this.forecastedDays == 15){
      toDate.setDate(toDate.getDate() + 14);
      this.forecastedDate = this.datePipe.transform(toDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      }
      else{
        toDate.setDate(toDate.getDate() + this.forecastedDays);
        this.forecastedDate = this.datePipe.transform(toDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
    }
    console.log({todayDate: this.todayDate, forecastedDate: this.forecastedDate})
  }

  // getweatherparamaters(){
  //   const weatherParamsListModelAPI: WeatherParamsListModelAPI = new WeatherParamsListModelAPI()
  //   weatherParamsListModelAPI.report_type_id = this.reporttypeid
  //   this.automaticWeatherReportservice.getWeatherParamsList(weatherParamsListModelAPI)
  //   .then((res:{success: boolean, data: WeatherParamsListDataModel[]})=>{
  //     this.WeatherParamsList = res.data
  //     this.initializeWeatherForm()
  //   })
  //   .catch((error: HttpErrorResponse)=>{
  //     this.globalService.handleError(error)
  //   })
  // }

  // initializeWeatherForm(){
  //   this.WeatherParamsList.forEach(Parameters => {
  //     const key = this.replaceKey(Parameters.meteoMaticsKey);
  //     this.weatherForm.addControl(key, this.fb.control(false));
  //   });
  // }
  // NEWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
  getweatherparamaters(){
    const weatherParamsListModelAPI: WeatherParamsListModelAPI = new WeatherParamsListModelAPI()
    weatherParamsListModelAPI.report_type_id = this.reporttypeid;
    this.automaticWeatherReportservice.getWeatherParamsList(weatherParamsListModelAPI)
    .then((res:{success: boolean, data: WeatherParamsListDataModel[]})=>{
      this.WeatherParamsList = res.data;
      this.groupedWeatherParamsList = this.groupParameters(this.WeatherParamsList);
      this.initializeWeatherForm();
    })
    .catch((error: HttpErrorResponse)=>{
      this.globalService.handleError(error);
    });
  }

  // initializeWeatherForm() {
  //   const groupedParams = this.groupParameters(this.WeatherParamsList);
  //   groupedParams.forEach(group => {
  //     const groupKey = group.groupKey;
  //     this.weatherForm.addControl(groupKey, this.fb.control(false));
  //     group.parameters.forEach(param => {
  //       const key = this.replaceKey(param.meteoMaticsKey);
  //       this.weatherForm.addControl(key, this.fb.control(false));
  //     });
  //   });
  // }
  initializeWeatherForm() {
    const formControls = {};

    this.groupedWeatherParamsList.forEach(group => {
        const groupKey = group.groupKey;
        formControls[groupKey] = this.fb.control(false);

        group.parameters.forEach(param => {
            const key = this.replaceKey(param.meteoMaticsKey);
            formControls[key] = this.fb.control(false);
        });
    });

    this.weatherForm = this.fb.group(formControls);
}

groupParameters(paramsList: WeatherParamsListDataModel[]): any[] {
  const groupedParams = [];
  const groupedParamKeys = new Set();

  paramsList.forEach(param => {
    const key = this.getGroupKey(param);
    if (!groupedParamKeys.has(key)) {
      groupedParamKeys.add(key);
      groupedParams.push({
        groupKey: key,
        parameters: [param],
      });
    } else {
      const group = groupedParams.find(group => group.groupKey === key);
      group.parameters.push(param);
    }
  });

  return groupedParams;
}

  // getGroupKey(param: any): string {
  //   if (param.meteoMaticsKey.includes('t_min_2m')) {
  //     return 'Temperature';
  //   } else if (param.meteoMaticsKey.includes('t_max_2m')) {
  //     return 'Temperature';
  //   } else if (param.meteoMaticsKey.includes('relative_humidity_min_2m')) {
  //     return 'Humidity';
  //   } else if (param.meteoMaticsKey.includes('relative_humidity_max_2m')) {
  //     return 'Humidity';
  //   } else if (param.meteoMaticsKey.includes('first_high_tide')) {
  //     return 'High Tide';
  //   } else if (param.meteoMaticsKey.includes('second_high_tide')) {
  //     return 'High Tide';
  //   } else if (param.meteoMaticsKey.includes('first_low_tide')) {
  //     return 'Low Tide';
  //   } else if (param.meteoMaticsKey.includes('second_low_tide')) {
  //     return 'Low Tide';
  //   } else {
  //     return param.nameEn; // No grouping
  //   }
  // }
  getGroupKey(param: any): string {
    if (param.meteoMaticsKey.includes('t_min_2m') || param.meteoMaticsKey.includes('t_max_2m')) {
        return 'Temperature';
    } else if (param.meteoMaticsKey.includes('relative_humidity')) {
        return 'Humidity';
    } else if (param.meteoMaticsKey.includes('high_tide')) {
        return 'High Tide';
    } else if (param.meteoMaticsKey.includes('low_tide')) {
        return 'Low Tide';
    } else {
        return param.nameEn; // Default to the nameEn if no specific group
    }
}

  // toggleGroup(group: any) {
  //   const groupKey = group.groupKey;
  //   const groupControl = this.weatherForm.get(groupKey);

  //   if (groupControl.value) {
  //     // Uncheck all parameters in the group
  //     group.parameters.forEach(param => {
  //       const paramKey = this.replaceKey(param.meteoMaticsKey);
  //       this.weatherForm.get(paramKey).setValue(false);
  //     });
  //   }
  // }
  toggleGroup(group: any) {
    const groupKey = group.groupKey;
    const groupControl = this.weatherForm.get(groupKey);

    if (groupControl.value) {
        // Check all parameters in the group
        group.parameters.forEach(param => {
            const paramKey = this.replaceKey(param.meteoMaticsKey);
            this.weatherForm.get(paramKey).setValue(true);
        });
    } else {
        // Uncheck all parameters in the group
        group.parameters.forEach(param => {
            const paramKey = this.replaceKey(param.meteoMaticsKey);
            this.weatherForm.get(paramKey).setValue(false);
        });
    }
}
  // NEWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
  replaceKey(keyStr:string){
    let key = keyStr.replace('%sh','24h');
    return key;
  }
  selectAllChanged(event:any) {
    Object.values(this.cityForm.controls).forEach((control)=>{
      control.setValue(event.target.checked)
    });
    // this.CityList.forEach(city => {
    //   this.cityForm.addControl(city.id.toString(), this.fb.control(false));
    // });
    // const selectAllValue = this.cityForm.get('selectAll').value;

    // if (selectAllValue) {
    //   this.CityList.forEach(city => {
    //     this.cityForm.get(city.id.toString()).setValue(true);
    //   });
    // } else {
    //   this.CityList.forEach(city => {
    //     this.cityForm.get(city.id.toString()).setValue(false);
    //   });
    // }
  }
  // selectAllWeatherParameters(event:any){
  //   let checkedKey = Object.values(this.weatherForm.controls).forEach((control)=>{
  //     control.setValue(event.target.checked)
  //   });
  //   console.log('checkedKey',checkedKey);
  //   console.log('weatherForm',this.weatherForm);
  // }
  selectAllWeatherParameters(event: any) {
    const isChecked = event.target.checked;

    Object.keys(this.weatherForm.controls).forEach(key => {
        this.weatherForm.get(key).setValue(isChecked);
    });

    console.log('weatherForm', this.weatherForm.value);
}


  validate() {

    const areatextarea = document.querySelector("#summary") as HTMLTextAreaElement | null;
    const areatext = areatextarea?.value.trim().split(/\s+/).length || 0; // Counting words instead of characters
    const textcount = document.querySelector("#textcount") as HTMLElement | null;
    const wordcount = document.querySelector("#words_count") as HTMLElement | null;

    if (textcount) {
        textcount.innerHTML = areatext.toString();
    }

    if (areatext > 200 && textcount) {
        textcount.classList.add("text-danger");
    } else if (textcount) {
        textcount.classList.remove("text-danger");
    }

    if (areatextarea) {
        if (areatext > 200) {
            areatextarea.classList.add("textarea_danger");
        } else {
            areatextarea.classList.remove("textarea_danger");
        }
    }
}


  formatTimeInto12HourFormat(dateStr:string){
    const [strHour, minutesAndPeriod] = dateStr.split(":");
    let hour = parseInt(strHour);
      if(hour > 12){
        hour = hour - 12;
      }
      if(hour  == 0){
        hour = 12;
      }
      return `${hour}:${minutesAndPeriod}`
  }

  formatTime(timeStr:string){
    const [time] = timeStr.split(" ");
    const seconds = `00`;
    return `${time}:${seconds}`;
  }
  getFullDateAndTimeString(date:string,time:string){
    return `${date} ${time}`;
  }

  convertTimeTo24HourFormat(timeString: string) {
    const [time, period] = timeString?.split(" ");
    const [hours, minutes] = time?.split(":");

    let convertedHours = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && convertedHours !== 12) {
      convertedHours += 12;
    } else if (period.toLowerCase() === 'am' && convertedHours === 12) {
      convertedHours = 0;
    }

    return `${String(convertedHours).padStart(2, '0')}:${minutes}:00`;
  }

  // goToAutomaticWeatherReport() {
  //   const selectedIds = Object.keys(this.cityForm.controls)
  //   .filter(key => this.cityForm.get(key).value)

  //   const selectedParams = Object.keys(this.weatherForm.controls)
  //   .filter(key => this.weatherForm.get(key).value)

  //   this.router.navigateByUrl(`home/automatic-weather-report/automatic-weather-report-preview?parameters=${selectedParams}&locations=${selectedIds}`);
  // }
  // NEWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWWW
  OnSelectAll(event: any) {
    Object.entries(this.reportsForm.controls).forEach(([key, control]) => {
      if (key !== 'weathersymbol') {
        control.setValue(event.target.checked);
      }
    });
  }
  OnSeaSelectAll(event: any) {
    Object.entries(this.seaReportsForm.controls).forEach(([key, control]) => {
      if (key !== 'weathersymbol') {
        control.setValue(event.target.checked);
      }
    });
  }
  OnCustomSelectAll(event: any) {
    Object.entries(this.customReportsForm.controls).forEach(([key, control]) => {
      if (key !== 'weathersymbol') {
        control.setValue(event.target.checked);
      }
    });
  }
  OnMashaerSelectAll(event: any) {
    Object.entries(this.mashaerReportsForm.controls).forEach(([key, control]) => {
      if (key !== 'weathersymbol') {
        control.setValue(event.target.checked);
      }
    });
  }
  goToAutomaticWeatherReport() {
    // this.reporttitleForm;
    // this.weatherForm;
    // this.statesForm;
    // this.cityForm;
    // this.channelForm;
    // debugger
    // return;
    if(this.reporttypekey == 'advance-custom-weather-report'){
      if (!this.advanceTitleForm.value.titleEn || this.advanceTitleForm.value.titleEn == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Title in English' : 'الرجاء إدخال عنوان التقرير باللغة الإنجليزية');
        return false;
      }
      if (!this.advanceTitleForm.value.titleAr || this.advanceTitleForm.value.titleAr == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Title in Arabic' : 'الرجاء إدخال عنوان التقرير باللغة العربية');
        return false;
      }
      if (!this.advanceTitleForm.value.descriptionEn || this.advanceTitleForm.value.descriptionEn == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Description in English' : 'الرجاء إدخال تفاصيل التقرير باللغة الإنجليزية');
        return false;
      }
      if (!this.advanceTitleForm.value.descriptionAr || this.advanceTitleForm.value.descriptionAr == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Description in Arabic' : 'الرجاء إدخال تفاصيل التقرير باللغة العربية');
        return false;
      }
    }
    else{
      if (!this.reporttitleForm.value.Report_Title || this.reporttitleForm.value.Report_Title == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Title' : 'الرجاء إدخال عنوان التقرير');
        return false;
      }
    }
    if(this.reporttypekey == 'advance-custom-weather-report'){
      if ( !this.isHistoricalDays && !this.isForecastedDays) {
        this.toast.error(this.language == 'en' ? 'Please select either Historical Days or Forecasted Days' : 'الرجاء اختيار إما الأيام السابقة أو الأيام المتوقعة');
        return;
      }
      if (this.isHistoricalDays && !this.historicalDate) {
        this.toast.error(this.language == 'en' ? 'Please select a date range for Historical Days' : 'الرجاء اختيار نطاق زمني للأيام السابقة');
        return;
      }

      if (this.isForecastedDays && !this.forecastedDate) {
          this.toast.error(this.language == 'en' ? 'Please select a date range for Forecasted Days' : 'الرجاء اختيار نطاق زمني للأيام المتوقعة');
          return;
      }
    }
    this.todayDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd') + 'T00:00:00.000Z';
    let historicalDate;
    let historicalDateStr;
    let forecastedDate;
    let forecastedDateStr;
    if(this.reporttypekey == 'advance-custom-weather-report' && this.isHistoricalDays){
      historicalDate = new Date(this.historicalDate.year, this.historicalDate.month - 1, this.historicalDate.day);
      historicalDateStr = this.datePipe.transform(historicalDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      this.historicalDays = 1;
      this.forecastedDays = null;
    }
    else if(this.reporttypekey == 'advance-custom-weather-report' && this.isForecastedDays){
      forecastedDate = new Date(this.forecastedDate.year, this.forecastedDate.month - 1, this.forecastedDate.day);
      forecastedDateStr = this.datePipe.transform(forecastedDate, 'yyyy-MM-dd') + 'T00:00:00.000Z';
      this.forecastedDays = 1;
      this.historicalDays = null;
    }
    let governateIds;
    if(this.reporttypekey == 'advance-custom-weather-report'){
      governateIds = this.cityForm.controls.selectedGovernates.value;
      if(governateIds.length === 0){
        this.toast.error(this.language == 'en' ? 'Please select a city' : 'الرجاء اختيار المدينة');
        return false;
      }
    }
    let selectedGovernates = this.selectedGovernates.value;
    if(this.reporttypekey == 'custom-weather-report'){
      if(selectedGovernates.length === 0){
        this.toast.error(this.language == 'en' ? 'Please select a city' : 'الرجاء اختيار المدينة');
        return false;
      }
      else{
        governateIds = selectedGovernates;
      }
      // governateIds = selectedGovernates;
    }
    let selectedIds;
    if(this.reporttypekey != 'advance-custom-weather-report' && this.reporttypekey != 'custom-weather-report'){
      selectedIds = Object.keys(this.cityForm.controls)
      .filter(key => this.cityForm.get(key).value)
    }

    const selectedParams = Object.keys(this.weatherForm.controls)
    .filter(key => this.weatherForm.get(key).value)
    .map(key => {
      const groupKey = this.replaceKey(key);
      const isGroupedCheckbox = this.groupedWeatherParamsList.some(group => group.groupKey === groupKey);

      if (isGroupedCheckbox) {
        // If it's a grouped checkbox, include the metomaticskey of each parameter in the group
        return this.groupedWeatherParamsList
          .find(group => group.groupKey === groupKey)
          .parameters.map(param => this.replaceKey(param.meteoMaticsKey));
      } else {
        // If it's an individual checkbox, include the metomaticskey of the parameter
        return this.replaceKey(key);
      }
    })
    .flat(); // Flatten the array if there are nested arrays
    if(this.reporttypekey != 'advance-custom-weather-report' && this.reporttypekey != 'custom-weather-report'){
      if(selectedIds.length == 0){
        this.toast.error(this.language == 'en' ? 'Please select a city' : 'الرجاء اختيار المدينة');
        return false;
      }
      // if(selectedParams.length == 0){
      //   this.toast.error('Please select a Weather Parameter');
      //   return false;
      // }
    }
    let selectedParameters: string[] = [];
    if(this.reporttypekey == 'advance-custom-weather-report'){
      for (const [key, value] of Object.entries(this.reportsForm.controls)) {
        if (value.value === true) {
          selectedParameters.push(ParametersMap[key]);
        }
      }
      if(selectedParameters.length < 2){
        this.spinner.hide();
        this.toast.error(this.language == 'en' ? 'Please select a Weather Phenomena' : 'الرجاء اختيار الظاهرة الجوية');
        return
      }
    }
    else if(this.reporttypekey == 'mashaer-weather-report'){
      for (const [key, value] of Object.entries(this.mashaerReportsForm.controls)) {
        if (value.value === true) {
          selectedParameters.push(MashaerReportParametersMap[key]);
        }
      }
      if(selectedParameters.length < 2){
        this.spinner.hide();
        this.toast.error(this.language == 'en' ? 'Please select a Weather Phenomena' : 'الرجاء اختيار الظاهرة الجوية');
        return
      }
    }
    else if(this.reporttypekey == 'custom-weather-report'){
      for (const [key, value] of Object.entries(this.customReportsForm.controls)) {
        if (value.value === true) {
          selectedParameters.push(CustomReportParametersMap[key]);
        }
      }
      if(selectedParameters.length < 2){
        this.spinner.hide();
        this.toast.error(this.language == 'en' ? 'Please select a Weather Phenomena' : 'الرجاء اختيار الظاهرة الجوية');
        return
      }
    }
    else{
      for (const [key, value] of Object.entries(this.seaReportsForm.controls)) {
        if (value.value === true) {
          selectedParameters.push(ArabianParametersMap[key]);
        }
      }
      if(selectedParameters.length < 2){
        this.spinner.hide();
        this.toast.error(this.language == 'en' ? 'Please select a Weather Phenomena' : 'الرجاء اختيار الظاهرة الجوية');
        return
      }
    }
    let reportnames = selectedParameters.flat();
    if(this.reporttypekey == 'custom-weather-report' || this.reporttypekey == 'mashaer-weather-report' || this.reporttypekey == 'advance-custom-weather-report'){
      if(!this.channelForm.controls['twitter'].value && !this.channelForm.controls['publicPortal'].value && !this.channelForm.controls['allOrganizations'].value && !this.channelForm.controls['selectOrganizationsArray'].value && !this.channelForm.controls['addEmails'].value && !this.channelForm.controls['addEmailGroup'].value){
        this.toast.error(this.language == 'en' ? 'Please select a channel' : 'الرجاء اختيار قناة');
        return false;
      }
    }
    const channeldata = this.channelForm.getRawValue();
    this.automaticWeatherReportservice.allChannelData = channeldata;

    if(this.channelForm.controls['addEmailGroup'].value){
      this.automaticWeatherReportservice.emailGroups = this.selectedEmailGroups;
    }

    // if(channeldata )
    // return;
    if(this.reporttypekey != 'custom-weather-report' && this.reporttypekey != 'mashaer-weather-report' && this.reporttypekey != 'advance-custom-weather-report'){
      if (!this.statesForm.value.Surface_Wind || this.statesForm.value.Surface_Wind == "") {
        this.toast.error(this.language == 'en' ? 'Please enter Surface Wind ( English )' : 'الرجاء إدخال الرياح السطحية (بالإنجليزية)');
        return false;
      }
      if (!this.statesForm.value.Surface_Wind_ar || this.statesForm.value.Surface_Wind_ar == "") {
        this.toast.error(this.language == 'en' ? 'Please enter Surface Wind ( عربي )' : 'الرجاء إدخال الرياح السطحية (بالعربية)');
        return false;
      }
      if (!this.statesForm.value.Wave_Height || this.statesForm.value.Wave_Height == "") {
        this.toast.error(this.language == 'en' ? 'Please enter Wave Height ( English )' : 'الرجاء إدخال ارتفاع الموج (بالإنجليزية)');
        return false;
      }
      if (!this.statesForm.value.Wave_Height_ar || this.statesForm.value.Wave_Height_ar == "") {
        this.toast.error(this.language == 'en' ? 'Please enter Wave Height ( عربي )' : 'الرجاء إدخال ارتفاع الموج (بالعربية)');
        return false;
      }
      if (!this.statesForm.value.State_of_the_Sea || this.statesForm.value.State_of_the_Sea == "") {
        this.toast.error(this.language == 'en' ? 'Please enter State of the Sea ( English )' : 'الرجاء إدخال حالة البحر (بالإنجليزية)');
        return false;
      }
      if (!this.statesForm.value.State_of_the_Sea_ar || this.statesForm.value.State_of_the_Sea_ar == "") {
        this.toast.error(this.language == 'en' ? 'Please enter State of the Sea ( عربي )' : 'الرجاء إدخال حالة البحر (بالعربية)');
        return false;
      }
    }
    else{
      this.statesForm.value.Surface_Wind == "";
      this.statesForm.value.Wave_Height == "";
      this.statesForm.value.State_of_the_Sea == "";
      this.statesForm.value.Surface_Wind_ar == "";
      this.statesForm.value.Wave_Height_ar == "";
      this.statesForm.value.State_of_the_Sea_ar == "";
    }
    // if (!this.cityForm.value || this.cityForm.value) {
    //   this.toast.error('Please select a City');
    //   return false;
    // }
    // if (!this.weatherForm.value.weatherForm || this.weatherForm.value.weatherForm == "") {
    //   this.toast.error('Please select a Weather Phenomena');
    //   return false;
    // }
    localStorage.setItem("Report_Title", this.reporttitleForm.value['Report_Title'])
    localStorage.setItem("Surface_Wind", this.statesForm.value['Surface_Wind'])
    localStorage.setItem("Wave_Height", this.statesForm.value['Wave_Height'])
    localStorage.setItem("State_of_the_Sea", this.statesForm.value['State_of_the_Sea'])
    localStorage.setItem("Surface_Wind_ar", this.statesForm.value['Surface_Wind_ar'])
    localStorage.setItem("Wave_Height_ar", this.statesForm.value['Wave_Height_ar'])
    localStorage.setItem("State_of_the_Sea_ar", this.statesForm.value['State_of_the_Sea_ar'])


    // const governateIds = this.selectedGovernates.value.toString();
    // const governates: any[] = [];
    // this.governates.forEach((governate) => {
    //   if (this.selectedGovernates.value.includes(governate.id)) {
    //     governates.push(governate);
    //   }
    // })
    // listEwsNotificationModel.governorates = governates;
    let advanceCustomData = {
      reporttypekey: this.reporttypekey,
      reporttypeid: this.reporttypeid,
      // selectedParams: reportnames,
      // selectedIds: governateIds,
      descriptionEn: this.advanceTitleForm.value.descriptionEn,
      descriptionAr: this.advanceTitleForm.value.descriptionAr,
      titleEn: this.advanceTitleForm.value.titleEn,
      titleAr: this.advanceTitleForm.value.titleAr,
      historicalDate: historicalDateStr,
      historicalDays: this.historicalDays,
      forecastedDate: forecastedDateStr,
      forecastedDays: this.forecastedDays,
      todayDate: this.todayDate,
      channel: this.channelForm.getRawValue(),
      emailGroups: this.selectedEmailGroups
    }
    if(this.reporttypekey == 'advance-custom-weather-report'){
      if(!this.isAdvanceCustomReport){
        this.spinner.show();
        const previewAdvanceCustomReport: PreviewAdvanceCustomReport = new PreviewAdvanceCustomReport();
        if(this.historicalDate){
          previewAdvanceCustomReport.from_date = historicalDateStr;
          previewAdvanceCustomReport.to_date = this.todayDate;
        }
        if(this.forecastedDate){
          previewAdvanceCustomReport.to_date = forecastedDateStr;
          previewAdvanceCustomReport.from_date = this.todayDate;
        }
        previewAdvanceCustomReport.lang = this.language;
        previewAdvanceCustomReport.locations = governateIds;
        previewAdvanceCustomReport.model = "mix";
        previewAdvanceCustomReport.parameters = reportnames;
        this.automaticWeatherReportservice.previewAdvanceCustomReport(previewAdvanceCustomReport)
        .then((res:any)=>{
          if(res.success){
            this.spinner.hide();
            // this.toast.success(res.message);
            this.alldata = res.data;
            this.automaticWeatherReportservice.advanceCustomData = advanceCustomData;
            this.automaticWeatherReportservice.advanceCustomPreviewData = this.alldata;
            this.mannedForecastService.previewdata = [];
            this.router.navigateByUrl('home/manned-forecast/manned-forecast-preview')
          }
          else{
            this.spinner.hide();
            this.toast.error(this.language == 'en' ? 'Report Preview Not Available. Please try later.' : 'معاينة التقرير غير متاحة. الرجاء المحاولة لاحقًا.');
          }
        })
        .catch((error)=>{
          this.globalService.handleError(error)
        })
      }
      else{
        this.toast.error(this.language == 'en' ? 'Please select a report type' : 'الرجاء اختيار نوع التقرير');
        return false;
      }
    }
    else if(this.reporttypekey == 'custom-weather-report'){
      this.router.navigateByUrl(`home/automatic-weather-report/automatic-weather-report-preview?reporttype=${this.reporttypeid}&reportKey=${this.reporttypekey}&parameters=${reportnames}&locations=${governateIds}&descriptionEn=${this.descriptionEn}&descriptionAr=${this.descriptionAr}&titleEn=${this.titleEn}&titleAr=${this.titleAr}`);
    }
    else{
      this.router.navigateByUrl(`home/automatic-weather-report/automatic-weather-report-preview?reporttype=${this.reporttypeid}&reportKey=${this.reporttypekey}&parameters=${reportnames}&locations=${selectedIds}&descriptionEn=${this.descriptionEn}&descriptionAr=${this.descriptionAr}&titleEn=${this.titleEn}&titleAr=${this.titleAr}`);
    }
  }
  publishMannedReport(){
    if(!this.isMannedReport){
      if (!this.advanceTitleForm.value.titleEn || this.advanceTitleForm.value.titleEn == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Title in English' : 'الرجاء إدخال عنوان التقرير باللغة الإنجليزية');
        return false;
      }
      if (!this.advanceTitleForm.value.titleAr || this.advanceTitleForm.value.titleAr == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Title in Arabic' : 'الرجاء إدخال عنوان التقرير باللغة العربية');
        return false;
      }
      if (!this.advanceTitleForm.value.descriptionEn || this.advanceTitleForm.value.descriptionEn == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Description in English' : 'الرجاء إدخال تفاصيل التقرير باللغة الإنجليزية');
        return false;
      }
      if (!this.advanceTitleForm.value.descriptionAr || this.advanceTitleForm.value.descriptionAr == "") {
        this.toast.error(this.language == 'en' ? 'Please enter a Report Description in Arabic' : 'الرجاء إدخال تفاصيل التقرير باللغة العربية');
        return false;
      }
      if (!this.base64String && !this.fileName) {
        this.toast.error(this.language == 'en' ? 'Please upload an Attachment' : 'الرجاء تحميل الملف');
        return false;
        // listEwsNotificationModel.filename = this.fileName
        // localStorage.setItem("tempFile", this.base64String)
      }
      if(this.reporttypekey == 'advance-custom-weather-report'){
        if(!this.channelForm.controls['twitter'].value && !this.channelForm.controls['publicPortal'].value && !this.channelForm.controls['allOrganizations'].value && !this.channelForm.controls['selectOrganizations'].value && !this.channelForm.controls['addEmails'].value && !this.channelForm.controls['addEmailGroup'].value){
          this.toast.error(this.language == 'en' ? 'Please select a channel' : 'الرجاء اختيار قناة');
          return false;
        }
      }
      // const channeldata = this.channelForm.getRawValue();
      // this.automaticWeatherReportservice.allChannelData = channeldata;

      if(this.channelForm.controls['addEmailGroup'].value){
        this.automaticWeatherReportservice.emailGroups = this.selectedEmailGroups;
      }
      if (this.fileName) {
        const parts = this.fileName.split('.');
        const filetype = parts.length > 1 ? parts.pop().toLowerCase() : '';

        if (filetype !== 'pdf') {
          this.toast.error(this.regionPage?.SelectValidFileFormat);
          return;
        }
      }
      let organizationsArray: number[] = [];
      let emails: string[] = [];
      const mychanneldata = this.channelForm.getRawValue();
      const channels = [];
      if(mychanneldata.addEmails){
        channels.push("email");
      }
      if(mychanneldata.ajwaaPortal){
        channels.push("ajwaa_portal");
      }
      if(mychanneldata.publicPortal){
        channels.push("public_portal");
      }
      if(mychanneldata.twitter){
        channels.push("twitter");
      }
      if(mychanneldata.selectOrganizations){
        mychanneldata.selectOrganizationsArray.forEach(element => {
          organizationsArray.push(element);
        })
      }
      else{
        organizationsArray = [0];
      }
      mychanneldata.count.forEach(element => {
        emails.push(element.email)
      });
      const generateMannedReport: GenerateMannedReport = new GenerateMannedReport();
      generateMannedReport.titleEn = this.advanceTitleForm.value.titleEn;
      generateMannedReport.titleAr = this.advanceTitleForm.value.titleAr;
      generateMannedReport.descriptionEn = this.advanceTitleForm.value.descriptionEn;
      generateMannedReport.descriptionAr = this.advanceTitleForm.value.descriptionAr;
      generateMannedReport.report_type_id = this.reporttypeid;
      generateMannedReport.fileName = this.fileName;
      generateMannedReport.file = this.base64String;
      generateMannedReport.lang = this.language;
      generateMannedReport.emails = emails;
      generateMannedReport.organizations = organizationsArray;
      if(this.automaticWeatherReportservice.allChannelData?.addEmailGroup){
        generateMannedReport.user_group_ids = this.selectedEmailGroups
      } else {
        generateMannedReport.channels = channels;
      }
      if(mychanneldata.sendEmail){
        generateMannedReport.send_email = 1;
      }
      this.automaticWeatherReportservice.generateMannedReport(generateMannedReport)
      .then((res:{success:boolean, message:string})=>{
        if(res.success){
          this.spinner.hide();
          this.toast.success(res.message);
          this.router.navigateByUrl('/home/automatic-weather-report')
        }
        else{
          this.spinner.hide();
          this.toast.error(this.language == 'en' ? 'Report Preview Not Available. Please try later.' : 'معاينة التقرير غير متاحة. الرجاء المحاولة لاحقًا.');
        }
      })
      .catch((error)=>{
        this.globalService.handleError(error)
      })
    }
    else{
      this.toast.error(this.language == 'en' ? 'Please select a report type' : 'الرجاء اختيار نوع التقرير');
      return false;
    }
  }
  openModalForOrganizations(event:any, content: TemplateRef<any>){
    if(event.target.checked){
      this.channelForm.controls['allOrganizations'].disable();
      this.openOrganizationModal(content);
    }else{
      this.channelForm.controls['allOrganizations'].enable();
    }
  }
  openOrganizationModal(content:any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered:true }).result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  openModalForEmails(event:any, email: TemplateRef<any>){
    if(event.target.checked){
      this.openEmailModal(email);
    }else{
      return;
    }
  }
  openModalForEmailGroup(event:any){
    if(event.target.checked){
      this.openEmailGroupModal();
    }else{
      return;
    }
  }
  openEmailModal(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered:true, modalDialogClass:'modal-lg', backdrop: 'static' }).result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }
  openEmailGroupModal() {
    const createEmailGroupmodal = this.modalService.open(EmailGroupsComponent, {
      centered:true,
      backdrop: 'static',
      modalDialogClass:'modal-lg',
      ariaLabelledBy: 'modal-basic-title',
    })
    createEmailGroupmodal.result.then((res: {selectedEmailGroups:number[]}) => {
      if (res?.selectedEmailGroups.length > 0) {
        this.selectedEmailGroups = res.selectedEmailGroups;
      } else {
        this.channelForm.controls['addEmailGroup'].setValue(false);
      }
    })
  }
  getOrganizationUsersForDropdown(clienttype?:any, page?:any){
    const ncmClientModelAPI: NCMClientModelAPI = new NCMClientModelAPI();
    if(page){
      ncmClientModelAPI.page = page;
    }
    else{
      if(this.organizationUsers?.paginationVariables?.pageCount > (this.currentPageForOrganizationUsers + 1 )){
        this.currentPageForOrganizationUsers++;
        ncmClientModelAPI.page = this.currentPageForOrganizationUsers;
      }
      else if(this.currentPageForOrganizationUsers == 1){
        ncmClientModelAPI.page = this.currentPageForOrganizationUsers;
      }
      else{
        return;
      }
    }
    this.spinner.show();
    this.userService.listOrganization(ncmClientModelAPI).then(
      (res:listOrganizationResponseModel) => {
        if(res.success){
          if(this.organizationUsers.data.length > 0){
            this.organizationUsers['data'] = [ ...this.organizationUsers['data'], ...res.data];
          }
          else{
            this.organizationUsers['data'] = res.data;
          }
          this.organizationUsers.paginationVariables = res.paginationVariables;
        }
        else{
          this.toast.error(res.message);
        }
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }
  count(): FormArray{
    return this.channelForm.get("count") as FormArray;
  }
  newCount(): FormGroup{
    return this.fb.group({
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]]
    });
  }
  addcount(){
    if(this.count().controls.length > 9){
      return;
    }
    this.count().push(this.newCount());
  }
  removeCount(i: number){
    this.count().removeAt(i);
  }
}

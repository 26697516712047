import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ColorModeService {
  private colorSource: BehaviorSubject<string>;
  currentColor: Observable<string>;
  constructor(private spinner: NgxSpinnerService) {
      const currentColor = localStorage.getItem("mode");
      if(currentColor){
        this.colorSource = new BehaviorSubject(currentColor);
      }
      else{
        this.colorSource = new BehaviorSubject('dark');
        localStorage.setItem('mode', 'dark');
      }
      this.currentColor = this.colorSource.asObservable();
   }

   changeColorMode(color:string){
      this.spinner.show();
      localStorage.setItem('mode', color);
      this.colorSource.next(color);
      setTimeout(()=>{
        this.spinner.hide();
      },600)
   }
}

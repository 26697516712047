import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { GlobalService } from 'src/app/shared/utilities/global';
import { LanguageService } from 'src/app/services/language/language.service';
import * as XLSX from 'xlsx';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';
import { CreateUserGroupModelAPI, CreateUserGroupModelDataAPI } from 'src/app/models/user-groups/user-groups.model';

class BulkLocationDataModel {
  tag_id: number;
  locationName: string;
  lat: number;
  lng: number;
  address_components: {
    long_name: string;
    short_name: string;
    types: string[]
  }[]
}

class BulkLocationModel {
  severeWeatherAlert: boolean;
  group: string;
  data: BulkLocationDataModel[];
}

@Component({
  selector: 'app-add-location-by-excel-modal',
  templateUrl: './add-location-by-excel-modal.component.html',
  styleUrls: ['./add-location-by-excel-modal.component.css']
})
export class AddLocationByExcelModalComponent implements OnInit {
  @Input() editgroup: CreateUserGroupModelAPI;
  private subscriptions: Subscription = new Subscription();
  language: string;
  excelFile: File;
  isLoading: boolean;
  progressStarted: boolean = false;
  progress: number = 0;
  groupName = new FormControl(null, {
    validators: Validators.required
  });
  groupType = new FormControl(null, {
    validators: Validators.required
  });
  severeWeatherAlert = new FormControl(false);
  tag_id = new FormControl(null,);
  locationPage: any;
  userGroupsPage: any;
  constructor(
    private userGroupService: UserGroupsService,
    private modal: NgbActiveModal,
    private toast: ToastrService,
    private globalService: GlobalService,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getCurrentLanguage();
    if(this.editgroup){
      this.groupName.setValue(this.editgroup.name);
      this.groupType.setValue(this.editgroup.type);
    }
  }

  getCurrentLanguage() {
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Locations").subscribe((res) => {
        this.locationPage = res;
      });
      this.translate.get("UserGroups").subscribe((res) => {
        this.userGroupsPage = res;
      });
    }));
  }

  handleFile(event: any) {
    const file = event.target.files[0];
    this.excelFile = file;
  }

  onSubmit() {
    if (this.isLoading) {
      return;
    }
    if (this.excelFile.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.toast.error(this.locationPage?.uploadExcelFile);
      return;
    }
    this.isLoading = true;
    this.readFile(this.excelFile);
  }

  readFile(file: File) {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      const fileContent = fileReader.result as ArrayBuffer;
      this.parseExcel(fileContent);
    }
    fileReader.readAsArrayBuffer(file);
  }
  async parseExcel(data: ArrayBuffer): Promise<void> {
    this.progressStarted = true;
    const workbook = XLSX.read(data, { type: 'array' });
    const firstSheetName = workbook.SheetNames[0];
    const worksheet = workbook.Sheets[firstSheetName];
    const locationData: CreateUserGroupModelDataAPI[] = [];
    const excelData: string[][] = XLSX.utils.sheet_to_json(worksheet, { header: 1, range: 0 }).filter(row => Object.values(row).some(cell => cell !== '')) as string[][];

    if (excelData?.length > 200) {
      this.toast.error(this.locationPage?.locationLimitExceeded);
      return;
    }

    const totalItems = excelData?.length;
    let processedItems = 0;
    for(const data of excelData){
      const firstName = data[0]
      const lastName = data[1]
      const email = data[2]

      locationData.push({firstName, lastName, email});
      processedItems++;
      this.progress = (processedItems / totalItems) * 100;
    }

    if (locationData?.length > 0) {
      if(this.editgroup){
        // this.editgroup.data = locationData;
        this.updateUserGroup(locationData);
      } else {
        this.createBulkLocations(locationData);
      }
    }
  }

  stopProgress() {
    this.progressStarted = false;
    this.isLoading = false;
  }

  createBulkLocations(locationData: CreateUserGroupModelDataAPI[]) {
    this.spinner.show();
    // const bulkLocationModel: BulkLocationModel = new BulkLocationModel();
    // bulkLocationModel.data = locationData;
    // bulkLocationModel.group = this.groupName.value;
    // bulkLocationModel.severeWeatherAlert = this.severeWeatherAlert.value || false;

    const createUserGroupModelAPI: CreateUserGroupModelAPI = new CreateUserGroupModelAPI();
    createUserGroupModelAPI.name = this.groupName.value;
    createUserGroupModelAPI.type = this.groupType.value;
    createUserGroupModelAPI.data = locationData;

    this.userGroupService.createUserGroup(createUserGroupModelAPI)
      .then((res: { success: boolean; message: string }) => {
        if (res.success) {
          this.modal.close({ result: true });
        }
        else {
          this.toast.error(res.message);
          this.stopProgress();
        }
      }).catch((error: HttpErrorResponse) => {
        if (error.status) {
          this.toast.error(error.error.message);
        }
        this.globalService.handleError(error);
        this.stopProgress();
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  updateUserGroup(locationData: CreateUserGroupModelDataAPI[]) {
    this.spinner.show();
    // const bulkLocationModel: BulkLocationModel = new BulkLocationModel();
    // bulkLocationModel.data = locationData;
    // bulkLocationModel.group = this.groupName.value;
    // bulkLocationModel.severeWeatherAlert = this.severeWeatherAlert.value || false;

    const createUserGroupModelAPI: CreateUserGroupModelAPI = new CreateUserGroupModelAPI();
    createUserGroupModelAPI.id = this.editgroup.id;
    createUserGroupModelAPI.name = this.groupName.value;
    createUserGroupModelAPI.type = this.groupType.value;
    createUserGroupModelAPI.data = locationData;

    this.userGroupService.updateUserGroup(createUserGroupModelAPI)
      .then((res: { success: boolean; message: string }) => {
        if (res.success) {
          this.modal.close({ result: true });
        }
        else {
          this.toast.error(res.message);
          this.stopProgress();
        }
      }).catch((error: HttpErrorResponse) => {
        if (error.status) {
          this.toast.error(error.error.message);
        }
        this.globalService.handleError(error);
        this.stopProgress();
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  isLocationInSaudiArabia(lat: number, lng: number): boolean {
    const saudiArabiaBounds = {
      north: 32.154352,
      south: 16.346749,
      west: 34.557416,
      east: 55.666224,
    };

    return (
      lat >= saudiArabiaBounds.south &&
      lat <= saudiArabiaBounds.north &&
      lng >= saudiArabiaBounds.west &&
      lng <= saudiArabiaBounds.east
    );
  }

  calculateProgress() {
    return `${this.progress}%`
  }

  closeModal() {
    this.modal.close({ result: false });
  }
}

import { Routes, RouterModule } from '@angular/router';
import { UserGroupComponent } from './user-group.component';

const routes: Routes = [
  {
    path: '',
    component: UserGroupComponent,
  },
];

export const UserGroupRoutes = RouterModule.forChild(routes);
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { ParametersMap, ArabianParametersMap, RedSeaParametersMap, CustomReportParametersMap, MashaerReportParametersMap } from 'src/app/models/manned-forecast/manned-forecast.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/shared/utilities/global';

export interface PreviewData {
  city: string
  lat: number
  lon: number
  parameters: Parameter[] | any;
}

export interface Parameter {
  parameter: string
  dates: Date[]
}

export interface Date {
  date: string
  value: number
}
export interface GetWeatherReportResponse {
  success: boolean
  data: GetWeatherReportReportData[];
}

export interface GetWeatherReportReportData {
  symbols: Symbol[]
  icons: Icon[]
}

export interface Symbol {
  nameEn: string
  nameAr: string
  iconValue: string
}

export interface Icon {
  iconValue: string
}

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent   {
  // @ViewChild("locid") locid;
  // @ViewChild("conditionId") conditionId;
  // @ViewChild("dateId") dateId;
  @Input() previewData:any | any[] = [];
  @Input() firstIndex:number;
  @Input() reporttypekey:string;

  @ViewChild("tempMaxId") tempMaxId;
  @ViewChild("tempMinId") tempMinId;
  @ViewChild("windSpeedId") windSpeedId;
  @ViewChild("windDirectionId") windDirectionId;
  @ViewChild("windPressureId") windPressureId;
  @ViewChild("precipId") precipId;
  @ViewChild("humidityId") humidityId;
  @ViewChild("probabilityId") probabilityId;
  @ViewChild("slipperyRoadId") slipperyRoadId;
  private subscriptions: Subscription = new Subscription()
  language:string;
  automaticpreviewPage: any;

  allPreviewData: PreviewData[];
  ParametersMap = ParametersMap;
  automaticWeather: any;
  windDirectionOptions = [
    { label: 'N', value: 22.5 },
    { label: 'NW', value: 67.5 },
    { label: 'W', value: 112.5 },
    { label: 'SW', value: 157.5 },
    { label: 'S', value: 202.5 },
    { label: 'SE', value: 247.5 },
    { label: 'E', value: 292.5 },
    { label: 'NE', value: 337.5 },
    { label: 'N', value: 360 }
  ];
  ArabianParametersMap = ArabianParametersMap;
  RedSeaParametersMap = RedSeaParametersMap;
  CustomReportParametersMap = CustomReportParametersMap;
  MashaerReportParametersMap = MashaerReportParametersMap;

  reportTypeMapping = {
    'custom-weather-report': CustomReportParametersMap,
    'mashaer-weather-report': MashaerReportParametersMap,
    'arabian-gulf-report': ArabianParametersMap,
    'red-sea-report': RedSeaParametersMap
  };
  symbols: Symbol[] = [];
  selectedSymbol: Symbol
  icons: Icon[] = [];
  filterWeatherS = new FormControl<string>('',);
  filtersymbols: Symbol[];
  filtersymbolsNight: Symbol[];


  constructor(
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private datePipe: DatePipe,
    private http: HttpClient,
    private languageService: LanguageService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private mannedForecastService: MannedForecast,
    private activeModal:NgbActiveModal,
    private globalService: GlobalService
  ) {
     this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticpreviewPage = res;
      });
      this.translate.get("AutomaticWeather").subscribe((res) => {
        this.automaticWeather = res;
      });

    }));
  }
  ngOnInit(): void {
    this.getWeatherReportsSymbols();
    const currentValueDay = this.previewData?.parameters[this.reportTypeMapping[this.reporttypekey].winddirection]?.[0].value;
    this.previewData.parameters[this.reportTypeMapping[this.reporttypekey].winddirection][0].value = this.getUpperLimit(currentValueDay);
    const currentValueNight = this.previewData?.parameters[this.reportTypeMapping[this.reporttypekey].winddirection]?.[1].value;
    this.previewData.parameters[this.reportTypeMapping[this.reporttypekey].winddirection][1].value = this.getUpperLimit(currentValueNight);
  }
  getWeatherReportsSymbols(){
    this.mannedForecastService.getWeatherReportSymbols()
    .then((res:GetWeatherReportResponse)=>{
      this.icons =  res.data[0].icons
      this.symbols =  res.data[0].symbols
      this.filtersymbols = this.symbols.filter((symbol)=>symbol.iconValue.length <= 2 || symbol.iconValue == '0');
      this.filtersymbolsNight = this.symbols.filter((symbol)=>symbol.iconValue.length >= 3 || symbol.iconValue == '0');
      for (let i = 0; i < this.symbols.length; i++) {
        if (this.symbols[i].iconValue === this.previewData?.parameters[this.reportTypeMapping[this.reporttypekey].weathersymbol]?.[1].value) {
          this.selectedSymbol = this.symbols[i]
          break;
        }
      }
    })
    .catch((error)=>{
      this.globalService.handleError(error)
    })
  }

  getUpperLimit(value: number): number {
    if (value <= 22.5) return 22.5;
    else if (value <= 67.5) return 67.5;
    else if (value <= 112.5) return 112.5;
    else if (value <= 157.5) return 157.5;
    else if (value <= 202.5) return 202.5;
    else if (value <= 247.5) return 247.5;
    else if (value <= 292.5) return 292.5;
    else if (value <= 337.5) return 337.5;
    else return 360;
  }
  // checkType(value:any){
  //   return typeof value;
  // }
  // onWeatherDescriptionChange(event:any){
  //   // this.previewData[this.firstIndex].parameters[this.ParametersMap.weathersymbol][this.secondIndex].nameEn = weatherSymbol.nameEn;
  //   // this.previewData[this.firstIndex].parameters[this.ParametersMap.weathersymbol][this.secondIndex].nameAr = weatherSymbol.nameAr;
  //   // this.previewData.parameters[this.reportTypeMapping[this.reporttypekey].weathersymbol].[1].value = event;
  // }
  getWindDirectionLabel(value: number): string {
    const option = this.windDirectionOptions.find(opt => opt.value === value);
    return option ? option.label : 'N/A';
  }
  focusOntempMaxId(){
    this.tempMaxId.nativeElement.focus();
  }
  // goToMannedForecast() {
  //   this.router.navigate(['home/manned-forecast']);
  // }
  onSave(){
    this.activeModal.close(this.previewData);
  }
  onClose(){
    this.activeModal.close();
  }
}

<div class="modal-header">
  <div class="modal-title">
    <h5>{{userGroupsPage?.CreateUserGroup}}</h5>
  </div>

  <i [ngClass]="{'disable-close-btn': progressStarted}" (click)="closeModal()"
    class="modal-close-btn far fa-times-circle"></i>
</div>

<div class="modal-body">
  <div [ngClass]="{'disable-form-container': progressStarted}">
    <!-- <input class="input" [ngClass]="{'disabled': progressStarted}" [disabled]="progressStarted"
      [formControl]="groupName" type="text" [placeholder]="userGroupsPage?.EnterGroupName"> -->
    <div class="d-flex flex-wrap col-12">
      <div class="search-locations d-flex col-6 px-1">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select w-100" appearance="outline">
          <mat-label>{{userGroupsPage?.EnterGroupName}}</mat-label>
          <input type="text" matInput [formControl]="groupName" [ngClass]="{'disabled': progressStarted}" [disabled]="progressStarted">
        </mat-form-field>
      </div>
      <div class="search-locations d-flex col-6 px-1">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="w-100 client-type-select">
          <mat-label>{{userGroupsPage?.SelectGroupType}}</mat-label>
          <mat-select floatLabel="always" [formControl]="groupType" [ngClass]="{'disabled': progressStarted}" [disabled]="progressStarted">
            <mat-option value="ews">{{userGroupsPage?.GroupType1}}</mat-option>
            <mat-option value="report">{{userGroupsPage?.GroupType2}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <input (change)="handleFile($event)" #file type="file" class="d-none"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">

    <div class="d-flex align-items-center gap-3 my-3 header-btn">
      <button [disabled]="progressStarted" [ngClass]="{'disabled': progressStarted}" (click)="file.click()"
        class="">
        <i class="far fa-file-excel excel"></i> &nbsp;
        {{locationPage?.BrowseFile}}
      </button>
      <span class="file-name">{{excelFile?.name}}</span>
    </div>

    <div class="d-flex align-items-center gap-2">
      <a target="_blank"
        href="https://ncmportal.centric.ae/User-Group-Format.xlsx">{{locationPage?.DownloadExampleFormat}}</a>
    </div>

    <!-- <div class="d-flex align-items-center gap-2 my-4">
      <input [ngClass]="{'disabled': progressStarted}" class="checkbox" #check [formControl]="severeWeatherAlert" type="checkbox"> <label (click)="check.click()">{{locationPage?.SevereWeatherAlert}}</label>
    </div> -->
  </div>

  <div *ngIf="progressStarted" class="progress-container">
    <div class="progress">
      <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
        aria-valuemin="0" aria-valuemax="100" [style.width]="calculateProgress()">{{calculateProgress()}}</div>
    </div>
  </div>

  <div *ngIf="!progressStarted" class="d-flex justify-content-center mt-4">
    <button (click)="onSubmit()" [ngClass]="{'disabled-btn':!excelFile?.name || !groupName.value}"
      [disabled]="!excelFile?.name || !groupName?.value" class="widget-btn btn-primary-dark text-white">
      <ng-container *ngIf="!isLoading else loader">
        {{locationPage?.Submit}}
      </ng-container>
    </button>
  </div>
</div>

<ng-template #loader>
  <div class="lds-ring">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</ng-template>
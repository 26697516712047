import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddUsersComponent } from './add-users.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddUsersRoutes } from './add-users.routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    AddUsersComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AddUsersRoutes,
    NgxPermissionsModule.forChild()
  ]
})
export class AddUsersModule { }

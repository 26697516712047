import { Component, Input, OnDestroy } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { POLICY_IN_ARABIC, POLICY_IN_ENGLISH, TERMS_IN_ARABIC, TERMS_IN_ENGLISH } from './privacy-policy-terms/privacy-policy-terms';
import { LanguageService } from 'src/app/services/language/language.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.css']
})
export class PrivacyPolicyModalComponent implements OnDestroy {
  private subscription:Subscription = new Subscription();
  @Input() policy:boolean = false;
  @Input() terms:boolean = false;
  language:string;
  termsEn:string = TERMS_IN_ENGLISH
  termsAr:string = TERMS_IN_ARABIC;
  policyEn:string = POLICY_IN_ENGLISH;
  policyAr:string = POLICY_IN_ARABIC;

  policyPage:any = {};

  constructor(private modal:NgbActiveModal, private languageService:LanguageService, private translate: TranslateService){
    const languageSubscription$ = this.languageService.currentLanguage.subscribe((language)=>{
      this.language = language;
      this.translate.use(language);
      this.translate.get("Dashboard").subscribe((res)=>{
        this.policyPage = res;
      })
    })
    this.subscription.add(languageSubscription$);
  }

  closeModal(){
    this.modal.close();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

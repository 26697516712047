<div class="main-container">
  <!-- <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">{{ mannedforecast?.MannedForecasts }}</p>
  </div> -->
  <!-- <div class="locationBG justify-content-between">
    <div class="addLocHeading">
      <img class="headerIcon" src="../../../assets/mannedforecast/mannedforecast-icon.png" />
      <h3>{{ mannedforecast?.MannedForecasts }}</h3>
    </div>
  </div> -->
  <p-toolbar class="row dome" style="margin: inherit;">
    <div class="p-toolbar-group-left col justify-content-between">
      <div class="d-flex gap-2 align-items-center">
        <img src="../../../assets/mannedforecast/mannedforecast-icon.png" style="scale: 0.7;">
        <div class="alert-history">{{ mannedforecast?.MannedForecasts }}</div>
      </div>
    </div>
  </p-toolbar>
  <div class="hello-container my-30 py-4 px-4">
    <div class="d-flex flex-wrap justify-content-between align-items-end">
      <p class="welcome w-100 pb-4">{{mannedforecast?.GenerateWeatherReport}}</p>
      <p class="welcometext w-50 pb-4">{{mannedforecast?.Seamlesslygeneratecomprehensiveweatherreports}}</p>
      <div class="w-100 d-flex justify-content-sm-end justify-content-xxl-end flex-wrap align-items-center gap-3">
        <button (click)="AddReport=true" class="widget-btn text-white btn-primary-dark">
          <span class="plus-icon"><img src="../../../assets/mannedforecast/generatereport.png"></span>
           {{mannedforecast?.GenerateReport}}
         </button>
         <!-- <button class="widget-btn text-white btn-primary-dark">
          <span class="icon-report">
            <i class="icon"></i>
          </span>
          {{dashboardPage?.Exportreports_btn}}
         </button> -->
      </div>
    </div>
  </div>
  <div class="AddReportForm my-30" *ngIf="AddReport">
    <div class="addLocHeading brdr-bottom w-100 py-3">
      <h3>{{ mannedforecast?.GenerateReport }}</h3>
    </div>
    <form [formGroup]="reportsForm" class="py-5">
      <!-- <div class="row py-3 flex-row">
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 px-3">
          <p class="customclr2 py-2">{{ mannedforecast?.CitiesinSaudiaArabia }}</p>
          <div class="togglebg d-flex align-items-center justify-content-between px-2">
            <p class="m-0 customclr">{{ mannedforecast?.AllLocations }}</p>
            <div class="customtoggleone py-2">
              <input formControlName="alllocation" type="checkbox" id="toggle-button-checkbox">
              <label class="toggle-button-switch" for="toggle-button-checkbox"></label>
              <div class="toggle-button-text">
                <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 px-3">
          <p class="customclr2 py-2">{{ mannedforecast?.WeatherForecast }}</p>
          <div class="togglebg d-flex align-items-center justify-content-between px-2">
            <p class="m-0 customclr">Today Forecast</p>
            <div class="customtoggleone py-2">
              <input formControlName="weatherforecast" type="checkbox" id="toggle-button-checkbox2" >
              <label class="toggle-button-switch" for="toggle-button-checkbox2"></label>
              <div class="toggle-button-text">
                <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="py-3">
        <div>
          <p class="customclr2">{{ mannedforecast?.WeatherPhenomena }}</p>
          <div class="togglebg d-flex flex-column">
            <div class="flex-wrap d-flex align-items-center px-2">
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.Temperature }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="temperature" type="checkbox" id="toggle-button-checkbox3" checked>
                    <label class="toggle-button-switch" for="toggle-button-checkbox3"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.WindSpeed }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="windspeed" type="checkbox" id="toggle-button-checkbox4">
                    <label class="toggle-button-switch" for="toggle-button-checkbox4"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.WindDirection }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="winddirection" type="checkbox" id="toggle-button-checkbox5">
                    <label class="toggle-button-switch" for="toggle-button-checkbox5"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.WindPressure }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="windpressure" type="checkbox" id="toggle-button-checkbox6">
                    <label class="toggle-button-switch" for="toggle-button-checkbox6"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.Precipitation }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="precipitation" type="checkbox" id="toggle-button-checkbox7">
                    <label class="toggle-button-switch" for="toggle-button-checkbox7"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.Humidity }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="humidity" type="checkbox" id="toggle-button-checkbox8" checked>
                    <label class="toggle-button-switch" for="toggle-button-checkbox8"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.Probability }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="probability" type="checkbox" id="toggle-button-checkbox9">
                    <label class="toggle-button-switch" for="toggle-button-checkbox9"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.SlipperyRoad }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="slipperyroad" type="checkbox" id="toggle-button-checkbox10">
                    <label class="toggle-button-switch" for="toggle-button-checkbox10"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-wrap flex-row align-items-center justify-content-between py-3 px-0">
                <div class="col-md-5 col-sm-12 col-12">
                  <p class="m-0 customclr">{{ mannedforecast?.Dust }}</p>
                </div>
                <div class="col-md-5 col-sm-12 col-12 py-2">
                  <div class="customtoggleone py-2">
                    <input formControlName="dust" type="checkbox" id="toggle-button-checkbox11">
                    <label class="toggle-button-switch" for="toggle-button-checkbox11"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div> -->
              <!-- <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12 d-flex flex-wrap flex-row align-items-center justify-content-between py-3 px-0">
                <div class="col-md-5 col-sm-12 col-12">
                  <p class="m-0" style="color: #F9FAFB;">{{ mannedforecast?.Temperature }}</p>
                </div>
                <div class="col-md-5 col-sm-12 col-12 py-2">
                  <div class="customtoggleone py-2" style="display: none;">
                    <input type="checkbox" id="toggle-button-checkbox12">
                    <label class="toggle-button-switch" for="toggle-button-checkbox12"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
            <div class="align-items-center d-flex px-2 flex-wrap">
              <div class="colclass d-flex flex-nowrap gap-3 flex-row align-items-center justify-content-between py-3 px-3">
                <div class="col">
                  <p class="m-0 customclr">{{ mannedforecast?.SelectAll }}</p>
                </div>
                <div class="col py-2">
                  <div class="customtoggleone py-2">
                    <input (change)="OnSelectAll($event)" type="checkbox" id="toggle-button-checkbox13">
                    <label class="toggle-button-switch" for="toggle-button-checkbox13"></label>
                    <div class="toggle-button-text">
                      <div class="toggle-button-text-on">{{ mannedforecast?.On }}</div>
                      <div class="toggle-button-text-off">{{ mannedforecast?.Off }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end align-items-center gap20 flex-wrap">
        <button (click)="AddReport = false;" type="button" class="canceltxt">
          {{ mannedforecast?.Cancel }}
        </button>
        <!-- <button type="button" class="publishbtn">
          {{ mannedforecast?.Publish }}
        </button> -->
        <button (click)="publishReport()" type="button" class="previewbtn">
          {{ mannedforecast?.Preview }}
        </button>
      </div>
    </form>
  </div>
  <div class="AddUserTable my-30" *ngIf="searchdata?.data?.length > 0">
    <!-- <div class="addLocHeading">

    </div> -->
    <div class="d-flex align-items-center justify-content-between title-container flex-wrap">
      <div class="title col-lg-7 col-md-6 col-sm-12 col-12">
        <h3 class="tableheadertext">{{ mannedforecast?.RecentReportsGenerated }}</h3>
      </div>
      <div class="d-flex flex-wrap align-items-center justify-content-end gap-2 col-lg-5 col-md-6 col-sm-12 col-12">
        <!-- <input class="searchbox col-lg-7 col-md-6 col-sm-12 col-12" type="text" [placeholder]="mannedforecast?.Search" (keyup)="onTypeUser($event)"> -->
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="client-type-select col-lg-7 col-md-6 col-sm-12 col-12" appearance="outline">
          <mat-label>{{mannedforecast?.Search}}</mat-label>
          <input type="text" matInput (keyup)="onTypeUser($event)">
        </mat-form-field>

        <!-- <ng-select class="col-lg-3 col-md-6 col-sm-12 col-12" (change)="onChangeEntry($event)"
          [searchable]="false" [clearable]="false" [placeholder]="mannedforecast?.Show+': 10'">
          <ng-option [value]="entry.value" *ngFor="let entry of entries; index as i">
            <span *ngIf="language == 'en'">{{entry?.label}}</span>
            <span *ngIf="language == 'ar'">{{entry?.label_ar}}</span>
          </ng-option>
        </ng-select> -->
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" appearance="outline" class="client-type-select col-lg-3 col-md-6 col-sm-12 col-12">
          <mat-label>{{mannedforecast?.Show}}</mat-label>
          <mat-select (valueChange)="onChangeEntry($event)" [placeholder]="mannedforecast?.Show+': 10'" floatLabel="always">
            <mat-option [value]="entry.value" *ngFor="let entry of entries; index as i">{{language == 'en' ? entry?.label : entry?.label_ar}}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <div class="copy-excel-print col-lg-1 col-md-6 col-sm-12 col-12">
          <button class="col-lg-12 col-md-12 col-sm-12 col-12" [ngbTooltip]="mannedforecast?.Print" tooltipClass="custom-tooltip">
            <img src="../../../assets/mannedforecast/printpreview-icon.svg">
          </button>
        </div> -->
      </div>
    </div>
    <div class="mb-30" style="overflow-x: auto;">
      <table class="customTable">
        <thead>
          <tr>
            <th>{{ mannedforecast?.Createdby }}</th>
            <th>{{ mannedforecast?.Email }}</th>
            <th>{{ mannedforecast?.Createdon }}</th>
            <th class="text-center">{{ mannedforecast?.Actions }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let list of searchdata?.data">
            <td>{{list.createdBy }}</td>
            <td>{{list.email }}</td>
            <td *ngIf="language=='en'">{{list.createdOn | date: "dd-MM-yyyy":"":"en" }}</td>
            <td *ngIf="language=='ar'">{{list.createdOn | date: "dd-MM-yyyy":"":"ar" }}</td>
            <td>
              <div class="">
                <!-- <div class="d-flex align-items-center gap-3 no-wrap">
                  <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="d-flex"
                    [ngbTooltip]="mannedforecast?.Forward" tooltipClass="custom-tooltip">
                    <img src="../../../assets/mannedforecast/forward-icon.png" style="scale: 0.8;">
                  </button>
                </div> -->
                <!-- <div class="d-flex align-items-center gap-3 no-wrap">
                  <button class="d-flex gap-2">
                    <img src="../../../assets/mannedforecast/editforecast-icon.png">
                  </button>
                </div> -->
                <div class="">
                  <button (click)="generatePdfReport(list?.id)" class=""
                    [ngbTooltip]="mannedforecast?.Pdf" tooltipClass="custom-tooltip">
                    <img src="../../../assets/mannedforecast/pdfforecast-icon.png" style="scale: 0.8;">
                  </button>
                </div>
                <!-- <div class="d-flex align-items-center gap-3 no-wrap">
                  <button class="d-flex gap-2">
                    <img src="../../../assets/mannedforecast/deleteforecast-icon.png">
                  </button>
                </div> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- <div style="margin: 2em auto;" *ngIf="searchdata?.length > 0" class="pagination dir-left row">
      <div class="text col-lg-4 col-md-5 col-sm-12 col-12">{{ mannedforecast?.Show }} {{((page - 1) * pageSize) + 1}}
        {{ mannedforecast?.To }} {{page * pageSize}} {{ mannedforecast?.Entries }}</div>
      <ngb-pagination class="col-lg-4 col-md-5 col-sm-12 col-12" style="width: auto;padding: inherit;"
        [boundaryLinks]="false" [maxSize]="3" [pageSize]="pageSize"
        [collectionSize]="searchdata.length"></ngb-pagination>
    </div> -->
    <div *ngIf="searchdata?.paginationVariables?.pageCount > 1" class="col-12 d-flex justify-content-center pagination dir-left mb-30">
      <ngb-pagination [maxSize]="3" [rotate]="true" [pageSize]="pageSize" [page]="page" (pageChange)="onPageChange($event)"
        [collectionSize]="searchdata?.paginationVariables?.totalCount"></ngb-pagination>
    </div>
  </div>
</div>
<!-- <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header mx-3">
        <h3 class="modalheadtext m-0" id="exampleModalLabel">{{ mannedforecast?.ForwardtoEmailaddress }}</h3>
      </div>
      <div class="modal-body">
        <div class="row">
          <p class="frwdemailmodaltext col-lg-12 col-md-12 col-sm-12 col-12 mx-1">{{ mannedforecast?.Pleaseenterforwardingemailaddress }}</p>
          <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" placeholder="muhammadahmed@ncm.gov.sa" class="frwdemailmodalinput col-lg-12 col-md-12 col-sm-12 col-12 mx-3">
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="forwardcancelbtn" data-bs-dismiss="modal">
          {{ mannedforecast?.Cancel }}
        </button>
        <button type="button" class="forwardfwdbtn">
          {{ mannedforecast?.Forward }}
        </button>
      </div>
    </div>
  </div>
</div> -->

import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { LanguageService } from "src/app/services/language/language.service";
import { SignupModel } from "src/app/models/signup/signup.model";
import { AuthenticationService } from "../service/authentication.service";
import { GlobalService } from "src/app/shared/utilities/global";
import { Subscription } from "rxjs";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PrivacyPolicyModalComponent } from "src/app/shared/privacy-policy-modal/privacy-policy-modal.component";

@Component({
  selector: "app-signup",
  templateUrl: "./signup.component.html",
  styleUrls: ["./signup.component.css"],
})
export class SignupComponent implements OnInit, OnDestroy {
  signup: any;
  SignupForm: FormGroup;
  isChecked: boolean;
  language: string;
  submitted: boolean = false;
  isPasswordValid: boolean;
  user: any;
  token: any;
  private subscription: Subscription = new Subscription();
  isLanguageChecked: boolean = true;
  constructor(
    private globalService: GlobalService,
    private formBuilder: FormBuilder,
    private router: Router,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
  ) {
    this.subscription.add(this.activatedRoute.queryParams.subscribe((res) => {
      if (res.token) {
        this.validateToken(res.token);
        this.token = res.token;
      }
    }));



    let currentlanguage = localStorage.getItem("language");

    if (currentlanguage) {
      if (currentlanguage === "en") {
        this.isChecked = false;
      } else {
        this.isChecked = true;
      }
    } else {
      this.isChecked = false;
    }
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.subscription.add(this.translate.get("Signup").subscribe((res) => {
        this.signup = res;
      }));
    }));
  }

  ngOnInit() {
    this.SignupForm = this.formBuilder.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
      email: ["",],
      password: ["", Validators.required],
      confirmPassword: ["", Validators.required],
      terms: [false]
    });
  }

  get signupFormControls() {
    return this.SignupForm.controls;
  }

  validateToken(token: string) {
    this.authenticationService.tokenValidate(token).then(
      (res: {success:boolean; message:string; data:string}) => {
        if (res.success) {
          this.user = res.data;
          this.setUserForm(this.user);
        } else {
          this.toast.error(res.message);
        }
      })
      .catch((err: HttpErrorResponse) => {
        this.toast.error(err.error.message);
      });
  }

  setUserForm(user: any) {
    this.signupFormControls.firstName.setValue(user.name.split(' ')[0]);
    this.signupFormControls.lastName.setValue(user.name.split(' ')[1]);
    this.signupFormControls.email.setValue(user.email);
    this.signupFormControls.email.disable();
  }

  checkPasswordValidation() {
    let password = this.signupFormControls.password.value;
    let confirmPassword = this.signupFormControls.confirmPassword.value;

    if (!password && !this.submitted) {
      return;
    }
    if (password === confirmPassword) {
      this.isPasswordValid = true;
    } else {
      this.isPasswordValid = false;
    }
  }

  onChangeLanguage(checked: boolean) {
    if (checked) {
      this.languageService.changeLanguage("ar");
      document.body.classList.remove("english-font");
      document.body.classList.add("arabic-font");
      document.body.setAttribute("dir", "rtl");
    } else {
      this.languageService.changeLanguage("en");
      document.body.classList.remove("arabic-font");
      document.body.classList.add("english-font");
      document.body.setAttribute("dir", "ltr");
    }
  }

  getUsers() {
    this.globalService.showLoader();
    this.authenticationService.getUserDetails().toPromise().then(
      (res: any) => {
        if (res.success) {
          this.globalService.setUser(res.data);
          localStorage.setItem("user", JSON.stringify(res.data));
          this.toast.success("Logged In Successfully");
          this.router.navigateByUrl("home/welcome");
        }
        this.globalService.hideLoader();
      },
      (err: HttpErrorResponse) => {
        this.globalService.hideLoader();
        this.toast.error(err.error);
      }
    );
  }

  getUserPermissions(){
    this.globalService.showLoader();
    this.authenticationService.getUserPermissions().then(
      (res:any)=>{
        if(res.success){
          this.globalService.setPermissions(res.grants);
        }
        this.globalService.hideLoader();
      }
    )
  }

  openModal(param:'terms' | 'policy'){
    this.modalService.dismissAll();
    const modal = this.modalService.open(PrivacyPolicyModalComponent,{
      backdrop:true,
      centered:true,
      windowClass:'privacy-modal'
    });
    if(param == 'policy'){
      modal.componentInstance.policy = true;
    }
    else{
      modal.componentInstance.terms = true;
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.SignupForm.invalid) {
      return;
    }
    this.checkPasswordValidation();
    if (!this.isPasswordValid) {
      return;
    }

    if(!this.signupFormControls.terms.value){
      this.toast.error('You must agree to terms and conditions')
      return
    }

    const user: SignupModel = new SignupModel();
    user.email = this.signupFormControls.email.value;
    user.firstName = this.signupFormControls.firstName.value;
    user.lastName = this.signupFormControls.lastName.value;
    user.password = this.signupFormControls.password.value;
    user.confirmPassword = this.signupFormControls.confirmPassword.value;
    user.type = 'user';
    this.globalService.showLoader();
    this.authenticationService.register(user, this.token).toPromise().then(
      (res: any) => {
        if (res.success) {
          this.toast.success(res.message);
          this.globalService.logOut();
        } else {
          this.toast.error(res.message);
        }
        this.globalService.hideLoader();
      },
      (error: HttpErrorResponse) => {
        this.globalService.hideLoader();
        this.toast.error(error.error.message);
      }
    );
  }


  showPassword(event:any, id){
    if(event.target.classList.contains('fa-eye')){
      event.target.classList.replace('fa-eye', 'fa-eye-slash');
      document.getElementById(id).setAttribute('type', 'password');
    }
    else{
      event.target.classList.replace('fa-eye-slash', 'fa-eye');
      document.getElementById(id).setAttribute('type', 'text');
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

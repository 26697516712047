import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MannedAlertsModel } from 'src/app/models/manned-alerts/manned-alerts.model';
import { GlobalService } from "./../../shared/utilities/global";
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: 'root'
})
export class ApisService {

  private baseUrl = `${environmentBaseUrl.url}/api/ncm/`;

  constructor(private globalService: GlobalService, private http: HttpClient) { }


  CreateEwsnotification(location: {
    name: string;
    coordinates: string[];
    title: string;
    address_components: any[];
    severeWeatherAlert: boolean;
  }) {
    const url = `${this.baseUrl}create_ewsnotification`;
    return this.globalService.PostApi(
      url,
      {
        name: location.name,
        coordinates: location.coordinates,
        title: location.title,
        Authorization: this.globalService.getToken(),
        address_components: location.address_components,
        severeWeatherAlert: location.severeWeatherAlert,
      },
      false
    );
  }

  ListEwsnotification(list:MannedAlertsModel, Authorization:string) {
    const url = `${this.baseUrl}list_ewsnotification`;
    return this.globalService.PostApi(url, list, true);
  }

  getAlertAction(Authorization:string){
    const url = `${this.baseUrl}alert_action`;
    return this.http.get(url).toPromise();
  }

  getAlertHazard(Authorization:string){
    const url = `${this.baseUrl}alert_hazard`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

  getAlertType(Authorization:string){
    const url = `${this.baseUrl}alert_type`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

  getAlertStatus(Authorization:string){
    const url = `${this.baseUrl}alert_status`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

  getGovernorate(Authorization:string){
    const url = `${this.baseUrl}governorate`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

  DetailEwsnotification(id:number, Authorization:string){
    const url = `${this.baseUrl}detail_ewsnotification`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

  Event(Authorization:string){
    const url = `${this.baseUrl}event`;
    return this.http.get(url).toPromise();
  }

  WeatherPhenomenon(Authorization:string){
    const url = `${this.baseUrl}weather_phenomenon`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

  WeatherPhenomenonAffect(Authorization:string){
    const url = `${this.baseUrl}weather_phenomenon_affect`;
    return this.http.post(url, MannedAlertsModel).toPromise();
  }

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundPrecipitationValue'
})
export class RoundPrecipitationValuePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value < 1) {
      return value;
    }
    else {
      return Math.round(value);
    }
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PackagesComponent } from './packages.component';
import { PackagesRoutes } from './packages.routing';
import { SharedModule } from 'src/app/shared/shared.module';
import { UpsertPackagesComponent } from './upsert-packages/upsert-packages.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  imports: [
    CommonModule,
    PackagesRoutes,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild()
  ],
  declarations: [
    PackagesComponent,
    UpsertPackagesComponent,
  ]
})
export class PackagesModule { }

import { ButtonComponent } from "./../shared/components/button/button.component";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { CommonModule, DatePipe, registerLocaleData } from "@angular/common";

import { AddClientModule } from "./add-client/add-client.module";
import { AddUsersModule } from "./add-users/add-users.module";
import { UserProfileModule } from "./user-profile/user-profile.module";
import { FeaturesRoutingModule } from "./features-routing.module";
import { MannedForecastModule } from "./manned-forecast/manned-forecast.module";
import { AlertHistoryModule } from "./alert-history/alert-history.module";
import { AlertEditorModule } from "./alert-editor/alert-editor.module";
import { NgbTooltipModule, NgbPaginationModule } from "@ng-bootstrap/ng-bootstrap";
import { AutomaticWeatherReportModule } from "./automatic-weather-report/automatic-weather-report.module";
import myLocaleAr from '@angular/common/locales/ar';
import { PackagesModule } from "./packages/packages.module";
import { UserGroupModule } from "./user-group/user-group.module";
registerLocaleData(myLocaleAr);

@NgModule({
  declarations: [
    ButtonComponent,
  ],

  imports: [
    CommonModule,
    FeaturesRoutingModule,
    HttpClientModule,
    AlertHistoryModule,
    AlertEditorModule,
    AddClientModule,
    AddUsersModule,
    UserProfileModule,
    AutomaticWeatherReportModule,
    MannedForecastModule,
    NgbPaginationModule,
    NgbTooltipModule,
    PackagesModule,
    UserGroupModule
  ],
  exports: [ButtonComponent],
  providers: [DatePipe],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FeatureModule { }


import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  getToken(){
    return localStorage.getItem('token');
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const token = this.getToken();
    const url = req.url.split("/").pop();
    if(url == 'forgot-password' || url == 'token-validate' || url.includes('register-user') || url == 'public-query' || url.includes('reset-password') || !token){
      const normalReq = req.clone({
        body: {...req.body}
      });
      return next.handle(normalReq);
    }
    if(token && !req?.body?.Authorization){
      const tokenReq = req.clone({
        body: { ...req.body, Authorization: token }
      });
      return next.handle(tokenReq);
    }
    const normalReq = req.clone({
      body: {...req.body}
    });
    return next.handle(normalReq);
  }
}

import { Routes, RouterModule } from '@angular/router';
import { MannedForecastComponent } from './manned-forecast.component';
import { MannedPreviewComponent } from './preview/mannedpreview.component';

const routes: Routes = [
  {
    path: '',
    component: MannedForecastComponent,
  },
  {
    path: 'manned-forecast-preview',
    component: MannedPreviewComponent,
  },
];

export const MannedForecastRoutes = RouterModule.forChild(routes);

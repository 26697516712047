import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DraftComponent } from './draft/draft.component';
import { PreviewComponent } from './preview/preview.component';
import { RegionComponent } from './region/region.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AlertEditorRoutes } from './alert-editor.routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { NgbModule, NgbModalModule, NgbTooltipModule, NgbPaginationModule, NgbDropdownModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgApexchartsModule } from 'ng-apexcharts';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MenubarModule } from 'primeng/menubar';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TableModule } from 'primeng/table';
import { ToolbarModule } from 'primeng/toolbar';

@NgModule({
  declarations: [
    RegionComponent,
    PreviewComponent,
    DraftComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgbModalModule,
    ToastrModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    LeafletModule,
    LeafletDrawModule,
    NgbDatepickerModule,
    GooglePlaceModule,
    NgxMaterialTimepickerModule,
    MenubarModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    NgApexchartsModule,
    TableModule,
    SelectButtonModule,
    InputTextModule,
    SharedModule,
    NgxPermissionsModule.forChild(),
    AlertEditorRoutes,
  ]
})
export class AlertEditorModule { }

import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GenerateAutomaticWeatherReportPdf, ListReportModel, UpdateMannedForecastModel } from 'src/app/models/manned-forecast/manned-forecast.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { AutomaticWeatherReport } from 'src/app/services/automatic-weather-report/automatic-weather-report.service';
interface KingdomReportModel {
  success: boolean
  data: KingdomReportDataModel[]
  paginationVariables: PaginationVariables
}

interface KingdomReportDataModel {
  reportNameEn?: string
  reportNameAr?: string
  reportPathEn: string
  reportPathAr: string
  generatedAt: string
}

@Component({
  selector: 'app-list-warnings',
  templateUrl: './list-warnings.component.html',
  styleUrls: ['./list-warnings.component.scss']
})
export class ListWarningsComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  private searchInputChange = new Subject<string>();
  private KsearchInputChange = new Subject<string>();

  language: string;
  earlyWarningsPage: any;
  navItems:string[] = ['RecentReportsGenerated','KingdomReports'];
  activeNav:string = this.navItems[0];
  automaticWeatherPage: any;
  mannedforecast: any;

  pageSize: number = 10;
  page: number = 1;
  KpageSize: number = 10;
  Kpage: number = 1;
  search: string;
  Ksearch: string;
  searchdata:any;
  entries: { id: number, label: string, value: number, label_ar: string,}[];
  selectedEntry: { id: number, label: string, value: number };

  generateReport:any;
  KingdomReport: KingdomReportModel;

  reportDetail: string
  reportDetailAR: string
  listReportModel: ListReportModel = new ListReportModel();
  sortarray: string[] = [];
  sortByCreated: boolean;
  listReportModelK: ListReportModel = new ListReportModel();
  sortarrayK: string[] = [];
  sortByCreatedK: boolean;

  constructor(
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private globalService: GlobalService,
    private mannedForecastService: MannedForecast,
    private toast: ToastrService,
    private router: Router,
    private translate: TranslateService,
    private modalService: NgbModal,
    private automaticWeatherReportservice: AutomaticWeatherReport,
    ) {
      this.entries = [
        { id: 1, label: 'Show: 10', value: 10, label_ar: 'عرض: 10' },
        { id: 2, label: 'Show: 20', value: 20, label_ar: 'عرض: 20' },
        { id: 3, label: 'Show: 30', value: 30, label_ar: 'عرض: 30' },
        { id: 4, label: 'Show: 40', value: 40, label_ar: 'عرض: 40' },
        { id: 5, label: 'Show: 50', value: 50, label_ar: 'عرض: 50' },
      ];
      this.selectedEntry = this.entries[0];
    }

  ngOnInit() {
    this.getCurrentLanguage();
    this.searchInputChange.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.search = filterValue;
      this.page = 1
      this.getAllReports();
    });
    this.KsearchInputChange.pipe(
      debounceTime(400)
    ).subscribe((KfilterValue: string) => {
      this.Ksearch = KfilterValue;
      this.Kpage = 1
      this.getKingdomReport();
    });
    this.getAllReports();
    this.getKingdomReport();

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }

  onTypeUser(event:Event){
    const input = event.target as HTMLInputElement;
    this.searchInputChange.next(input.value);
  }

  onKTypeUser(Kevent:Event){
    const input = Kevent.target as HTMLInputElement;
    this.KsearchInputChange.next(input.value);
  }

  onChangeEntry(entry: number) {
    this.pageSize = entry;
    this.getAllReports()
  }

  onKChangeEntry(Kentry: number) {
    this.KpageSize = Kentry;
    this.getKingdomReport()
  }

  onPageChange(pageNumber:number){
    this.page = pageNumber;
    this.getAllReports();
  }

  getCurrentLanguage() {
    this.subscriptions.add(
      this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("EarlyWarnings").subscribe((res) => {
        this.earlyWarningsPage = res;
      });
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticWeatherPage = res;
      });
      this.translate.get("MannedForecast").subscribe((res) => {
        this.mannedforecast = res;
      });
    }));
  }

  onNavChange(nav:string){
    this.activeNav = nav
  }

  getKingdomReport(sortfilter?: string){
    this.spinner.show();
    this.listReportModelK = new ListReportModel();
    if(sortfilter){
      if (!this.sortarrayK.includes(sortfilter)) {
        this.sortarrayK.push(sortfilter);
      }
    }
    this.listReportModelK.page = this.Kpage;
    this.listReportModelK.limit = this.KpageSize;
    if(this.Ksearch){
      this.listReportModelK.search = this.Ksearch;
    }
    if(this.sortarrayK?.length > 0){
      if (this.sortarrayK.includes('sortByCreated')){
        if(sortfilter === 'sortByCreated'){
          this.sortByCreatedK = !this.sortByCreatedK
        }
        this.listReportModelK.sortByCreated = this.sortByCreatedK ? 'asc' : 'desc'
      }
    }
    this.mannedForecastService.getTodayReport(this.listReportModelK)
    .then((res: KingdomReportModel) => {
      this.spinner.hide();
      this.KingdomReport = res;
    })
    .catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  getAllReports(sortfilter?: string){
    this.spinner.show();
    this.listReportModel = new ListReportModel();
    if(sortfilter){
      if (!this.sortarray.includes(sortfilter)) {
        this.sortarray.push(sortfilter);
      }
    }
    this.listReportModel.page = this.page;
    this.listReportModel.limit = this.pageSize;
    this.listReportModel.report_type = "automatic";
    if(this.search){
      this.listReportModel.search = this.search;
    }
    if(this.sortarray?.length > 0){
      if (this.sortarray.includes('sortByCreated')){
        if(sortfilter === 'sortByCreated'){
          this.sortByCreated = !this.sortByCreated
        }
        this.listReportModel.sortByCreated = this.sortByCreated ? 'asc' : 'desc'
      }
    }
    this.mannedForecastService.getListReport(this.listReportModel).then(
      (res: any) => {
        this.spinner.hide();
        this.searchdata = res;
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  generatePdfReport(id:number){
    this.spinner.show();
    const generateAutomaticWeatherReportPdf: GenerateAutomaticWeatherReportPdf = new GenerateAutomaticWeatherReportPdf();
    generateAutomaticWeatherReportPdf.id = id;
    generateAutomaticWeatherReportPdf.lang = this.language;
    this.mannedForecastService.generateAutomaticPDFReport(generateAutomaticWeatherReportPdf).then(
      (res: any) => {
        if(res.success){
          this.spinner.hide();
          this.generateReport = res.data;
          window.open(this.generateReport);
        }
        else{
          this.spinner.hide();
          this.toast.error('PDF Report Not Available. Please try later.');
        }
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }

  goTOedit(list: any){
    if(list?.reportTypeKey == '10-day-forecast-report'){
      this.mannedForecastService.previewdata = [list?.report];
      this.mannedForecastService.locationslist = [];
      this.mannedForecastService.start_date = list?.startDate;
      this.mannedForecastService.end_date = list?.endDate;
      this.mannedForecastService.report_title = list?.reportTitleEn;
      this.mannedForecastService.report_id = list?.id;
      this.automaticWeatherReportservice.advanceCustomData = null;
      this.router.navigate(['home/manned-forecast/manned-forecast-preview']);
    }
    else if(list?.reportTypeKey == 'advance-custom-weather-report' && list?.isMannedAdvanceCustomReport == false){
      let advanceCustomData = {
        reporttypekey: list?.reportTypeKey,
        reporttypeid: list?.reportTypeId,
        // selectedParams: reportnames,
        // selectedIds: governateIds,
        descriptionEn: list?.reportDescriptionEn,
        descriptionAr: list?.reportDescriptionAr,
        titleEn: list?.reportTitleEn,
        titleAr: list?.reportTitleAr,
        // historicalDate: historicalDateStr,
        // historicalDays: 1,
        // forecastedDate: forecastedDateStr,
        // forecastedDays: 1,
        // todayDate: this.todayDate,
        // channel: this.channelForm.getRawValue(),
        // emailGroups: this.selectedEmailGroups
      }
      this.automaticWeatherReportservice.advanceCustomData = advanceCustomData;
      this.automaticWeatherReportservice.advanceCustomPreviewData = [list?.report];
      this.automaticWeatherReportservice.advanceCustomId = list?.id;
      this.automaticWeatherReportservice.advanceCustomStartDate = list?.startDate;
      this.automaticWeatherReportservice.advanceCustomEndDate = list?.endDate;
      this.mannedForecastService.previewdata = [];
      this.router.navigate(['home/manned-forecast/manned-forecast-preview']);
    }
    else if(list?.reportTypeKey === 'custom-weather-report') {
      const params: NavigationExtras = {
          queryParams: {
              reporttype: list?.reportTypeId,
              reportKey: list?.reportTypeKey,
              reportdata: JSON.stringify(list?.report),
              descriptionEn: list?.reportTypeEn,
              descriptionAr: list?.reportTypeAr,
              titleEn: list?.reportTitleEn,
              titleAr: list?.reportTitleAr,
              reportId: list?.id
          }
      };
      this.router.navigate(['home/automatic-weather-report/automatic-weather-report-preview'], params);
  } else {
      const params: NavigationExtras = {
          queryParams: {
              reporttype: list?.reportTyeId,
              reportKey: list?.reportTypeKey,
              reportdata: JSON.stringify(list?.report),
              descriptionEn: list?.reportTypeEn,
              descriptionAr: list?.reportTypeAr,
              titleEn: list?.reportTitleEn,
              titleAr: list?.reportTitleAr,
              reportId: list?.id,
              surfaceWind: list?.surfaceWindEn,
              waveHeight: list?.waveHeightEn,
              stateOfTheSea: list?.stateOfTheSeaEn,
              surfaceWindAr: list?.surfaceWindAr,
              waveHeightAr: list?.waveHeightAr,
              stateOfTheSeaAr: list?.stateOfTheSeaAr
          }
      };
      this.router.navigate(['home/automatic-weather-report/automatic-weather-report-preview'], params);
  }
  }

  titleEdit(content: any, list: any){
    this.reportDetail = list?.reportDescriptionEn || ''
    this.reportDetailAR = list?.reportDescriptionAr || ''
    const titleEdit = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered:true,
      modalDialogClass:'modal-lg',
      backdrop: 'static'
    });
    titleEdit.result.then((result: any) => {
      const updateMannedForecastModel: UpdateMannedForecastModel = new UpdateMannedForecastModel()
      updateMannedForecastModel.start_date = list?.startDate
      updateMannedForecastModel.end_date = list?.endDate
      updateMannedForecastModel.report_type_id = list.reportTypeId
      updateMannedForecastModel.report_id = list?.id
      updateMannedForecastModel.lang = localStorage.getItem("language");
      updateMannedForecastModel.data = list?.report
      updateMannedForecastModel.report_title = this.reportDetail
      updateMannedForecastModel.report_title_ar = this.reportDetailAR

      this.spinner.show()
      this.mannedForecastService.publishMannedForecastReport(updateMannedForecastModel)
      .then((res: {data: number, message: string, success: boolean})=>{
        if(res.success){
          this.reportDetail = ''
          this.reportDetailAR = ''
          this.getAllReports()
          this.toast.success(res.message)
        } else {
          this.toast.error(res.message)
        }
      })
      .catch((err) => {
        this.spinner.hide();
        this.globalService.handleError(err);
      })
      .finally(()=>{
        this.spinner.hide();
      })
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

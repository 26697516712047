import { PaginationVariables } from "../pagination/pagination.model";

export class CreatePackageModel {
  name :string;
  name_ar? :string;
  tenure : number;
  api_groups: number[];
  price : number;
  max_locations: number;
  max_users: number;
}
export class CreateWSOModel {
  name: string
  price: number
  tenure: number
  throttling: number
}
export class UpdatePackageModel extends CreatePackageModel {
  id: number;
}
export class UpdateWSOModel extends CreateWSOModel {
  id: number;
}
export class CreateWSOUser {
  subscription_id?: number
  name: string
  email: string
  package_id: number
}
export class GetListPackageModel {
  page:number;
  page_size:number;
  clientType?: string;
  organization_id?:number;
  search?: string
  api_group?:number
  sortBytenure?: string;
  sortByprice?: string;
  sortByMaxUsers?: string;
  sortByMaxLocations?: string;

  constructor(){
    this.page = 1;
    this.page_size = 10;
  }
}
export class getListWSOModel {
  page:number;
  page_size:number;
  search?: string
  sortByprice?: string;
  sortBytenure?: string;
  sortByThrottling?: string;
  sortByStatus?: string;

  constructor(){
    this.page_size = 10;
  }
}
export class getListWSOModelUser {
  page:number;
  page_size:number;
  search?: string
  package_id?: number

  constructor(){
    this.page_size = 10;
  }
}

export class ListPackagesResponseModel {
  success: boolean;
  data: ListPackages[];
  paginationVariables: PaginationVariables;
  message: string;

  constructor(){
    this.data = [];
  }
}


export interface ListApiGroup {
  totalApiGroups?: number;
  id?: number;
  type?: string;
  name?: string;
  name_ar?:string;
}

export class ListPackagesModel {
  data:ListPackages[];
  paginationVariables:PaginationVariables;
  success:boolean;

  constructor(){
    this.data = []
  }
}

export interface ListPackages {
  id: number;
  type: string;
  name?: string;
  name_ar?: string;
  tenure: number;
  max_locations: number;
  max_users: number;
  assign_users: number
  api_groups: ListApiGroup[];
}


export interface ListPrivatePackages {
  id: number
  type: string
  group_name: string
  name: string
  name_ar: string
}

export interface NewPrivatePackage {
  success: boolean
  data: number
  message: string
}
export class SuspendPackageModel {
  id :number;
}
export class ResumePackageModel {
  id :number;
}
export class RenewPackageModel {
  id :number;
  month ?:number;
  days :number;
}

import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, inject } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { EMPTY, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Directive({
  selector: 'mat-option[selectAll]',
  standalone: true,
})
export class SelectAllDirective implements OnChanges, OnDestroy {
    @Input() allValues: any[] = [];

    private _matSelect = inject(MatSelect);
    private _matOption = inject(MatOption);
    
    private _subscriptions: Subscription[] = [];

    ngOnChanges(changes: SimpleChanges): void {
      const parentSelect = this._matSelect;
      const parentFormControl = parentSelect.ngControl.control;

      this._subscriptions.push(
        this._matOption.onSelectionChange.pipe(
          switchMap((ev) => {
            if (ev.isUserInput) {
              if (ev.source.selected) {
                parentFormControl?.setValue(this.allValues);
                this._matOption.select();
              
              } else {
                parentFormControl?.setValue([]);
                this._matOption.deselect();
              }
            }
            return EMPTY;
          })
        ).subscribe()
      );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((s) => s.unsubscribe());
    }
}

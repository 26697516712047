import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ParameterNames'
})
export class ParameterNamesPipe implements PipeTransform {
  transform(value: any, language?: string): string {

    let lang = localStorage.getItem('language');
    if (language) {
      lang = language;
    }

    if (lang === 'en') {
      return value
    }
    else {
      switch (value) {
        case 't_min_2m_24h:C':
          return 'أدنى درجة حرارة';
        case 't_max_2m_24h:C':
          return 'أعلى درجة حرارة';
        case 'wind_dir_10m:d':
          return 'اتجاه الرياح';
        case 'wind_speed_10m:kmh':
          return 'سرعة الرياح';
        case 'max_individual_wave_height:m':
          return 'أقصى ارتفاع للأمواج الفردية';
        case 'visibility:km':
          return 'الرؤية';
        case 'relative_humidity_max_2m_24h:p':
          return 'أقصى نسبة رطوبة نسبية';
        case 'relative_humidity_min_2m_24h:p':
          return 'أدنى نسبة رطوبة نسبية';
        case 'first_high_tide:sql':
          return 'مرتفع المد';
        // case 'second_high_tide:sql':
        //   return 'مرتفع المد الثاني';
        case 'first_low_tide:sql':
          return 'منخفض المد';
        // case 'second_low_tide:sql':
        //   return 'منخفض المد الثاني';
        case 'precip_24h:mm':
          return 'تساقط';
        case 'prob_precip_24h:p':
          return 'احتمالا';
        case 'is_slippery_road_24h:idx':
          return 'طريق منزلق';
        case 'weather_symbol_24h:idx':
          return 'حالة الطقس';
        default:
          return value; // Return the original value if no match is found
      }
    }
  }
}

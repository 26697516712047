import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/guard/auth.guard";
import { FullComponent } from "./full/full.component";
import { AlertService } from "./services/alert-service/alert-service.service";

const Approutes: Routes = [
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "home",
    component: FullComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./features/features.module").then((m) => m.FeatureModule),
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: "**",
    redirectTo: "home/profile",
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(Approutes, { relativeLinkResolution: "legacy" }),
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: (service: AlertService) => () => service.getEsriKey(),
    //   deps: [AlertService],
    //   multi: true,
    // },
  ],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule {}

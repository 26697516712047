<div>
  <div class="modal-header mx-3">
    <h3 class="modalheadtext m-0">{{ mannedpreviewPage?.EditWeatherParameters }}</h3>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 mb-2">
        <mat-form-field style="width: 150px;" [dir]="language === 'en' ? 'ltr' : 'rtl'"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{mannedpreviewPage?.WeatherSymbol}}</mat-label>
          <mat-select [(ngModel)]="selectedSymbol" (valueChange)="onWeatherDescriptionChange($event)">
            <mat-select-trigger>
              <img class="preview-symbols" [src]="'../../../../assets/report_weather_icons/' + previewData[firstIndex]['parameters'][ParametersMap.weathersymbol][secondIndex].value + '.svg'" >
            </mat-select-trigger>
            <ng-container *ngFor="let symbol of symbols">
              <mat-option [value]="symbol">
                <img class="preview-symbols" [src]="'../../../../assets/report_weather_icons/' + symbol?.iconValue + '.svg'" >
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12 mb-2" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.weathersymbol]">
        <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{mannedpreviewPage?.WeatherDescription}}</mat-label>
          <mat-select [(ngModel)]="selectedSymbol" (valueChange)="onWeatherDescriptionChange($event)">
            <mat-select-trigger>
              {{language === 'en' ? previewData[firstIndex]['parameters'][ParametersMap.weathersymbol][secondIndex]['nameEn'] : previewData[firstIndex]['parameters'][ParametersMap.weathersymbol][secondIndex]['nameAr']}}
            </mat-select-trigger>
            <input matInput [formControl]="filterWeatherS" [placeholder]="mannedpreviewPage?.Search" matSelectFilter [section]="'Gov'" [control]="filterWeatherS" [items]="symbols" [language]="language" (filteredItems)="updateFilteredItems($event, 'Gov')" class="packageinputfilter">
            <ng-container *ngFor="let symbol of filtersymbols">
              <mat-option [value]="symbol">
                {{language === 'en' ? symbol?.nameEn : symbol?.nameAr}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.tmax]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #tempMaxId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.tmax][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.TemperatureMaximum }}</label>
          <span class="myspan" (click)="tempMaxId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.tmin]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #tempMinId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.tmin][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.TemperatureMinimum }}</label>
          <span class="myspan" (click)="tempMinId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.windspeed]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #windSpeedId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.windspeed][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.WindSpeed }}</label>
          <span class="myspan" (click)="windSpeedId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.winddirection]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <!-- <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #windDirectionId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.winddirection][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.WindDirection }}</label>
          <span class="myspan" (click)="windDirectionId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span> -->
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
            <mat-label>{{ mannedpreviewPage?.WindDirection }}</mat-label>
            <mat-select [(value)]="previewData[firstIndex]['parameters'][ParametersMap.winddirection][secondIndex].value">
              <mat-option *ngFor="let option of windDirectionOptions" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.windpressure]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #windPressureId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.windpressure][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.WindPressure }}</label>
          <span class="myspan" (click)="windPressureId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.precipitation]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #precipId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.precipitation][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.Precipitation }}</label>
          <span class="myspan" (click)="precipId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.humidity]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #humidityId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.humidity][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.Humidity }}</label>
          <span class="myspan" (click)="humidityId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.probability]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #probabilityId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.probability][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.Probability }}</label>
          <span class="myspan" (click)="probabilityId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]['parameters'][ParametersMap.slipperyroad]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #slipperyRoadId [(ngModel)]="previewData[firstIndex]['parameters'][ParametersMap.slipperyroad][secondIndex].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ mannedpreviewPage?.SlipperyRoad }}</label>
          <span class="myspan" (click)="slipperyRoadId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button (click)="onClose()" type="button" class="publishbtn">
      {{ mannedpreviewPage?.Close }}
    </button>
    <button (click)="onSave()" type="button" class="previewbtn">
      {{ mannedpreviewPage?.Save }}
    </button>
  </div>
</div>

import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LanguageService } from 'src/app/services/language/language.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChangePasswordModel } from 'src/app/models/change-password/change-password.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  changePasswordForm: FormGroup;
  submitted: boolean;

  isConformNewPasswordMatched: boolean = false;
  currentPassword: string;
  language: string;
  profile: any;
  resetPassword: any;

  token:string;
  isLanguageChecked: boolean = true;

  private subscription: Subscription = new Subscription();

  constructor(
    private formBuilder:FormBuilder,
    private globalService: GlobalService,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private router: Router,
    private authService: AuthenticationService,
    private route: ActivatedRoute
    ) { }

  ngOnInit(): void {
    this.subscription.add(this.route.queryParams.subscribe((param:{token:string})=>{
      if(!param?.token){
        this.router.navigateByUrl('auth/login');
      }
      else{
        this.token = param.token;
      }
    }));
    this.getCurrentLanguage()
    this.getUserPassword();
    this.changePasswordForm = this.formBuilder.group({
        newPassword: [null, Validators.required],
        confirmNewPassword: [null, Validators.required],
    })
  }

  getCurrentLanguage(){
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.subscription.add(this.translate.get("ResetPassword").subscribe((res) => {
        this.resetPassword = res;
      }));
    }));

    if(this.language === 'en'){
      this.isLanguageChecked = false;
    }
    else{
      this.isLanguageChecked = true;
    }
  }

  onChangeLanguage(checked: boolean) {
    if (checked) {
      this.languageService.changeLanguage("ar");
      document.body.classList.remove("english-font");
      document.body.classList.add("arabic-font");
      document.body.setAttribute("dir", "rtl");
    } else {
      this.languageService.changeLanguage("en");
      document.body.classList.remove("arabic-font");
      document.body.classList.add("english-font");
      document.body.setAttribute("dir", "ltr");
    }
  }

  getUserPassword(){
    this.currentPassword = this.globalService.getUserPassword();
  }

  conformNewPasswordValidator(){
    if(!this.changePasswordForm.controls.newPassword.value){
      this.isConformNewPasswordMatched = false;
      return;
    }

    if(
      this.changePasswordForm.controls.newPassword.value === this.changePasswordForm.controls.confirmNewPassword.value
    ){
      this.isConformNewPasswordMatched = true;
    }
    else{
      this.isConformNewPasswordMatched = false;
    }

    return this.isConformNewPasswordMatched;
  }

  onSubmit(){
    this.submitted = true;
    if(this.changePasswordForm.invalid) return;
    if(!this.conformNewPasswordValidator()) return;
    if(!this.token) return;

    this.spinner.show();

    const resetPasswordModel: ChangePasswordModel = new ChangePasswordModel();
    resetPasswordModel.newPassword = this.changePasswordForm.controls.newPassword.value;
    resetPasswordModel.confirmNewPassword = this.changePasswordForm.controls.confirmNewPassword.value;
    resetPasswordModel.Authorization = this.globalService.getToken();

    this.authService.resetPassword(resetPasswordModel, this.token).then(
      (res:any)=>{
        if(res.success){
          this.toast.success(res.message);
          this.router.navigateByUrl('auth/login');
        }
        else{
          this.toast.error(res.message);
        }
      }).catch((err: HttpErrorResponse)=>{
        if(err.status == 401){
          this.toast.error('Session Expired');
          this.router.navigateByUrl('auth/login');
        }
        this.toast.error(err.message);
      })
      .finally(()=>{
        this.spinner.hide();
      });
  }

  showPassword(event:any, id){
    if(event.target.classList.contains('fa-eye')){
      event.target.classList.replace('fa-eye', 'fa-eye-slash');
      document.getElementById(id).setAttribute('type', 'password');
    }
    else{
      event.target.classList.replace('fa-eye-slash', 'fa-eye');
      document.getElementById(id).setAttribute('type', 'text');
    }
  }

  arePasswordsMatch(newPassword: string, confirmPassword: string): boolean {
    return newPassword === confirmPassword;
  }
  showNewPasswords: boolean = false;
  showConfirmPasswords: boolean = false;
  toggleShowPasswordsVisibility(): void {
    this.showNewPasswords = !this.showNewPasswords;
  }
  toggleShowConfirmedPasswordsVisibility(): void {
    this.showConfirmPasswords = !this.showConfirmPasswords;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}

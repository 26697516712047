import { Component,  Input } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { ParametersMap } from 'src/app/models/manned-forecast/manned-forecast.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from 'src/app/shared/utilities/global';
import { FormControl } from '@angular/forms';

export interface PreviewData {
  city: string
  lat: number
  lon: number
  parameters: Parameter[] | any;
}

export interface Parameter {
  parameter: string
  dates: Date[]
}

export interface Date {
  date: string
  value: number;
  nameEn?:string;
  nameAr:String;
}


export interface GetWeatherReportResponse {
  success: boolean
  data: GetWeatherReportReportData[];
}

export interface GetWeatherReportReportData {
  symbols: Symbol[]
  icons: Icon[]
}

export interface Symbol {
  nameEn: string
  nameAr: string
  iconValue: string
}

export interface Icon {
  iconValue: string
}


@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent  {
  @Input() previewData:PreviewData[] = [];
  @Input() firstIndex:number;
  @Input() secondIndex:number;
  private subscriptions: Subscription = new Subscription()
  language:string;
  mannedpreviewPage: any;
  allPreviewData: PreviewData[];
  ParametersMap = ParametersMap;
  symbols: Symbol[] = [];
  selectedSymbol: Symbol
  icons: Icon[] = [];
  filterWeatherS = new FormControl<string>('',);
  filtersymbols: Symbol[];
  windDirectionOptions = [
    { label: 'N', value: 22.5 },
    { label: 'NW', value: 67.5 },
    { label: 'W', value: 112.5 },
    { label: 'SW', value: 157.5 },
    { label: 'S', value: 202.5 },
    { label: 'SE', value: 247.5 },
    { label: 'E', value: 292.5 },
    { label: 'NE', value: 337.5 },
    { label: 'N', value: 360 }
  ];

  constructor(
    private router: Router,
    private languageService: LanguageService,
    private translate: TranslateService,
    private mannedForecastService: MannedForecast,
    private activeModal:NgbActiveModal,
    private globalService: GlobalService
  ) {
     this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("MannedForecast").subscribe((res) => {
        this.mannedpreviewPage = res;
      });
    }));
  }

  ngOnInit(): void {
    this.getWeatherReportsSymbols();
    const currentValueNight = this.previewData[this.firstIndex]['parameters'][ParametersMap.winddirection][this.secondIndex].value;
    this.previewData[this.firstIndex]['parameters'][ParametersMap.winddirection][this.secondIndex].value = this.getUpperLimit(currentValueNight);
  }

  getWeatherReportsSymbols(){
    this.mannedForecastService.getWeatherReportSymbols()
    .then((res:GetWeatherReportResponse)=>{
      this.icons =  res.data[0].icons
      this.symbols =  res.data[0].symbols
      this.filtersymbols = this.symbols

      for (let i = 0; i < this.symbols.length; i++) {
        if (this.symbols[i].iconValue === this.previewData[this.firstIndex].parameters[this.ParametersMap.weathersymbol][this.secondIndex].value) {
          this.selectedSymbol = this.symbols[i]
          break;
        }
      }
    })
    .catch((error)=>{
      this.globalService.handleError(error)
    })
  }

  updateFilteredItems(filtered: any[], section: string) {
    if (section === 'Gov') {
      this.filtersymbols = filtered;
    }
  }

  onWeatherDescriptionChange(weatherSymbol:{nameEn:string; nameAr:string, iconValue:string}){
    this.previewData[this.firstIndex].parameters[this.ParametersMap.weathersymbol][this.secondIndex].nameEn = weatherSymbol.nameEn;
    this.previewData[this.firstIndex].parameters[this.ParametersMap.weathersymbol][this.secondIndex].nameAr = weatherSymbol.nameAr;
    this.previewData[this.firstIndex].parameters[this.ParametersMap.weathersymbol][this.secondIndex].value = weatherSymbol.iconValue
  }
  getUpperLimit(value: number): number {
    if (value <= 22.5) return 22.5;
    else if (value <= 67.5) return 67.5;
    else if (value <= 112.5) return 112.5;
    else if (value <= 157.5) return 157.5;
    else if (value <= 202.5) return 202.5;
    else if (value <= 247.5) return 247.5;
    else if (value <= 292.5) return 292.5;
    else if (value <= 337.5) return 337.5;
    else return 360;
  }

  goToMannedForecast() {
    this.router.navigate(['home/manned-forecast']);
  }

  onSave(){
    this.activeModal.close(this.previewData);
  }

  onClose(){
    this.activeModal.close();
  }
}

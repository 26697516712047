import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'roundWindDirectionValue'
})
export class RoundWindDirectionValuePipe implements PipeTransform {

  transform(value: number, ...args: unknown[]): unknown {
    if (value % 10 < 5) {
      return value - (value % 10);
    } else {
      return value + (10 - (value % 10));
    }
  }

}

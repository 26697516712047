export class AlertHistoryFilterModel {
  location_id: number;
  title: string;
  Authorization: string;
}

export class EWSNotificationListModel{
  unpublished: boolean
  fromDate:string;
  toDate:string;
  page: number
  page_size: number
}
export interface notificationListDataModel {
  id: number
  title: number
  alertType: number
  alertTypeAr: string
  alertTypeEn: string
  fromDate: string
  toDate: string
  alertStatusID: number
  alertStatusAr: string
  alertStatusEn: string
  alertStatusCategory: string
  alertHazard: AlertHazard[]
  regionID: number
  regionAR: string
  regionEn: string
  governorates: Governorate[]
  otherLocationsAr: string
  otherLocationsEn: any
  tweetID: string
  enableTwitterNotification: boolean
  enableSMSNotification: boolean
  enableEmailNotification: boolean
  alertActions: AlertAction[]
  municipalities: Municipality[]
  lastModified: string
  coordinates: string
  message: any[]
  file: any[]
  criteria: string
  created_at: string
  created_by: string
}

export interface AlertHazard {
  pim_id: number
  id: number
  nameEn: string
  nameAr: string
}

export interface Governorate {
  id: number
  nameEn: string
  nameAr: string
  longitude: string
  latitude: string
  municipalities: any[]
}

export interface AlertAction {
  pim_id: number
  id: number
  descriptionEn: string
  descriptionAr: string
}

export interface Municipality {
  id: number
  nameEn: string
  nameAr: string
  governate: number
}
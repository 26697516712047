import { GlobalService } from "src/app/shared/utilities/global";
import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { LanguageService } from "src/app/services/language/language.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-aside",
  templateUrl: "./aside.component.html",
  styleUrls: ["./aside.component.css"],
})
export class AsideComponent implements OnInit {
  activeLink: number = 0;
  isNavOpen: boolean = true;
  aside: any;
  language: string;

  links = [
    { name: "Dashboard",url: "/home/dashboard",},
    { name: "Forecast",url: "/home/forecast", },
    { name: "Clients", url: "/home/clients" },
    { name: "Users", url: "/home/users" },
    { name: "Locations", url: "/home/locations" },
    { name: "Profile", url: "/home/profile" },
    { name: "Public Users", url: "/home/public-users" },


  ];
  permissions: any;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private globalService: GlobalService
  ) {
    setTimeout(()=>{
      this.globalService.getPermissions().subscribe((res: any) => {
        this.permissions = JSON.parse(res);
      })
    },500)
    this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Sidebar").subscribe((res) => {
        this.aside = res;
      });
    });

  }

  ngOnInit() { }

  openNav() {
    this.isNavOpen = true;
    let leftside = document.getElementById("left-side");
    leftside.classList.replace('left-side-closed', 'left-side-open');
    let rightside = document.getElementById("right-side");
    rightside.style.width = "calc(100% - 200px)";
  }

  closeNav() {
    this.isNavOpen = false;
    let leftside = document.getElementById("left-side");
    leftside.classList.replace('left-side-open', 'left-side-closed');
    let rightside = document.getElementById("right-side");
    rightside.style.width = "calc(100% - 100px)";
  }

  toggleNav() {
    if (this.isNavOpen) {
      this.closeNav();
    } else {
      this.openNav();
    }
  }

  navigate(path?: string, index?: number) {
    this.activeLink = index;
    if (!this.isNavOpen) {
      this.openNav();
    }
    if (path) {
      if (path === "auth/login") {
        this.globalService.logOut();
        this.toast.success("Logout Successfully!");
      }
      this.router.navigateByUrl(`/${path}`);
    }
  }

  isActive(url: string) {
    return this.router.url === url;
  }

  logout(){
    this.globalService.logOut();
  }
}

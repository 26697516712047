import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[matSelectFilter]',
  standalone: true,
})
export class MatSelectFilterDirective implements OnInit {
  @Input() control: FormControl;
  @Input() items: any[];
  @Input() language: string;
  @Input() section: string;
  @Output() filteredItems = new EventEmitter<any[]>();

  private inputElement: HTMLInputElement;

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.inputElement = this.el.nativeElement.querySelector('input');
    if (this.control) {
      this.control.valueChanges.subscribe(() => {
        this.filterItems();
      });
    }
  }

  @HostListener('keyup')
  onKeyup() {
    this.filterItems();
  }

  private filterItems() {
    const filterValue = this.control.value ? this.control.value.toLowerCase() : '';
    if (filterValue) {
        if(this.section === 'Munc'){
          const filtered = this.items.map(group => {
            const filteredData = group.data.filter(municipality =>
              municipality.nameEn.toLowerCase().includes(filterValue) ||
              municipality.nameAr.toLowerCase().includes(filterValue)
            );
            return { ...group, data: filteredData };
          }).filter(group => group.data.length > 0);
          this.filteredItems.emit(filtered);
        } else if(this.section === 'Gov') {
          const filteredData = this.items.filter(gov =>
            gov.nameEn.toLowerCase().includes(filterValue) ||
            gov.nameAr.toLowerCase().includes(filterValue)
          );
          this.filteredItems.emit(filteredData);
        } else if(this.section === 'Reg') {
          const filteredData = this.items.filter(gov =>
            gov.name_en.toLowerCase().includes(filterValue) ||
            gov.name_ar.toLowerCase().includes(filterValue)
          );
          this.filteredItems.emit(filteredData);
        } else if(this.section === 'UG') {
          const filteredData = this.items.filter(gov =>
            gov.name.toLowerCase().includes(filterValue)
          );
          this.filteredItems.emit(filteredData);
        } else if(this.section === 'ApiGroup') {
          const filteredData = this.items.filter(gov =>
            gov.name.toLowerCase().includes(filterValue) ||
            gov.name_ar.toLowerCase().includes(filterValue)
          );
          this.filteredItems.emit(filteredData);
        } else if(this.section === 'ewsAnalyticsResponse') {
          const filteredData = this.items.filter(gov =>
            gov.regionEn.toLowerCase().includes(filterValue) ||
            gov.regionAr.toLowerCase().includes(filterValue)
          );
          this.filteredItems.emit(filteredData);
        } else if(this.section === 'ThrottlingPolicy') {
          const filteredData = this.items.filter(gov =>
            gov.police_display_name.toLowerCase().includes(filterValue)
          );
          this.filteredItems.emit(filteredData);
        }
    } else {
        this.filteredItems.emit(this.items);
    }
  }
}

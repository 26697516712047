import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/shared/utilities/global';
import { NgxPermissionsService } from 'ngx-permissions';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private baseUrl:string;
  constructor(
    private http: HttpClient,
    private globalService: GlobalService,
    private permission: NgxPermissionsService
    ) {
    this.baseUrl = `${environmentBaseUrl.url}/api/ncm/user/`;
  }

  getUserPermissions(){
    const url = `${this.baseUrl}get-permissions`;
    return this.http.post<{success:boolean;grants:Permissions}>(url, { Authorization : localStorage.getItem('token')} ).toPromise();
  }


  async getPermissions(): Promise<string[]> {
    try {
      let permissions:string[] = this.getNgxPermissions();
      permissions = [];
      if(permissions?.length > 0){
        return permissions;
      }
      const res = await this.getUserPermissions();
      Object.entries(res.grants).forEach(([key, value]) => {
        if (value) {
          permissions.push(key);
        }
      });
      return permissions;
    } catch (error) {
      this.globalService.handleError(error);
      throw error;
    }
  }
  

   getNgxPermissions():string[]{
    const userPermissions = [];
    const permissions = this.permission.getPermissions();
    for (const key in permissions) {
      if (key.trim() !== '') {
        userPermissions.push(key.trim());
      }
    }
    return userPermissions;
  }


  getPermissionsAndLoadPermissions(){
    this.getUserPermissions()
    .then((res:{success:boolean;grants:Permissions})=>{
      const permissions = [];
      Object.entries(res.grants).forEach(([key, value])=>{
        if(value){
          permissions.push(key)
        }
      })
      this.permission.loadPermissions(permissions);
      return this.permission.getPermissions();
    })
    .catch((err)=>{
      this.globalService.handleError(err, false);
      return false;
    })
  }

}

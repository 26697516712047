import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { CreatePackageModel, CreateWSOModel, ListApiGroup, ListPackages, UpdatePackageModel, UpdateWSOModel } from 'src/app/models/package/package.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { PackageService } from 'src/app/services/package/package.service';
import { GlobalService } from 'src/app/shared/utilities/global';

export interface PackageFormGroup {
  id: FormControl<number | null>,
  name: FormControl<string | undefined>;
  tenure: FormControl<number | undefined>;
  api_groups: FormControl<number[] | undefined>;
  price: FormControl<number | undefined>;
  max_locations: FormControl<number | undefined>;
  max_users: FormControl<number | undefined>;
}

export interface APIFormGroup {
  id: FormControl<number | null>,
  name: FormControl<string | undefined>;
  price: FormControl<number | undefined>;
  tenure: FormControl<number | undefined>;
  throttling: FormControl<number | undefined>;
}

export interface ThrottlingData {
  id: number
  police_name: string
  police_display_name: string
  description: string
}

@Component({
  selector: 'app-upsert-packages',
  templateUrl: './upsert-packages.component.html',
  styleUrls: ['./upsert-packages.component.scss']
})
export class UpsertPackagesComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() apiGroups: ListApiGroup[] = [];
  @Input() throttlingData: ThrottlingData[];
  @Output() closeWindow: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() getPackagesList: EventEmitter<null> = new EventEmitter();
  @Output() getAPIList: EventEmitter<null> = new EventEmitter();
  
  private subscriptions: Subscription = new Subscription();
  editPackage: ListPackages;
  activeNav:string;
  editAPI: ListApiGroup;
  language: string;
  packagesPage: any;
  filterAPIgroups = new FormControl<string>('',);
  filterThrottling = new FormControl<string>('',);
  filterapiGroups: ListApiGroup[];
  allAPIgIds: number[];
  navItems: string[] = ['Selection', 'Packages', 'APIgroups'];
  filterthrottlingData: ThrottlingData[];

  disablePackages: boolean = false;
  disableAPIgroups: boolean = false;

  packageFormGroup = new FormGroup<PackageFormGroup>({
    id: new FormControl(null),
    name: new FormControl('', { validators: [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)] }),
    price: new FormControl(null, { validators: [Validators.required] }),
    max_locations: new FormControl(null, { validators: [Validators.required] }),
    max_users: new FormControl(null, { validators: [Validators.required] }),
    tenure: new FormControl(null, { validators: [Validators.required] }),
    api_groups: new FormControl([], { validators: [Validators.required] }),
  });
  APIFormGroup = new FormGroup<APIFormGroup>({
    id: new FormControl(null),
    name: new FormControl('', { validators: [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)] }),
    price: new FormControl(null, { validators: [Validators.required] }),
    tenure: new FormControl(null, { validators: [Validators.required] }),
    throttling: new FormControl(null, { validators: [Validators.required] }),
  });

  constructor(
    private packageService: PackageService,
    private globalService: GlobalService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.getCurrentLanguage()
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.allAPIgIds = []
      this.apiGroups = this.apiGroups.slice(1)
      this.filterapiGroups = this.apiGroups
      this.allAPIgIds = this.apiGroups.map(group => group.id);
      this.filterthrottlingData = this.throttlingData

      if(this.activeNav === null){
        this.activeNav = 'Selection';
      }
      if(this.editPackage?.id){
        this.setEditPackageForm()
      } else if(this.editAPI?.id){
        this.setEditAPIForm()
      }
    }, 100);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['editPackage']?.currentValue?.['id']) {
      this.setEditPackageForm()
    } else if (changes['editAPI']?.currentValue?.['id']) {
      this.filterthrottlingData = this.throttlingData
      this.setEditAPIForm()
    }
  }

  getCurrentLanguage(){
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Packages").subscribe((res) => {
        this.packagesPage = res;
      });
    }));
  }

  onNavChange(nav:string){
    this.packageFormGroup.reset();
    this.APIFormGroup.reset();
    this.activeNav = nav;
  }

  onSubmit() {
    if (this.packageFormGroup.invalid) {
      this.showValidationsMessages();
      return;
    }

    if (this.packageFormGroup.controls.id.value) {
      this.updatePackage();
    }
    else {
      this.createPackage();
    }
  }

  onSubmitAPI() {
    if (this.APIFormGroup.invalid) {
      this.showValidationsMessages();
      return;
    }

    if (this.APIFormGroup.controls.id.value) {
      this.updateWSOpackage();
    }
    else {
      this.createWSOpackage();
    }
  }

  showValidationsMessages(): boolean {
    const requiredFieldsMessage = this.packagesPage;

    for (const [controlName, value] of Object.entries(this.packageFormGroup.controls)) {
      const formControl = this.packageFormGroup.get(controlName);

      if (formControl?.errors?.required) {
        this.toast.error(requiredFieldsMessage[controlName]);
        return false;
      }
    }
    if (this.packageFormGroup.controls?.name.errors?.pattern) {
      this.toast.error(this.packagesPage?.nameContainsNumberSpecialChars);
      return false;
    }
  }

  setEditPackageForm() {
    if (!this.editPackage.id) {
      return;
    }
    Object.entries(this.editPackage).forEach(([key, value]) => {
      const control = this.packageFormGroup.controls[key];
      if (control) {
        if (typeof value !== 'object') {
          control.setValue(value);
        }
        else {
          const apiGroupIds = [];
          for (let i = 0; i < value?.length; i++) {
            apiGroupIds.push(value[i].id);
          }
          control.setValue(apiGroupIds);
        }
      }
    })
  }

  setEditAPIForm(){
    if (!this.editAPI.id) {
      return;
    }
    Object.entries(this.editAPI).forEach(([key, value]) => {
      const control = this.APIFormGroup.controls[key];
      if (control) {
        control.setValue(value);
      }
    })
  }

  createPackage() {
    const createPackageModel: CreatePackageModel = this.packageFormGroup.getRawValue();
    this.packageService.createPackage(createPackageModel)
      .then((res: { success: boolean, message: string }) => {
        if (res?.success) {
          this.toast.success(res.message);
          this.getPackagesList.emit()
          this.closeWindow.emit();
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
  }

  updatePackage() {
    const updatePackageModel: UpdatePackageModel = this.packageFormGroup.getRawValue();
    this.packageService.editPackage(updatePackageModel)
      .then((res: { success: boolean, message: string }) => {
        if (res?.success) {
          this.toast.success(res.message);
          this.getPackagesList.emit()
          this.closeWindow.emit();
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
  }

  createWSOpackage() {
    const createWSOModel: CreateWSOModel = this.APIFormGroup.getRawValue();
    this.packageService.createWSOPackage(createWSOModel)
      .then((res: { success: boolean, message: string }) => {
        if (res?.success) {
          this.toast.success(res.message);
          this.getAPIList.emit()
          this.closeWindow.emit();
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
  }

  updateWSOpackage() {
    const updateWSOModel: UpdateWSOModel = this.APIFormGroup.getRawValue();
    this.packageService.editWSOPackage(updateWSOModel)
      .then((res: { success: boolean, message: string }) => {
        if (res?.success) {
          this.toast.success(res.message);
          this.getAPIList.emit()
          this.closeWindow.emit();
        }
        else {
          this.toast.error(res.message);
        }
      })
      .catch((err) => {
        this.globalService.handleError(err);
      })
  }

  preventAlphabets(event: KeyboardEvent): void {
    const charCode = event.charCode;

    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  validateInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement;
    const value = Number(inputElement.value);
  
    if (value > 100) {
      inputElement.value = '100';
      if(this.activeNav === 'Packages'){
        this.packageFormGroup.controls.tenure.setValue(100);
      } else {
        this.APIFormGroup.controls.tenure.setValue(100);
      }
      this.toast.error(this.packagesPage?.maxValueIs100);
    }
  }

  updateFilteredItems(filtered: any[], section: string) {
    if (section === 'ApiGroup') {
      this.filterapiGroups = filtered;
    } else if (section === 'ThrottlingPolicy') {
      this.filterthrottlingData = filtered;
    }
  }
}

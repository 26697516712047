import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { CreateUserGroupModelAPI, ListUserGroupModelAPI } from 'src/app/models/user-groups/user-groups.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';
import { GlobalService } from '../utilities/global';

@Component({
  selector: 'app-email-groups',
  templateUrl: './email-groups.component.html',
  styleUrls: ['./email-groups.component.scss']
})
export class EmailGroupsComponent implements OnInit {
  private subscriptions: Subscription = new Subscription()
  language:string;
  automaticdetailPage: any;
  
  currentPageForUserGroups: number = 1;
  listUserGroups: CreateUserGroupModelAPI[];
  ListPagination: PaginationVariables;
  
  selectedEmailGroups: number[] = [];

  constructor(
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private modalService: NgbModal,
    private modal: NgbActiveModal,
    private userGroupService:UserGroupsService,
  ) { }

  ngOnInit() {
    this.getCurrentLanguage()
    this.getUserGroupsForDropdown()
  }

  getCurrentLanguage() {
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("AutomaticWeatherReport").subscribe((res) => {
        this.automaticdetailPage = res;
      });
    }));
  }

  getUserGroupsForDropdown(clienttype?:any, page?:any){
    const listUserGroupModelAPI: ListUserGroupModelAPI = new ListUserGroupModelAPI();
    if(page){
      listUserGroupModelAPI.page = page;
    }
    else{
      if(this.ListPagination?.pageCount > (this.currentPageForUserGroups + 1 )){
        this.currentPageForUserGroups++;
        listUserGroupModelAPI.page = this.currentPageForUserGroups;
      }
      else if(this.currentPageForUserGroups == 1){
        listUserGroupModelAPI.page = this.currentPageForUserGroups;
      }
      else{
        return;
      }
    }
    listUserGroupModelAPI.type = 'report';
    this.spinner.show();
    this.userGroupService.listUserGroup(listUserGroupModelAPI).then(
      (res:{success:boolean,data:CreateUserGroupModelAPI[],paginationVariables:PaginationVariables}) => {
        if(res.success){
          if(this.listUserGroups?.length > 0){
            this.listUserGroups = [ ...this.listUserGroups, ...res.data];
          }
          else{
            this.listUserGroups = res.data;
          }
          this.ListPagination = res.paginationVariables;
        }
      }
    ).catch((err) => {
      this.globalService.handleError(err);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }

  modalSave(){
    const selectedEmailGroups = this.selectedEmailGroups
    this.modal.close({selectedEmailGroups});
  }
  modalClose(){
    const selectedEmailGroups = []
    this.modal.close({selectedEmailGroups});
  }
}

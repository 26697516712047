import { NgxSpinnerService } from 'ngx-spinner';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { EWSNotificationModel, ListEwsNotificationModel } from 'src/app/models/EWSnotification/EWSnotification.model';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';
import { ToastrService } from 'ngx-toastr';
import "leaflet";
import { Subject, Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import html2canvas from 'html2canvas';
declare var L: any;


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./x-preview.component.scss']
})
export class PreviewComponent implements OnInit, AfterViewInit {
  @Output() xpostImgSubject = new EventEmitter<string>();
  runSave: boolean = false;
  
  @ViewChild ('content', { static: false }) content: ElementRef
  @Input() navigationExtras:any;
  private subscriptions: Subscription = new Subscription()
  language: string;
  notificationList: ListEwsNotificationModel;
  regionLayers: any;
  previewPage: any;
  isUpdate: boolean;
  isAlreadyPublished: boolean = false;
  filetype: string;
  typeofFile: string;
  base64: string;
  today: Date;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private route: ActivatedRoute
  ) {
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Dashboard").subscribe((res) => {
        this.previewPage = res;
      });
    }));
    this.today = new Date
  }

  ngOnInit(){
    this.getDataFromParams();
  }
    
  ngAfterViewInit(): void {
    setTimeout(() => {
      if(this.runSave){
        this.captureScreen().then(xpostIMG => {
          this.xpostImgSubject.emit(xpostIMG);
        });
      }
    }, 1000);
  }

  captureScreen(): Promise<string> {
    return new Promise((resolve, reject) => {
      const element = document.querySelector("#x-content-post");
      if (element) {
        html2canvas(element as HTMLElement, { logging: true, useCORS: true }).then(canvas => {
          const xpostIMG = canvas.toDataURL('image/png');
          resolve(xpostIMG); // Resolve the promise with xpostIMG
        }).catch(error => {
          console.error('Error capturing screen:', error);
          reject(error); // Reject the promise with the error
        });
      } else {
        console.error('Element not found!');
        reject('Element not found!'); // Reject the promise if element is not found
      }
    });
  }

  getBackgroundImageUrl(): string {
    if (this.notificationList && this.notificationList.alertTypeEn) {
      return `url(/assets/x-preview/${this.notificationList.alertTypeEn.replace(/\s/g, '').toLowerCase()}.webp)`;
    }
    return '';
  }

  fetchimage(alertStatus: string): string {
    if (alertStatus === 'High winds') {
      return 'Wind';
    } else if (alertStatus === 'Heavy rainfall') {
      return 'Thunder Rainy';
    } else if (alertStatus === 'Thunder') {
      return 'Thunder Clouds';
    } else if (alertStatus === 'Severe snowstorms') {
      return 'Snow';
    } else if (alertStatus === 'Waves rising') {
      return 'Waves Rising';
    } else if (alertStatus === 'Heat waves') {
      return 'Sunny';
    } else if (alertStatus === 'Cold waves') {
      return 'Cold';
    } else if (alertStatus === 'Raised dust' || alertStatus === 'Dusty plankton' || alertStatus === 'Raised dust / Dust storms' || alertStatus === 'Dust storms') {
      return 'Sand Storm';
    } else if (alertStatus === 'Light rain' || alertStatus === 'Moderate Rainfall') {
      return 'Rain Clouds';
    } else if (alertStatus === 'Light snowfall' || alertStatus === 'Moderate snowfall') {
      return 'Rain and Snow';
    } else if (alertStatus === 'Light mist' || alertStatus === 'Fog' || alertStatus === 'Thick fog') {
      return 'Fog';
    }
    return 'Rain and Snow';
  }  

  getDataFromParams() {
    if (this.navigationExtras?.queryParams?.list) {
      this.isUpdate = JSON.parse(this.navigationExtras?.queryParams?.isUpdate);
      this.isAlreadyPublished = JSON.parse(this.navigationExtras?.queryParams?.isAlreadyPublished);
      this.notificationList = JSON.parse(this.navigationExtras?.queryParams?.list);
      if(this.notificationList.filename){
        this.getFileExtension(this.notificationList.filename)
      }
    }
  }

  getFileExtension(fileName: string) {
    const parts = fileName.split('.');
    this.filetype = parts.length > 1 ? parts.pop().toLowerCase() : '';
    
    if(this.filetype === 'pdf'){
      this.typeofFile = 'application'
    }
    else{
      this.typeofFile = 'image'
    }

    this.base64 = localStorage.getItem('tempFile')
  }

  formatTimeInto12HourFormat(dateStr: string) {
    let [strHour, minutesAndPeriod] = dateStr.split(":");
    let period: string;
    let hour = parseInt(strHour);
    if (hour > 12) {
      hour = hour - 12;
      period = 'PM';
    }
    else {
      period = 'AM';
    }

    if (hour == 0) {
      hour = 12;
    }

    return `${hour}:${minutesAndPeriod} ${period}`;
  }


  // onSave(published:boolean){
  // }

  onSave(published: boolean) {
    this.spinner.show();
    // const ewsNotificationModel:EWSNotificationModel = this.getEwsNotificationModelData();
    const ewsNotificationModel: EWSNotificationModel = new EWSNotificationModel();
    ewsNotificationModel.id = this.notificationList?.id;
    ewsNotificationModel.regionId = `${this.notificationList?.regionID}`;
    const alertActionIds = this.notificationList?.alertActions.map((action) => action.id);
    const alertHazardIds = this.notificationList?.alertHazard.map((hazard) => hazard.id);
    ewsNotificationModel.alertActionId = alertActionIds.toString();
    ewsNotificationModel.alertHazardId = alertHazardIds.toString();
    ewsNotificationModel.alertTypeId = `${this.notificationList?.alertType}`;
    ewsNotificationModel.weatherPhenomenonId = this.notificationList?.alertStatusID;
    ewsNotificationModel.startDate = this.notificationList?.startDate
    ewsNotificationModel.endDate = this.notificationList?.endDate;
    ewsNotificationModel.startTime = this.notificationList?.startTime;
    ewsNotificationModel.endTime = this.notificationList?.endTime;
    ewsNotificationModel.message = {
      en: this.notificationList?.message?.messageEn,
      ar: ""
    };
    ewsNotificationModel.governateIds = this.notificationList?.governateIds;
    ewsNotificationModel.municipalityIds = this.notificationList?.municipalityIds;
    ewsNotificationModel.ewsOtherLocationIds = this.notificationList?.ewsOtherLocationIds;
    ewsNotificationModel.enableEmailNotification = this.notificationList?.enableEmailNotification || false;
    ewsNotificationModel.enableSMSNotification = this.notificationList?.enableSMSNotification || false;
    ewsNotificationModel.enableTwitterNotification = this.notificationList?.enableTwitterNotification || false;

    if(this.base64 && this.notificationList.filename){
      ewsNotificationModel.file = this.base64
      ewsNotificationModel.filename = this.notificationList.filename
    }

    if (this.isUpdate) {
      this.updateEwsNotification(ewsNotificationModel, published);
    }
    else {
      this.createEwsNotification(ewsNotificationModel, published);
    }
  }


  onEditNotificationList() {
    this.notificationList.fromDate = this.getFullDateAndTimeString(this.notificationList.startDate, this.notificationList.startTime);
    this.notificationList.toDate = this.getFullDateAndTimeString(this.notificationList.endDate, this.notificationList.endTime);
    this.notificationList.file = this.notificationList.filename;
    const navigationExtras: NavigationExtras = {
      queryParams: {
        list: JSON.stringify(this.notificationList),
        isUpdate: this.isUpdate,
        isAlreadyPublished: this.isAlreadyPublished
      }
    }
    this.router.navigate(['home/alert-editor/add-region'], navigationExtras)
  }


  getFullDateAndTimeString(dateStr: string, time: string) {
    const [month, day, year] = dateStr.split("-");
    const date = `${year}-${month}-${day}`;
    return `${date} ${time}`;
  }

  convertTimeTo24HourFormat(timeString: string) {
    const [time, period] = timeString?.split(" ");
    const [hours, minutes] = time?.split(":");

    let convertedHours = parseInt(hours, 10);

    if (period.toLowerCase() === 'pm' && convertedHours !== 12) {
      convertedHours += 12;
    } else if (period.toLowerCase() === 'am' && convertedHours === 12) {
      convertedHours = 0;
    }

    return `${String(convertedHours).padStart(2, '0')}:${minutes}:00`;
  }

  publishEwsNotification(publish: { id: number, published: boolean }) {
    this.alertService.publishEwsNotification(publish)
      .then((res: { success: boolean; data: any }) => {
        if (res.success) {
          this.router.navigateByUrl('home/alert-history');
        }
      })
      .catch((error) => {
        this.spinner.hide();
        if (error.status === 401) {
          this.toast.error('Session Expired');
          this.router.navigateByUrl('auth/login');
        }
        console.log("error", error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  goToDraft() {
    this.router.navigateByUrl('home/alert-editor/draft');
  }


  createEwsNotification(ewsNotificationModel: EWSNotificationModel, publish: boolean) {
    this.alertService.createEwsNotification(ewsNotificationModel)
      .then((res: { success: boolean, message: string, notification_id: number }) => {
        if (res?.success) {
          this.toast.success(res?.message);
          if (publish) {
            let published: { id: number, published: boolean } = {
              id: res.notification_id,
              published: true
            }
            this.publishEwsNotification(published);
          }
          else {
            this.router.navigateByUrl('/home/alert-editor/draft')
          }
        }
        else {
          this.toast.error(res?.message);
        }
      })
      .catch((error) => {
        this.spinner.hide();
        if (error.status === 401) {
          this.toast.error('Session Expired');
          this.router.navigateByUrl('auth/login');
        }
        console.log("error", error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }


  updateEwsNotification(ewsNotificationModel: EWSNotificationModel, publish: boolean) {
    this.alertService.updateEwsNotification(ewsNotificationModel)
      .then((res: { success: boolean, message: string, notification_id: number }) => {
        if (res?.success) {
          this.toast.success(res?.message);
          if (res?.notification_id) {
            if (publish) {
              let published: { id: number, published: boolean } = {
                id: res.notification_id,
                published: true
              }
              this.publishEwsNotification(published);
            }
          }
        }
        else {
          this.toast.error(res?.message);
        }
      })
      .catch((error) => {
        this.spinner.hide();
        if (error.status === 401) {
          this.toast.error('Session Expired');
          this.router.navigateByUrl('auth/login');
        }
        console.log("error", error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  createBlobAndOpenFile() {
    const byteCharacters = atob(this.base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: `${this.typeofFile}/${this.filetype}` });

    const blobUrl = URL.createObjectURL(blob);
    window.open(blobUrl, '_blank');
  }
}

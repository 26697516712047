import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorConversion'
})
export class ColorConversionPipe implements PipeTransform {

  transform(colorName: string): string {
    switch (colorName) {
      case 'Orange':
        return '#FFA5002b';
      case 'Red':
        return '#FF00002b';
      case 'Yellow':
        return '#FFFF002b';
      // Add more color mappings as needed
      default:
        return colorName; // Return the input color if no match found
    }
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ChangePasswordModel } from "src/app/models/change-password/change-password.model";
import { LoginModel, OtpModel } from "src/app/models/login/login.model";
import { SignupModel } from "src/app/models/signup/signup.model";
import { environmentBaseUrl } from "src/environments/environmentBaseUrl";
@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  baseUrl: string;
  token = "";
  user = {};
  constructor(private http: HttpClient) {
    this.baseUrl = `${environmentBaseUrl.url}/api/ncm/user/`;
  }

  register(user: SignupModel, token?:string) {
    let url = '';
    if(!token){
       url = `${this.baseUrl}register`;
    }
    else{
     url = `${this.baseUrl}register-user?token=${token}`;
    }
    return this.http.post(url, user);
  }

  login(user: LoginModel) {
    const url = `${environmentBaseUrl.url}/api/login_check`;
    return this.http.post(url, user).toPromise();
  }
  otpCheck(user: OtpModel) {
    const url = `${this.baseUrl}otp-verify`;
    return this.http.post(url, user).toPromise();
  }
  getUserDetails() {
    const url = `${this.baseUrl}get-profile`;
    return this.http.post(url, {});
  }

  getUserPermissions(){
    const url = `${this.baseUrl}get-permissions`;
    return this.http.post(url, { Authorization : localStorage.getItem('token')} ).toPromise();
  }

  tokenValidate(token: string) {
    const url = `${this.baseUrl}token-validate`;
    return this.http.post(url,{token:token}).toPromise();
  }

  forgotPassword(email:string){
    const url = `${this.baseUrl}forgot-password`;
    return this.http.post(url, {email}).toPromise();
  }

  resetPassword(resetPasswordModel: ChangePasswordModel, token:string){
    const url = `${this.baseUrl}reset-password`
    return this.http.post(url, {...resetPasswordModel, token}).toPromise();
  }

  getMomraMapToken(){
    const url = `${environmentBaseUrl.url}/api/ncm/admin/momra-generate-token`;
    return this.http.post(url, {}).toPromise();
  }
}

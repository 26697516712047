export class CreateUserGroupModelAPI {
    id?: number
    name: string
    type: string
    data: CreateUserGroupModelDataAPI[]
}

export interface CreateUserGroupModelDataAPI {
    firstName: string
    lastName: string
    email: string
}

export class ListUserGroupModelAPI {
    search?: string
    type?: string
    page: number
}


import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpParams,
} from "@angular/common/http";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, Subject, Subscription, of } from "rxjs";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgxPermissionsService } from "ngx-permissions";
import { LanguageService } from "src/app/services/language/language.service";
import { TranslateService } from "@ngx-translate/core";
// import axios from "axios";
export interface UserInfo {
  id: number
  name: string
  email: string
  department: string
  title: string
  role: string
  organization: string
  organizationId: number
}

@Injectable()
export class GlobalService {
  private token: string;
  private user: UserInfo;
  private subscriptions: Subscription = new Subscription();
  language: string;
  
  userSource:Subject<UserInfo> = new BehaviorSubject(JSON.parse(localStorage.getItem("user")));
  userInfo$ = this.userSource.asObservable();
  constructor(
    private http: HttpClient,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private modalService: NgbModal,
    private languageService: LanguageService,
    private translate: TranslateService,
    private permissionsService: NgxPermissionsService
  ) {
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
    }));
  }

  clearToken(){
    localStorage.removeItem('token');
  }

  clearUser() {
    localStorage.removeItem('user')
  }

  clearLastLoginTime(){
    localStorage.removeItem('lastLoginTime');
  }

  logout(){
    this.modalService.dismissAll();
    this.clearToken();
    this.clearUser();
    this.clearLastLoginTime();
    this.permissionsService.flushPermissions();
    this.router.navigateByUrl('auth/login');
  }

  setUser(user: any) {
    localStorage.setItem("user", JSON.parse(user));
    this.user = user
  }

  getUser() {
    this.user = JSON.parse(localStorage.getItem("user"))
    return this.user;
  }

  getUserRole() {
    this.user = JSON.parse(localStorage.getItem("user"))
    return this.user?.role;
  }

  setUserPassword(password:string){
    localStorage.setItem('password', password)
  }

  getUserPassword(){
    return localStorage.getItem('password');
  }

  clearUserPassword(){
    localStorage.removeItem('password');
  }

  setToken(token: string) {
    this.token = token;
    localStorage.setItem("token", token);
  }

  getToken() {
    return localStorage.getItem("token") || this.token;
  }

  clearPermissions() {
    localStorage.removeItem('permissions');
  }
  
  setPermissions(permissions: any) {
    localStorage.setItem('permissions', JSON.stringify(permissions))
  }
  
  getPermissions() {
    return of(localStorage.getItem('permissions'));
  }

  get isLoggedIn(): boolean {
    return !!this.getToken();
  }

  PostApi(url, data, islogin) {
    const headers = this.getHeaders(islogin);
    return this.http
      .post(url, data, {
        headers: headers,
      })
      .toPromise()
      .catch((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.code === 401) {
            this.router.navigateByUrl("auth/login");
          }
          this.hideLoader();
        }
      });
  }

  GetApi(url, islogin) {
    const headers = this.getHeaders(islogin);
    return this.http
      .get(url, {
        headers: headers,
      })
      .toPromise()
      .catch((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.code === 401) {
            this.router.navigateByUrl("auth/login");
          }
          return Observable.throw(err);
        }
      });
  }

  PostmannedApi(url, data, Authorization) {
    const headers = this.getHeaders(Authorization);
    return this.http
      .post(url, data, {
        headers: headers,
      })
      .toPromise()
      .catch((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.code === 401) {
            this.router.navigateByUrl("auth/login");
          }
          this.hideLoader();
        }
      });
  }

  GetWeatherApi(url) {
    const headers = this.getHeadersWeather();
    return this.http
      .get(url, {
        headers,
      })
      .toPromise()
      .catch((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.error.code === 401) {
            this.router.navigateByUrl("auth/login");
          }
          return new Error(err.message);
        }
      });
  }

  async putApi(url, params, headerson?) {
    const headerObj = {
      "Content-Type": "application/json; charset=utf-8",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: "",
    };
    if (this.token !== "") {
      headerObj.Authorization = "Bearer " + this.token;
    }
    const obj = Object.assign({}, headerObj);
    const httpHeaders = new HttpHeaders(obj);
    const options = { headers: httpHeaders };

    let result: any;

    await this.http
      .post(environment.baseUrl + url, params, options)
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        result = await resp;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async patchApi(url, params, headerson?) {
    const headerObj = {
      "Content-Type": "application/json",
      Authorization: "",
    };
    if (this.token !== "") {
      headerObj.Authorization = "Bearer " + this.token;
    }
    const obj = Object.assign({}, headerObj);
    const httpHeaders = new HttpHeaders(obj);
    const options = { headers: httpHeaders };
    const result = this.http
      .patch(environment.baseUrl + url, params, options)
      .toPromise();
    return result;
  }

  async postAttachmentApi(url, formData, headerson?) {
    const headerObj = {
      enctype: "multipart/form-data;",
      Accept: "plain/text",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS, PUT, PATCH, DELETE",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Headers":
        "Origin, X-Requested-With, Content-Type, Accept",
      Authorization: "",
    };
    if (this.token !== "") {
      headerObj.Authorization = "Bearer " + this.token;
    }
    const obj = Object.assign({}, headerObj);
    const httpHeaders = new HttpHeaders(obj);
    const options = { headers: httpHeaders };
    let result: any;

    await this.http
      .post(environment.baseUrl + url, formData, options)
      .toPromise()
      .then(async (resp: any) => {
        console.log(resp);
        result = await resp;
      })
      .catch((error) => {
        console.log(error);
      });

    return result;
  }

  async deleteApi(url, params, headerson?) {
    const headerObj = {
      "Content-Type": "application/json; charset=utf-8",
      Authorization: "",
    };
    if (this.token !== "") {
      headerObj.Authorization = "Bearer " + this.token;
    }
    const obj = Object.assign({}, headerObj);
    const httpHeaders = new HttpHeaders(obj);
    const options = { headers: httpHeaders };

    const result = this.http
      .delete(environment.baseUrl + url, options)
      .toPromise();
    return result;
  }

  getHeaders(islogin: boolean) {
    let headers = {
      "Content-Type": "text/plain",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "POST",
      // "Access-Control-Allow-Headers": "Content-Type",
      // "Content-Type": "application/json",
      // "Access-Control-Allow-Origin": "*",
      // "Access-Control-Allow-Methods": "GET, POST",
      // "Access-Control-Allow-Credentials": "true",
      // "Access-Control-Allow-Headers":
      //   "Origin, X-Requested-With, Content-Type, Accept",
      // Accept: "*/*",
      // Allow: "*",
    };
    if (islogin == false) {
      // headers["Authorization"] = "Bearer " + this.getToken();
      // headers["abcd"] = "this.getToken()";
    }
    return new HttpHeaders(headers);
  }

  getHeadersWeather() {
    let headers = {
      Authorization: "Basic " + btoa("ncm_mis_centric_dev:0yrSFxqiiJ0k"),
    };
    return new HttpHeaders(headers);
  }

  removeToken() {
    localStorage.removeItem("token");
  }

  handleError(error:HttpErrorResponse, showMessage:boolean = true){
    if (error.status === 401) {
      if(showMessage){
        this.toast.error(this.language == 'en' ? "Session Expired" : "انتهت الجلسة");
      }
      this.logout();
    }
    else if (error['code'] === 401) {
      if(showMessage){
        this.toast.error(this.language == 'en' ? "Session Expired" : "انتهت الجلسة");
      }
      this.logout();
    }
  }
  logOut() {
    this.modalService.dismissAll();
    this.removeToken();
    this.clearUser();
    this.clearUserPassword();
    this.permissionsService.flushPermissions();
    this.router.navigateByUrl('auth/login');
  }
  showLoader() {
    this.spinner.show();
  }
  hideLoader() {
    this.spinner.hide();
  }
}

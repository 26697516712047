import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutomaticWeatherReportComponent } from './automatic-weather-report.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AutomaticWeatherReportRoutes } from './automatic-weather-report.routing.module';
import { NgbNavModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AutomaticPreviewComponent } from './preview/automaticpreview.component';
import { AutomaticReportComponent } from './reportpage/automaticreportpage.component';
import { NgxPermissionsModule } from 'ngx-permissions';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { ListWarningsComponent } from './list-warnings/list-warnings.component';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    AutomaticWeatherReportComponent,
    AutomaticPreviewComponent,
    AutomaticReportComponent,
    PreviewModalComponent,
    ListWarningsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    AutomaticWeatherReportRoutes,
    NgbPaginationModule,
    NgbTooltipModule,
    NgxPermissionsModule.forChild(),
    MatSelectInfiniteScrollModule,
    NgbNavModule,
    NgbDatepickerModule
  ]
})
export class AutomaticWeatherReportModule { }

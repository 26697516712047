import { ClientModel } from './../../models/client/client.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';
import { interval } from 'rxjs/internal/observable/interval';
import { Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  private baseUrl: string;

    constructor(private http: HttpClient) {
      this.baseUrl = `${environmentBaseUrl.url}/api/ncm/`;
    }

    createClient(clientModel:ClientModel){
      const url = `${this.baseUrl}admin/invite-user`
      return this.http.post(url, clientModel).toPromise();
    }
    editClient(clientModel:ClientModel){
      const url = `${this.baseUrl}user/edit-user`
      return this.http.post(url, clientModel).toPromise();
    }
    inviteUser(user): Observable<string> {
      // Simulate API call with delay
      return of('Success').pipe(delay(1000));
    }
    createBulkClient(clientModel:ClientModel): Observable<any> {
      const url = `${this.baseUrl}admin/invite-user`
      return this.http.post(url, clientModel);
    }
    createManualClient(manualClientModel:ClientModel){
      const url = `${this.baseUrl}admin/create-manual-user`
      return this.http.post(url, manualClientModel).toPromise();
    }

}

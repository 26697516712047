import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddClientComponent } from './add-client.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { AddClientRoutes } from './add-client.routing.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AddClientComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AddClientRoutes,
    FormsModule,
    ReactiveFormsModule,
    NgxPermissionsModule.forChild()
  ]
})
export class AddClientModule { }

import { Routes, RouterModule } from '@angular/router';
import { DraftComponent } from './draft/draft.component';
import { PreviewComponent } from './preview/preview.component';
import { RegionComponent } from './region/region.component';
import { UnsavedChangesGuard } from 'src/app/guards/unsaved-changes/unsaved-changes.guard';

const routes: Routes = [
  {
    path: 'add-region',
    component: RegionComponent,
    canDeactivate: [UnsavedChangesGuard]
  },
  {
    path: 'preview',
    component: PreviewComponent,
  },
  {
    path: 'draft',
    component: DraftComponent,
  },
];

export const AlertEditorRoutes = RouterModule.forChild(routes);

<!-- <div class="page-titlealert">
  <i class="fas fa-caret-right"></i>
  <p class="active">{{ automaticdetailPage?.AutomaticWeatherReports }}</p>
</div> -->
<!-- <div class="locationBG justify-content-between">
  <div class="addLocHeading">
    <img class="headerIcon" src="../../../assets/automaticweatherreport/automaticweatherreport-icon.png" />
    <h3>{{ automaticdetailPage?.AutomaticWeatherReports }}</h3>
  </div>
</div> -->
<p-toolbar class="d-flex flex-wrap dome-a" style="margin: inherit;">
  <div class="p-toolbar-group-left col justify-content-between">
    <div class="d-flex gap-2 align-items-center">
      <img src="../../../assets/automaticweatherreport/automaticweatherreport-icon.png" style="scale: 0.7;">
      <div class="alert-history">{{ automaticdetailPage?.AutomaticWeatherReports }}</div>
    </div>
  </div>
</p-toolbar>
<div class="previewTablediv my-30">
  <div class="addLocHeading brdr-bottom w-100 py-3">
    <h3>{{ language == 'en' ? descriptionEn : descriptionAr }}</h3>
  </div>
  <ng-container *ngIf="reporttypekey === 'advance-custom-weather-report'">
    <div class="d-flex py-3">
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio1" [checked]="!isMannedReport" [value]="isMannedReport" (click)="onAdvanceClick($event)">
        <label [ngClass]="language === 'en' ? 'ps-3' : 'pe-3'" class="form-check-label" style="font-size: 16px; color: #005bb5;" for="inlineRadio1">{{ automaticdetailPage?.MannedReports }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input class="form-check-input" type="radio" name="inlineRadioOptions1" id="inlineRadio2" [checked]="!isAdvanceCustomReport" [value]="isAdvanceCustomReport" (click)="onMannedClick($event)">
        <label [ngClass]="language === 'en' ? 'ps-3' : 'pe-3'" class="form-check-label" style="font-size: 16px; color: #005bb5;" for="inlineRadio2">{{ automaticdetailPage?.AdvanceCustomReport }}</label>
      </div>
    </div>
  </ng-container>
  <div [ngClass]="{'d-none': reporttypekey === 'red-sea-report' || reporttypekey === 'arabian-gulf-report'}" class="addLocHeading brdr-bottom-grey d-flex flex-wrap w-100 py-4 mx-2">
    <ng-container *ngIf="reporttypekey == 'advance-custom-weather-report'">
      <form [formGroup]="advanceTitleForm" class="w-100">
        <div class="w-100 pb-4 brdr-bottom-grey">
          <div class="d-flex">
            <h5 class="titletext w-50" [ngClass]="language === 'en' ? 'me-3' : 'ms-3'">{{ automaticdetailPage?.ReportTitle }} (English)</h5>
            <h5 class="titletext w-50">{{ automaticdetailPage?.ReportTitle }} (عربي)</h5>
          </div>
          <div class="d-flex">
            <input formControlName="titleEn" class="titleinput w-50 px-3" [ngClass]="language === 'en' ? 'me-3' : 'ms-3'" [placeholder]="automaticdetailPage?.EnterReportTitle" >
            <input formControlName="titleAr" class="titleinput w-50 px-3" [placeholder]="automaticdetailPage?.EnterReportTitle" >
          </div>
        </div>
        <div class="w-100 pt-4">
          <div class="d-flex">
            <h5 class="titletext w-50" [ngClass]="language === 'en' ? 'me-3' : 'ms-3'">{{ automaticdetailPage?.ReportDetail }} (English)</h5>
            <h5 class="titletext w-50">{{ automaticdetailPage?.ReportDetail }} (عربي)</h5>
          </div>
          <div class="d-flex">
            <input formControlName="descriptionEn" class="titledesc w-50 px-3" [ngClass]="language === 'en' ? 'me-3' : 'ms-3'" [placeholder]="automaticdetailPage?.EnterReportDetail" >
            <input formControlName="descriptionAr" class="titledesc w-50 px-3" [placeholder]="automaticdetailPage?.EnterReportDetail" >
          </div>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="reporttypekey != 'advance-custom-weather-report'">
      <form [formGroup]="reporttitleForm" class="w-100">
        <h5 class="titletext w-100 ">{{ automaticdetailPage?.ReportTitle }}</h5>
        <input formControlName="Report_Title" class="titleinput w-100 px-3" [placeholder]="automaticdetailPage?.EnterReportTitle" >
      </form>
    </ng-container>
  </div>
  <ng-container *ngIf="reporttypekey == 'advance-custom-weather-report' && !isAdvanceCustomReport">
    <div class="addLocHeading brdr-bottom-grey d-flex flex-wrap w-100 py-4 mx-2 align-items-center justify-content-between">
      <div style="width: 48%;">
        <div class="d-flex">
          <div class="form-check form-check-inline">
            <label [ngClass]="language === 'en' ? 'pe-3' : 'ps-3'" class="form-check-label p-0" style="font-size: 1.1em; font-weight: 500; color: #005bb5;" for="inlineRadio3">{{ automaticdetailPage?.HistoricalDaysRange }}</label>
            <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio3" [checked]="isHistoricalDays" [value]="isHistoricalDays" (click)="onHistoricalDays($event)">
          </div>
          <div class="form-check form-check-inline">
            <label [ngClass]="language === 'en' ? 'pe-3' : 'ps-3'" class="form-check-label" style="font-size: 1.1em; font-weight: 500; color: #005bb5;" for="inlineRadio4">{{ automaticdetailPage?.ForecastedDaysRange }}</label>
            <input class="form-check-input" type="radio" name="inlineRadioOptions2" id="inlineRadio4" [checked]="isForecastedDays" [value]="isForecastedDays" (click)="onForecastedDays($event)">
          </div>
        </div>
        <div class="d-flex">
          <div *ngIf="isHistoricalDays" (click)="d1.toggle()" class="date-picker-container col w-50">
            <input placeholder="yyyy-mm-dd" [(ngModel)]="historicalDate" [minDate]="historicalMinStartDate" [maxDate]="historicalMaxStartDate" type="text" container="body" ngbDatepicker #d1="ngbDatepicker" readonly>
            <img src="../../../../assets/date-picker-icon.svg" class="date_picker_icon" [ngClass]="language === 'en' ? 'en-img' : 'ar-img'">
          </div>
          <div *ngIf="isForecastedDays" (click)="d2.toggle()" class="date-picker-container col w-50">
            <input placeholder="yyyy-mm-dd" [minDate]="forecastMinStartDate" [maxDate]="forecastMaxStartDate" [(ngModel)]="forecastedDate" type="text" container="body" ngbDatepicker #d2="ngbDatepicker" readonly>
            <img src="../../../../assets/date-picker-icon.svg" class="date_picker_icon" [ngClass]="language === 'en' ? 'en-img' : 'ar-img'">
          </div>
          <!-- <mat-form-field *ngIf="isHistoricalDays" [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col w-50" appearance="outline">
            <mat-label>{{ automaticdetailPage?.Days }}</mat-label>
            <mat-select [(ngModel)]="historicalDays" (valueChange)="getHistoricalDays($event)">
              <mat-option [value]="7">
                <ng-container *ngIf="language === 'en'; else arabic7">
                  7 Days
                </ng-container>
                <ng-template #arabic7>
                  ٧ أيام
                </ng-template>
              </mat-option>
              <mat-option [value]="15">
                <ng-container *ngIf="language === 'en'; else arabic15">
                  15 Days
                </ng-container>
                <ng-template #arabic15>
                  ١٥ يومًا
                </ng-template>
              </mat-option>
              <mat-option [value]="30">
                <ng-container *ngIf="language === 'en'; else arabic30">
                  30 Days
                </ng-container>
                <ng-template #arabic30>
                  ٣٠ يومًا
                </ng-template>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="isForecastedDays" [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col w-50" appearance="outline">
            <mat-label>{{ automaticdetailPage?.Days }}</mat-label>
            <mat-select [(ngModel)]="forecastedDays" (valueChange)="getForecastedDays($event)">
              <mat-option [value]="7">
                <ng-container *ngIf="language === 'en'; else arabic7">
                  7 Days
                </ng-container>
                <ng-template #arabic7>
                  ٧ أيام
                </ng-template>
              </mat-option>
              <mat-option [value]="10">
                <ng-container *ngIf="language === 'en'; else arabic10">
                  10 Days
                </ng-container>
                <ng-template #arabic10>
                  ١٠ أيام
                </ng-template>
              </mat-option>
              <mat-option [value]="15">
                <ng-container *ngIf="language === 'en'; else arabic15">
                  15 Days
                </ng-container>
                <ng-template #arabic15>
                  ١٥ يومًا
                </ng-template>
              </mat-option>
            </mat-select>
          </mat-form-field> -->
        </div>
      </div>
      <div *ngIf="reporttypekey == 'advance-custom-weather-report' && !isAdvanceCustomReport" class="addLocHeading d-flex flex-wrap">
        <h5 class="titletext w-100">{{ automaticdetailPage?.SelectCity }}</h5>
        <form [formGroup]="cityForm" class="w-100">
          <ng-container>
            <div class="d-flex flex-wrap align-items-center">
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
                <mat-label>{{regionPage?.Governorates}}</mat-label>
                <mat-select [formControl]="cityForm.controls.selectedGovernates" multiple (selectionChange)="onSelectionChange($event)">
                  <mat-select-trigger>
                    <ng-container *ngFor="let selectedId of cityForm.controls.selectedGovernates.value; let i = index">
                      <ng-container *ngIf="i === 0">
                        {{ getGovernateNameById(selectedId) }}
                      </ng-container>
                    </ng-container>
                    <span class="additional-selection" *ngIf="(cityForm.controls.selectedGovernates.value.length || 0) > 1">
                      (+{{ (cityForm.controls.selectedGovernates.value.length || 0) - 1 }}
                      {{ cityForm.controls.selectedGovernates.value.length === 2 ? "other" : "others" }})
                    </span>
                  </mat-select-trigger>
                  <mat-option (click)="toggleSelectAll()" (onSelectionChange)="isAllSelected()" [value]="allGovernatesIds" style="min-height: 40px;">
                    {{regionPage?.SelectAll}}
                  </mat-option>
                  <input matInput [formControl]="filterGovernorates" [placeholder]="regionPage?.Search" (keyup)="filterGovernates()" class="packageinputfilter">
                  <mat-option *ngFor="let governate of filteredGov" [value]="governate.id">
                    <ng-container *ngIf="language === 'en'; else arabic">
                      {{governate?.nameEn}}
                    </ng-container>
                    <ng-template #arabic>
                      {{governate?.nameAr}}
                    </ng-template>
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </form>
      </div>
    </div>
  </ng-container>
  <div *ngIf="reporttypekey == 'advance-custom-weather-report' && !isMannedReport">
    <div class="col p-3">
      <div class="addLocHeading pb-3">
        <h3>
          {{regionPage?.UploadAttachment}}
        </h3>
      </div>
      <div class="d-flex gap-2">
        <input (change)="handleFileChange($event)" #fileBtn class="d-none" type="file" accept=".pdf">
        <button (click)="triggerFileInput()" class="polyBtn polySave2">
          <div class="savebg2">
            <div>
              <span>
              </span>
            </div>
          </div>
          {{regionPage?.Browse}}
        </button>
        <p class="no-file">{{fileName ? fileName : regionPage?.Nofileselected}}</p>
      </div>
      <p class="no-file mt-1 mb-0 ms-0 me-0">.pdf</p>
      <p class="max-file mt-2 mb-0 ms-0 me-0">{{regionPage?.MaxFileSize}}</p>
    </div>
  </div>
  <div *ngIf="reporttypekey != 'advance-custom-weather-report'" class="addLocHeading brdr-bottom-grey d-flex flex-wrap w-100 py-4 mx-2">
    <h5 class="titletext w-100">{{ automaticdetailPage?.SelectCity }}</h5>
    <form [formGroup]="cityForm" class="citydiv w-100">
      <!--  -->
      <ng-container *ngIf="reporttypekey != 'custom-weather-report'">
        <div class="d-flex flex-wrap gap-3 py-3">
          <div *ngFor="let city of CityList" class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" [id]="city.id.toString()" [formControl]="cityForm.get(city.id.toString())">
            <label [for]="city.id" class="form-check-label">{{language == 'en' ? city?.nameEn : city?.nameAr}}</label>
          </div>
        </div>
        <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="selectAll" (change)="selectAllChanged($event)">
          <label for="selectAll" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
        </div>
      </ng-container>
      <ng-container *ngIf="reporttypekey == 'custom-weather-report'">
        <div class="d-flex flex-wrap p-3 gap-3 align-items-center">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
            <mat-label>{{ regionPage?.Regions }}</mat-label>
            <mat-select (valueChange)="getGovernatesByRegion($event)">
              <mat-option *ngFor="let region of allRegions" [value]="region?.id">
                <ng-container *ngIf="language === 'en' else arabic">
                  {{region?.name_en}}
                </ng-container>
                <ng-template #arabic>
                  {{region?.name_ar}}
                </ng-template>
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" [ngClass]="{'disabled': governates.length == 0}"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{regionPage?.Governorates}}</mat-label>
          <mat-select [formControl]="selectedGovernates" [disabled]="governates.length === 0" multiple>
            <mat-select-trigger>
              <ng-container *ngFor="let governate of governates">
                <ng-container *ngIf="selectedGovernates.value[0] === undefined">
                  <ng-container *ngIf="selectedGovernates.value?.[1] === governate?.id">
                    {{language === 'en' ? governate?.nameEn : governate?.nameAr}}
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="selectedGovernates.value[0] !== undefined">
                  <ng-container *ngIf="selectedGovernates.value?.[0] === governate?.id">
                    {{language === 'en' ? governate?.nameEn : governate?.nameAr}}
                  </ng-container>
                </ng-container>
              </ng-container>
              <span class="additional-selection" *ngIf="(selectedGovernates.value?.length || 0) > 1">
                <ng-container *ngIf="selectedGovernates.value[0] === undefined">
                  (+{{ (selectedGovernates.value?.length || 0) - 2 }}
                  {{ selectedGovernates.value?.length === 2 ? "other" : "others" }})
                </ng-container>
                <ng-container *ngIf="selectedGovernates.value[0] !== undefined">
                  (+{{ (selectedGovernates.value?.length || 0) - 1 }}
                  {{ selectedGovernates.value?.length === 2 ? "other" : "others" }})
                </ng-container>
              </span>
            </mat-select-trigger>
            <mat-option selectAll [allValues]="allGovernatesIds" style="min-height: 40px;">{{regionPage?.SelectAll}}</mat-option>
            <input matInput [formControl]="filterGovernorates" [placeholder]="regionPage?.Search" matSelectFilter [section]="'Gov'" [control]="filterGovernorates" [items]="governates" [language]="language" (filteredItems)="updateFilteredItems($event, 'Gov')" class="packageinputfilter">
            <mat-option *ngFor="let governate of filteredGov" [value]="governate.id">
              <ng-container *ngIf="language === 'en' else arabic">
                {{governate?.nameEn}}
              </ng-container>
              <ng-template #arabic>
                {{governate?.nameAr}}
              </ng-template>
            </mat-option>
          </mat-select>
        </mat-form-field>
        </div>
      </ng-container>
    </form>
  </div>
  <div *ngIf="reporttypekey == 'advance-custom-weather-report' && !isAdvanceCustomReport" class="addLocHeading brdr-bottom-grey d-flex flex-wrap w-100 py-4 mx-2">
    <h5 class="titletext w-100">{{ language == 'en' ? titleEn : titleAr }}</h5>
    <!-- <form [formGroup]="weatherForm" class="citydiv">
      <div class="flex-wrap d-flex gap-3 py-3">
        <div *ngFor="let Parameters of WeatherParamsList" class="checkdiv form-check">
          <input type="checkbox" class="form-check-input" [id]="Parameters.id.toString()" [formControl]="weatherForm.get(replaceKey(Parameters.meteoMaticsKey))">
          <label [for]="Parameters.id" class="form-check-label">{{language == 'en' ? Parameters?.nameEn : Parameters?.nameAr}}</label>
        </div>
      </div>
      <div class="checkdiv py-3 form-check">
        <input type="checkbox" class="form-check-input" id="selectAllW">
        <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
      </div>
    </form> -->
    <!-- <form [formGroup]="weatherForm" class="citydiv w-100">
      <div class="d-flex flex-wrap gap-3 py-3">
        <div *ngFor="let group of groupedWeatherParamsList" class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" [id]="group.groupKey" [formControl]="weatherForm.get(group.groupKey)" (change)="toggleGroup(group)">
          <label [for]="group.groupKey" class="form-check-label">{{ group.parameters[0] | GroupKey : language}}</label>
        </div>
      </div>
      <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
        <input type="checkbox" class="form-check-input" id="selectAllW" (change)="selectAllWeatherParameters($event)">
        <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
      </div>
    </form> -->
    <form [formGroup]="reportsForm" class="citydiv w-100">
      <div class="d-flex flex-wrap gap-3 py-3">
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox1" formControlName="temperature">
          <label for="checkbox1" class="form-check-label">{{ automaticdetailPage?.Temperature }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox2" formControlName="windspeed">
          <label for="checkbox2" class="form-check-label">{{ automaticdetailPage?.WindSpeed }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox3" formControlName="winddirection">
          <label for="checkbox3" class="form-check-label">{{ automaticdetailPage?.WindDirection }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox4" formControlName="humidity">
          <label for="checkbox4" class="form-check-label">{{ automaticdetailPage?.Humidity }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox5" formControlName="precipitation">
          <label for="checkbox5" class="form-check-label">{{ automaticdetailPage?.Precipitation }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox6" formControlName="probability">
          <label for="checkbox6" class="form-check-label">{{ automaticdetailPage?.Probability }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox9" formControlName="windpressure">
          <label for="checkbox9" class="form-check-label">{{ automaticdetailPage?.Pressure }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox7" formControlName="slipperyroad">
          <label for="checkbox7" class="form-check-label">{{ automaticdetailPage?.SlipperyRoad }}</label>
        </div>
        <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="checkbox8" formControlName="weathersymbol" checked disabled>
          <label for="checkbox8" class="form-check-label">{{ automaticdetailPage?.WeatherCondition }}</label>
        </div>
      </div>
      <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" id="selectAllW" (change)="OnSelectAll($event)">
          <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
      </div>
    </form>
  </div>
  <div *ngIf="reporttypekey != 'advance-custom-weather-report'" class="addLocHeading brdr-bottom-grey d-flex flex-wrap w-100 py-4 mx-2">
    <h5 class="titletext w-100">{{ language == 'en' ? titleEn : titleAr }}</h5>
    <!-- <form [formGroup]="weatherForm" class="citydiv w-100">
      <div class="d-flex flex-wrap gap-3 py-3">
        <div *ngFor="let group of groupedWeatherParamsList" class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
          <input type="checkbox" class="form-check-input" [id]="group.groupKey" [formControl]="weatherForm.get(group.groupKey)" (change)="toggleGroup(group)">
          <label [for]="group.groupKey" class="form-check-label">{{ group.parameters[0] | GroupKey : language}}</label>
        </div>
      </div>
      <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
        <input type="checkbox" class="form-check-input" id="selectAllW" (change)="selectAllWeatherParameters($event)">
        <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
      </div>
    </form> -->
    <ng-container *ngIf="reporttypekey == 'custom-weather-report'">
      <form [formGroup]="customReportsForm" class="citydiv w-100">
        <div class="d-flex flex-wrap gap-3 py-3">
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox1" formControlName="temperature">
            <label for="checkbox1" class="form-check-label">{{ automaticdetailPage?.Temperature }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox2" formControlName="windspeed">
            <label for="checkbox2" class="form-check-label">{{ automaticdetailPage?.WindSpeed }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox3" formControlName="winddirection">
            <label for="checkbox3" class="form-check-label">{{ automaticdetailPage?.WindDirection }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox4" formControlName="humidity">
            <label for="checkbox4" class="form-check-label">{{ automaticdetailPage?.Humidity }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox5" formControlName="precipitation">
            <label for="checkbox5" class="form-check-label">{{ automaticdetailPage?.Precipitation }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox6" formControlName="probability">
            <label for="checkbox6" class="form-check-label">{{ automaticdetailPage?.Probability }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox9" formControlName="pressure">
            <label for="checkbox9" class="form-check-label">{{ automaticdetailPage?.Pressure }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox7" formControlName="slipperyroad">
            <label for="checkbox7" class="form-check-label">{{ automaticdetailPage?.SlipperyRoad }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox8" formControlName="weathersymbol" checked disabled>
            <label for="checkbox8" class="form-check-label">{{ automaticdetailPage?.WeatherCondition }}</label>
          </div>
        </div>
        <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="selectAllW" (change)="OnCustomSelectAll($event)">
            <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="reporttypekey == 'mashaer-weather-report'">
      <form [formGroup]="mashaerReportsForm" class="citydiv w-100">
        <div class="d-flex flex-wrap gap-3 py-3">
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox1" formControlName="temperature">
            <label for="checkbox1" class="form-check-label">{{ automaticdetailPage?.Temperature }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox2" formControlName="windspeed">
            <label for="checkbox2" class="form-check-label">{{ automaticdetailPage?.WindSpeed }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox3" formControlName="winddirection">
            <label for="checkbox3" class="form-check-label">{{ automaticdetailPage?.WindDirection }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox4" formControlName="humidity">
            <label for="checkbox4" class="form-check-label">{{ automaticdetailPage?.Humidity }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox5" formControlName="precipitation">
            <label for="checkbox5" class="form-check-label">{{ automaticdetailPage?.Precipitation }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox6" formControlName="probability">
            <label for="checkbox6" class="form-check-label">{{ automaticdetailPage?.Probability }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox9" formControlName="pressure">
            <label for="checkbox9" class="form-check-label">{{ automaticdetailPage?.Pressure }}</label>
          </div>
          <!-- <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox7" formControlName="slipperyroad">
            <label for="checkbox7" class="form-check-label">{{ automaticdetailPage?.SlipperyRoad }}</label>
          </div> -->
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox8" formControlName="weathersymbol" checked disabled>
            <label for="checkbox8" class="form-check-label">{{ automaticdetailPage?.WeatherCondition }}</label>
          </div>
        </div>
        <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="selectAllW" (change)="OnMashaerSelectAll($event)">
            <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
        </div>
      </form>
    </ng-container>
    <ng-container *ngIf="reporttypekey == 'red-sea-report' || reporttypekey == 'arabian-gulf-report'">
      <form [formGroup]="seaReportsForm" class="citydiv w-100">
        <div class="d-flex flex-wrap gap-3 py-3">
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox1" formControlName="temperature">
            <label for="checkbox1" class="form-check-label">{{ automaticdetailPage?.Temperature }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox2" formControlName="windspeed">
            <label for="checkbox2" class="form-check-label">{{ automaticdetailPage?.WindSpeed }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox3" formControlName="winddirection">
            <label for="checkbox3" class="form-check-label">{{ automaticdetailPage?.WindDirection }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox4" formControlName="humidity">
            <label for="checkbox4" class="form-check-label">{{ automaticdetailPage?.Humidity }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox5" formControlName="waveheight">
            <label for="checkbox5" class="form-check-label">{{ automaticdetailPage?.WaveHeight }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox6" formControlName="visibility">
            <label for="checkbox6" class="form-check-label">{{ automaticdetailPage?.Visibility }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox9" formControlName="hightide">
            <label for="checkbox9" class="form-check-label">{{ automaticdetailPage?.HighTide }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox7" formControlName="lowtide">
            <label for="checkbox7" class="form-check-label">{{ automaticdetailPage?.LowTide }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="checkbox8" formControlName="weathersymbol" checked disabled>
            <label for="checkbox8" class="form-check-label">{{ automaticdetailPage?.WeatherCondition }}</label>
          </div>
        </div>
        <div class="checkdiv py-3 form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="selectAllW" (change)="OnSeaSelectAll($event)">
            <label for="selectAllW" class="form-check-label">{{ automaticdetailPage?.SelectAll }}</label>
        </div>
      </form>
    </ng-container>
  </div>
  <div *ngIf="reporttypekey != 'custom-weather-report' && reporttypekey != 'mashaer-weather-report' && reporttypekey != 'advance-custom-weather-report'" class="d-flex flex-wrap g-3 my-4 mx-2">
    <form [formGroup]="statesForm" class="d-flex flex-wrap w-100">
      <div class="dist d-flex flex-column gap-3" style="padding-inline-end: 1rem;">
        <div class="detail-en">
          <h5 class="titletext">{{ automaticdetailPage?.SurfaceWind }} <span>( English )</span></h5>
          <div class="col-md-12 d-flex justify-content-center">
            <textarea formControlName="Surface_Wind" id="summary" (onkeyup)="validate()" class="textareaclass"
              [placeholder]="automaticdetailPage?.EnterSurfaceWind"></textarea>
          </div>
          <!-- <div class="d-flex justify-content-end pt-3">
            <span id="words_count" class="spantext"><span id="textcount"></span>/200 words</span>
          </div> -->
        </div>
        <div class="detail-ar">
          <h5 class="titletext">{{ automaticdetailPage?.SurfaceWind }} <span style="font-family: 'Somar' !important;font-size: 18px !important;">( عربي )</span></h5>
          <div class="col-md-12 d-flex justify-content-center">
            <textarea formControlName="Surface_Wind_ar" id="summary" (onkeyup)="validate()" class="textareaclass"
              [placeholder]="automaticdetailPage?.EnterSurfaceWind"></textarea>
          </div>
          <!-- <div class="d-flex justify-content-end pt-3">
            <span id="words_count" class="spantext"><span id="textcount"></span>/200 words</span>
          </div> -->
        </div>
      </div>
      <div class="dist d-flex flex-column gap-3" style="padding-inline-end: 1rem;">
        <div class="detail-en">
          <h5 class="titletext">{{ automaticdetailPage?.WaveHeight }} <span>( English )</span></h5>
          <div class="col-md-12 d-flex justify-content-center">
            <textarea formControlName="Wave_Height" id="summary" (onkeyup)="validate()" class="textareaclass"
              [placeholder]="automaticdetailPage?.EnterWaveHeight"></textarea>
          </div>
          <!-- <div class="d-flex justify-content-end pt-3">
            <span id="words_count" class="spantext"><span id="textcount"></span>/200 words</span>
          </div> -->
        </div>
        <div class="detail-ar">
          <h5 class="titletext">{{ automaticdetailPage?.WaveHeight }} <span style="font-family: 'Somar' !important;font-size: 18px !important;">( عربي )</span></h5>
          <div class="col-md-12 d-flex justify-content-center">
            <textarea formControlName="Wave_Height_ar" id="summary" (onkeyup)="validate()" class="textareaclass"
              [placeholder]="automaticdetailPage?.EnterWaveHeight"></textarea>
          </div>
          <!-- <div class="d-flex justify-content-end pt-3">
            <span id="words_count" class="spantext"><span id="textcount"></span>/200 words</span>
          </div> -->
        </div>
      </div>
      <div class="dist d-flex flex-column gap-3">
        <div class="detail-en">
          <h5 class="titletext">{{ automaticdetailPage?.StateoftheSea }} <span>( English )</span></h5>
          <div class="col-md-12 d-flex justify-content-center">
            <textarea formControlName="State_of_the_Sea" id="summary" (onkeyup)="validate()" class="textareaclass"
              [placeholder]="automaticdetailPage?.EnterStateoftheSea"></textarea>
          </div>
          <!-- <div class="d-flex justify-content-end pt-3">
            <span id="words_count" class="spantext"><span id="textcount"></span>/200 words</span>
          </div> -->
        </div>
        <div class="detail-ar">
          <h5 class="titletext">{{ automaticdetailPage?.StateoftheSea }} <span style="font-family: 'Somar' !important;font-size: 18px !important;">( عربي )</span></h5>
          <div class="col-md-12 d-flex justify-content-center">
            <textarea formControlName="State_of_the_Sea_ar" id="summary" (onkeyup)="validate()" class="textareaclass"
              [placeholder]="automaticdetailPage?.EnterStateoftheSea"></textarea>
          </div>
          <!-- <div class="d-flex justify-content-end pt-3">
            <span id="words_count" class="spantext"><span id="textcount"></span>/200 words</span>
          </div> -->
        </div>
      </div>
    </form>
  </div>
  <ng-container *ngIf="reporttypekey == 'custom-weather-report' || reporttypekey == 'mashaer-weather-report' || reporttypekey == 'advance-custom-weather-report'">
    <div [ngClass]="" class="d-flex flex-wrap addLocHeading w-100 py-4 mx-2">
      <h5 class="titletext w-100">{{ automaticdetailPage?.SelectChannel }}</h5>
      <form class="citydiv w-100" [formGroup]="channelForm">
        <div class="d-flex flex-wrap gap-3 py-3">
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="publicportal" formControlName="publicPortal">
            <label for="publicportal" class="form-check-label">{{ automaticdetailPage?.PublicPortal }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="ajwaaportal" formControlName="ajwaaPortal" (change)="onAjwaaChanges($event)">
            <label for="ajwaaportal" class="form-check-label">{{ automaticdetailPage?.AjwaaPortal }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="allorganizations" formControlName="allOrganizations" (change)="onAllOrganizationChanges($event)">
            <label for="allorganizations" class="form-check-label">{{ automaticdetailPage?.AllOrganizations }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="selectorganizations" formControlName="selectOrganizations" (change)="openModalForOrganizations($event,content)">
            <label for="selectorganizations" class="form-check-label">{{ automaticdetailPage?.SelectOrganizations }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="addemails" formControlName="addEmails" (change)="openModalForEmails($event, email)">
            <label for="addemails" class="form-check-label">{{ automaticdetailPage?.AddEmails }}</label>
          </div>
          <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="addemailgroup" formControlName="addEmailGroup" (change)="openModalForEmailGroup($event)">
            <label for="addemailgroup" class="form-check-label">{{ automaticdetailPage?.AddEmailGroups }}</label>
          </div>
          <div *ngIf="reporttypekey == 'advance-custom-weather-report' && !isAdvanceCustomReport" class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="twitter" formControlName="twitter">
            <label for="twitter" class="form-check-label">{{ automaticdetailPage?.Xx }}</label>
          </div>
          <div *ngIf="reporttypekey != 'advance-custom-weather-report'" class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
            <input type="checkbox" class="form-check-input" id="twitter" formControlName="twitter">
            <label for="twitter" class="form-check-label">{{ automaticdetailPage?.Xx }}</label>
          </div>
        </div>
        <ng-template #email let-modal>
          <div class="modal-header">
            <h3 class="modalheadtext m-0" id="modal-basic-title">{{ automaticdetailPage?.AddSpecificEmails }}</h3>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
          </div>
          <div class="modal-body">
            <!-- <div class="d-flex align-items-center gap-2 px-3" formArrayName="email">
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"  class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 w-50" appearance="outline">
                <mat-label>{{ automaticdetailPage?.AddSpecificEmails }}</mat-label>
                <input formControlName="email"  type="text" matInput>
              </mat-form-field>
            </div> -->
            <div class="container-fluid">
              <div [ngClass]="channelForm.get('count')?.length > 5 ? 'justify-content-evenly' : 'justify-content-start'" class="row overflow-auto flex-nowrap gap-3">
                <!-- <form > -->
                  <ng-container *ngFor="item of count().controls; index as j">
                    <div *ngIf="j % 5 === 0" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 m-0 p-0 custombrdr position-relative" style="width: auto;" formArrayName="count">
                      <div class="form-row py-3" [ngClass]="language == 'en' ? 'pe-3' : 'ps-3'">
                        <div [id]="i" class="d-flex align-items-center gap-3 pb-3" *ngFor="let item of count().controls.slice(j, j + 5); index as i" [formGroupName]="j + i">
                          <div class="d-flex flex-column">
                            <!-- <input type="email" formControlName="email" class="inputtext sameWidth" placeholder="Email"> -->
                            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="sameWidth" appearance="outline">
                              <mat-label>{{automaticdetailPage?.AddSpecificEmails}}</mat-label>
                              <input formControlName="email"  type="text" matInput>
                            </mat-form-field>
                          </div>
                          <div class="removefieldclass" (click)="removeCount(j)" [ngClass]="{'disabled': count().controls.length === 1}">
                            <img src="../../../assets/removefield-icon.png">
                          </div>
                        </div>

                      </div>
                    </div>
                  </ng-container>
                <!-- </form> -->
              </div>
              <div container="body" placement="top" [ngbTooltip]="count().controls.length == 10 ? 'Cannot add more then 10 fields' : ''" class="addanotherfieldclass pt-3" (click)="addcount()" [ngClass]="{'disableds': count().controls.length === 10}">
                <img src="../../../assets/addanotherfield-icon.png">
                <h6 class="addanotherusertextclass m-0">{{ automaticdetailPage?.AddAnotherEmail }}</h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="previewbtn" (click)="modal.close('Save click')">{{ automaticdetailPage?.Save }}</button>
          </div>
        </ng-template>
        <ng-template #content let-modal>
          <div class="modal-header">
            <h3 class="modalheadtext m-0" id="modal-basic-title">{{ automaticdetailPage?.SelectAnOrganization }}</h3>
            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
          </div>
          <div class="modal-body">
            <div class="d-flex align-items-center gap-2 px-3">
              <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"  class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 w-50" appearance="outline">
                <mat-label>{{ automaticdetailPage?.SelectAnOrganization }}</mat-label>
                <mat-select formControlName="selectOrganizationsArray" multiple msInfiniteScroll (infiniteScroll)="getOrganizationUsersForDropdown()" floatLabel="always">
                  <ng-container *ngFor="let group of organizationUsers.data">
                    <mat-option [value]="group.id">{{ language == 'en' ? group?.nameEn : group?.nameAr }}</mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
              <div class="checkdiv form-check" [ngStyle]="language == 'en' ? {'padding-left':'1.5em'} : {'padding-right':'1.5em'}">
                <input type="checkbox" class="form-check-input" id="sendEmail" formControlName="sendEmail">
                <label for="sendEmail" class="form-check-label">{{ automaticdetailPage?.SendEmails }}</label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="previewbtn" (click)="modal.close('Save click')">{{ automaticdetailPage?.Save }}</button>
          </div>
        </ng-template>
      </form>
    </div>
  </ng-container>
</div>
<div class="d-flex justify-content-end align-items-center flex-wrap gap-2">
  <!-- <button type="button" class="canceltxt" >
    {{ automaticdetailPage?.Publish }}
  </button> -->
  <button data-bs-dismiss="modal" type="button" class="publishbtn" routerLink="/home/automatic-weather-report">
    {{ automaticdetailPage?.Cancel }}
  </button>
  <ng-container *ngIf="reporttypekey != 'advance-custom-weather-report'">
    <button (click)="goToAutomaticWeatherReport()" type="button" class="previewbtn">
      {{ automaticdetailPage?.Preview }}
    </button>
  </ng-container>
  <ng-container *ngIf="reporttypekey == 'advance-custom-weather-report' && !isAdvanceCustomReport">
    <button (click)="goToAutomaticWeatherReport()" type="button" class="previewbtn">
      {{ automaticdetailPage?.Preview }}
    </button>
  </ng-container>
  <ng-container *ngIf="reporttypekey == 'advance-custom-weather-report' && !isMannedReport">
    <button (click)="publishMannedReport()" type="button" class="previewbtn">
      {{ automaticdetailPage?.Publish }}
    </button>
  </ng-container>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateUserGroupModelAPI, ListUserGroupModelAPI } from 'src/app/models/user-groups/user-groups.model';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
    providedIn: 'root'
})

export class UserGroupsService {
    private baseUrl: string;

    constructor(private http: HttpClient) {
      this.baseUrl = `${environmentBaseUrl.url}/api/ncm/ews/`;
    }
    
    createUserGroup(createUserGroupModelAPI: CreateUserGroupModelAPI){
        const url = `${this.baseUrl}create-ews-report-user-group`
        return this.http.post(url, createUserGroupModelAPI).toPromise();
    }

    updateUserGroup(updateUserGroupModelAPI: CreateUserGroupModelAPI){
        const url = `${this.baseUrl}update-ews-report-user-group`
        return this.http.post(url, updateUserGroupModelAPI).toPromise();
    }

    deleteUserGroup(id: number){
        const url = `${this.baseUrl}delete-ews-report-user-group`
        return this.http.post(url, {id}).toPromise();
    }

    listUserGroup(listUserGroupModelAPI: ListUserGroupModelAPI){
        const url = `${this.baseUrl}list-ews-report-user-group`
        return this.http.post(url, listUserGroupModelAPI).toPromise();
    }
}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'HighLowTideCriteria'
})
export class HighLowTideCriteriaPipe implements PipeTransform {
  transform(value: number | string, language?:string): any {
    let lang = localStorage.getItem('language');
    if(language){
        lang = language;
    }

    if(lang === 'en'){
      if(typeof value === 'string'){
        value = parseFloat(value)
      }
      if (value === -666) {
          return "Not Available";
      }
      else{
        return value;
      }
    }
    else{
      if(typeof value === 'string'){
        value = parseFloat(value)
      }

      if (value === -666) {
          return "غير متاح";
      }
      else{
        return value;
      }
    }


}
}

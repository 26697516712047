import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherDescriptionDetailedAR'
})
export class WeatherDescriptionDetailedPipeAR implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 0:
        return "تعذر تحديد رمز الطقس بسبب بيانات غير كافية أو خطأ في استرجاع معلومات الطقس.";
      case 1:
      case 101:
        return "الطقس مميز بسماء صافية، حيث لا تظهر سحب ذات أهمية. الشمس تشرق بوضوح، والسماء زرقاء حية.";
      case 2:
      case 102:
        return "الطقس الحالي يتميز بغطاء سحابي خفيف. بينما لا تزال الشمس مرئية، إلا أنها معترضة جزئيًا بسحب رقيقة، مما يخلق جوًا مغمورًا ولكن لطيفًا.";
      case 3:
      case 103:
        return "تظهر السماء غائمة جزئيًا، مقدمة مزيجًا رائعًا من السحب والسماء الصافية. تظهر الشمس بين السحب المنفوشة بشكل متقطع، مقدمة تجربة بصرية ديناميكية.";
      case 4:
      case 104:
        return "الطقس غائم، مع سماء ملبدة بالغيوم. تهيمن السحب الكثيفة على الجو، مما يحجب أشعة الشمس المباشرة ويصبح الجو رماديًا وكئيبًا.";
      case 5:
      case 105:
        return "الطقس الحالي مميز بالأمطار. قطرات المطر تتساقط من السماء، مما يخلق صوتًا مهدئًا عندما تصطدم بالأرض.";
      case 6:
      case 106:
        return "تتضمن ظروف الطقس كل من المطر والثلج، وغالبًا ما يُشار إليها باسم الزخات. الهطول يتألف من مزيج من قطرات المطر وبلورات الثلج، مما يؤدي إلى مستويات مختلفة من الرطوبة والمياه المذابة.";
      case 7:
      case 107:
        return "الثلج يتساقط من السماء، يغطي المناظر ببطانة بيضاء ناعمة. العالم يتحول إلى عالم شتوي مدهش حيث تتساقط براعم الثلج برفق.";
      case 8:
      case 108:
        return "يتميز الطقس بزخات مطرية، فترات قصيرة من الأمطار الغزيرة. تحمل الزخات المطرية عادة هطولًا مكثفًا ومؤقتًا يمكن أن يبلل المحيط بسرعة.";
      case 9:
      case 109:
        return "تحدث زخات ثلجية، مع هطول ثلوج قصير ومتقطع. يمكن أن تضيف هذه الزخات طبقات جديدة من الثلج إلى المناظر الموجودة.";
      case 10:
      case 110:
        return "تحدث زخات من البَرَد، مزيج من المطر والثلج. الهطول هو مزيج من قطرات المطر الجليدية وبلورات الثلج.";
      case 11:
      case 111:
        return "الطقس يتسم بالضباب الخفيف، مما يقلل قليلاً من الرؤية. الجو أصبح أكثر غمرة، والأشياء البعيدة قد تبدو أكثر غموضًا.";
      case 12:
      case 112:
        return "استقر الضباب الكثيف بشكل كبير، مما يقلل بشكل كبير من الرؤية. يكتنف الأماكن في ضباب كثيف، مما يجعل من الصعب رؤية أبعد من مسافة قصيرة.";
      case 13:
      case 113:
        return "الأمطار المتجمدة تتساقط، مما يخلق ظروفًا خطيرة. يحدث هذا النوع من الهطول عندما تتجمد قطرات المطر عند اتصالها بالأسطح الباردة، مما يؤدي إلى ظروف جليدية وملساء.";
      case 14:
      case 114:
        return "العواصف الرعدية قائمة، مع البرق والرعد والأمطار الغزيرة والرياح القوية. يمكن أن تترافق هذه الأحداث الجوية الشديدة مع عروض مذهلة لقوة الطبيعة.";
      case 15:
      case 115:
        return "يحدث هطول رذاذي، مع قطرات مطر خفيفة ورقيقة تتساقط بلطف. على الرغم من أنه قد لا يؤدي إلى هطول غزير، إلا أن الرذاذ يمكن أن يخلق رطوبة دائمة في البيئة.";
      case 16:
      case 116:
        return "عاصفة رملية في تأثير، مع رياح قوية تعصف بجزيئات الرمل عبر المناظر الطبيعية. تتقلص الرؤية بشدة، ويجب اتخاذ تدابير وقائية للوقاية من الرمال العائمة.";
      default:
        return "وصف طقس غير معروف";
    }
  }
}
import { MannedAlertsModel } from 'src/app/models/manned-alerts/manned-alerts.model';
import { DatePipe } from '@angular/common';
import { Component, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/service/authentication.service';
import { CityListModel, GenerateReportPdfModel, ListReportModel, ListReportModelres, MannedForecastModel, ParametersMap } from 'src/app/models/manned-forecast/manned-forecast.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { MannedForecast } from 'src/app/services/manned-forecast/manned-forecast.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { ReportTypesDataModel, ReportTypesModelAPI } from 'src/app/models/automatic-weather-report/automatic-weather-report.model';
import { AutomaticWeatherReport } from 'src/app/services/automatic-weather-report/automatic-weather-report.service';
import { HttpErrorResponse } from '@angular/common/http';
import { debounceTime } from 'rxjs/operators';

interface DataItem {
  id: number;
}

@Component({
  selector: 'app-manned-forecast',
  templateUrl: './manned-forecast.component.html',
  styleUrls: ['./manned-forecast.component.scss']
})
export class MannedForecastComponent implements OnInit {
  private searchInputChange = new Subject<string>();
  private subscription: Subscription = new Subscription();
  language: string;
  mannedforecast: any;

  AddReport: boolean = false;
  reportsForm: FormGroup;
  permissions: any;
  checked:boolean;

  currentdate: any;
  oneweekdate:any;
  reportid:any;
  generateReport:any;
  allcities:any;
  cityid:any[]=[];
  // alllist:any;
  searchdata:ListReportModelres = new ListReportModelres()
  // allsearchdata:any;
  alldata:any;
  pageSize: number = 10;
  page: number = 1;
  entries: { id: number, label: string, value: number, label_ar: string,}[];
  selectedEntry: { id: number, label: string, value: number };

  isChecked = true;
  reporttypedata: ReportTypesDataModel[];
  search: string;


  constructor(
    private globalService: GlobalService,
    private fb: FormBuilder,
    private mannedForecastService: MannedForecast,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private automaticWeatherReportservice: AutomaticWeatherReport,
    private datePipe: DatePipe,
  ) {
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("MannedForecast").subscribe((res) => {
        this.mannedforecast = res;
      });
    }));
    this.subscription.add(this.globalService.getPermissions().subscribe((res: any) => {
      this.permissions = JSON.parse(res);
      if (this.permissions.list_ncm_user == false) {
        this.toast.error("Access Denied Contact Your Administrator");
        this.router.navigateByUrl('home/dashboard');
      }
    }));
    const dateNow = new Date().toISOString()
    const fromDate = new Date(dateNow);

    fromDate.setHours(0,0,0,0);
    const toDate = new Date(fromDate.getTime() + 9 * 24 * 60 * 60 * 1000);
    toDate.setHours(0,0,0,0);
    this.currentdate = this.datePipe.transform(fromDate, 'yyyy-MM-dd','UTC');
    localStorage.setItem("current_date",this.currentdate);
    this.oneweekdate = this.datePipe.transform(toDate, 'yyyy-MM-dd','UTC');
    localStorage.setItem("oneweekdate", this.oneweekdate);
    this.reportsForm = this.fb.group({
      temperature: true,
      windspeed: false,
      winddirection: false,
      windpressure: false,
      precipitation: false,
      humidity: true,
      probability: false,
      slipperyroad: false,
      weathersymbol:true
    });
    this.entries = [
      { id: 1, label: 'Show: 10', value: 10, label_ar: 'عرض: 10' },
      { id: 2, label: 'Show: 20', value: 20, label_ar: 'عرض: 20' },
      { id: 3, label: 'Show: 30', value: 30, label_ar: 'عرض: 30' },
      { id: 4, label: 'Show: 40', value: 40, label_ar: 'عرض: 40' },
      { id: 5, label: 'Show: 50', value: 50, label_ar: 'عرض: 50' },
    ];
    this.selectedEntry = this.entries[0];

   }

  ngOnInit(): void {
    this.searchInputChange.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.search = filterValue;
      this.page = 1
      this.getAllReports();
    });
    this.getReportTypes();
  }
  onChangeEntry(entry: number) {
    this.pageSize = entry;
    this.getAllReports()
  }
  onTypeUser(event:Event){
    const input = event.target as HTMLInputElement;
    this.searchInputChange.next(input.value);
  }
  getReportTypes(){
    this.spinner.show()
    const reportTypesModel: ReportTypesModelAPI = new ReportTypesModelAPI()
    reportTypesModel.automatic = 0;
    this.automaticWeatherReportservice.getReportTypes(reportTypesModel)
    .then((res:{success: boolean, data: ReportTypesDataModel[]}) => {
      this.reporttypedata = res.data;
      this.getAllCities();
    })
    .catch((error: HttpErrorResponse) => {
      this.globalService.handleError(error);
    })
    .finally(() => {
      this.spinner.hide();
    })
  }

  onPageChange(pageNumber:number){
    this.page = pageNumber;
    this.getAllReports();
  }

  getAllReports(){
    this.spinner.show();
    const listReportModel: ListReportModel = new ListReportModel();
    listReportModel.page = this.page;
    listReportModel.limit = this.pageSize;
    listReportModel.report_type = "mannforecast";
    if(this.search){
      listReportModel.search = this.search;
    }
    this.mannedForecastService.getListReport(listReportModel).then(
      (res:ListReportModelres) => {
        this.spinner.hide();
        this.searchdata = res;
        this.reportid = this.searchdata[0]?.data?.id;
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }
  getAllCities(){
    this.spinner.show();
    const cityListModel: CityListModel = new CityListModel();
    cityListModel.Authorization = localStorage.getItem("token");
    cityListModel.report_type_id = this.reporttypedata[0].id;
    this.mannedForecastService.getCityList(cityListModel).then(
      (res: any) => {
        this.spinner.hide();
        this.allcities = res.data;
        this.cityid = this.allcities.map(city => city.id);
        // this.publishReport();
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }
  generatePdfReport(id:number){
    // this.spinner.show();
    const generateReportPdfModel: GenerateReportPdfModel = new GenerateReportPdfModel();
    generateReportPdfModel.id = id;
    generateReportPdfModel.Authorization = localStorage.getItem("token");
    generateReportPdfModel.lang = this.language;
    this.mannedForecastService.generatePDFReport(generateReportPdfModel).then(
      (res: any) => {
        this.spinner.hide();
        if(res.success){
          this.generateReport = res.data;
          window.open(this.generateReport);
        }
        else{
          this.toast.error('PDF Report Not Available. Please try later.');
        }
      }
    ).catch((err) => {
      this.spinner.hide();
      this.globalService.handleError(err);
    });
  }
  publishReport(){
    //  if (Object.values(this.reportsForm.controls).some(control => control.value !== true)) {
    //   this.toast.error('Please select a Weather Phenomena');
    //   return false;
    // }
    this.reportsForm.controls;

    this.spinner.show();
    const mannedForecastModel: MannedForecastModel = new MannedForecastModel();
    mannedForecastModel.from_date = this.currentdate;
    mannedForecastModel.to_date = this.oneweekdate;
    mannedForecastModel.hours = "24";
    const selectedParameters: string[] = [];
    for (const [key, value] of Object.entries(this.reportsForm.controls)) {
      if (value.value === true) {
        selectedParameters.push(ParametersMap[key]);
      }
    }
    if(selectedParameters.length < 2){
      this.spinner.hide();
      this.toast.error('Please select a Weather Phenomena');
      return
    }
    mannedForecastModel.parameters_24h = selectedParameters.flat();
    mannedForecastModel.parameters_12h = [];
    mannedForecastModel.model = "mix";
    mannedForecastModel.locations = this.cityid;
    mannedForecastModel.Authorization = localStorage.getItem("token");
    this.mannedForecastService.createMannedForecastReport(mannedForecastModel).then(
      (res: any) => {
        if(res.success){
          this.spinner.hide();
          this.alldata = res.data;
          this.mannedForecastService.previewdata = this.alldata;
          this.mannedForecastService.locationslist = this.cityid;
          this.mannedForecastService.start_date = this.currentdate;
          this.mannedForecastService.end_date = this.oneweekdate;
          this.goToPreview();
        }
        else{
          this.spinner.hide();
          this.toast.error('Report Preview Not Available. Please try later.');
        }
      }
    ).catch((err) => {
      this.spinner.hide();
      console.log("err", err);
      if (err.status === 401) {
        this.toast.error('Session Expired');
        this.router.navigateByUrl('auth/login');
      }
    });
  }

  // getParameterKeys(parameters:string[]):any{
  //   let keys = parameters.map((param) => {
  //     const matchingParameter = PARAMETERS.find((parameter) => parameter.value === param);
  //     if (matchingParameter) {
  //       return matchingParameter.key;
  //     }
  //   });
  //   keys = keys.flat();
  //   return keys;
  // }

  // OnSelectAll(event:any){
  //   Object.values(this.reportsForm.controls).forEach((control)=>{
  //     control.setValue(event.target.checked)
  //   });
  // }
  OnSelectAll(event: any) {
    Object.entries(this.reportsForm.controls).forEach(([key, control]) => {
      if (key !== 'weathersymbol') {
        control.setValue(event.target.checked);
      }
    });

    // Set weathersymbol separately
    // this.reportsForm.get('weathersymbol')?.setValue(true);
  }

  goToPreview() {
    this.router.navigate(['home/manned-forecast/manned-forecast-preview']);
  }

}

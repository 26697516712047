export class ChangePasswordModel {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
  Authorization?: string;
}

export class ResendInviteUserModel {
  id: number
}

export class ResendInviteWSOuserModel {
  user_id: number
}

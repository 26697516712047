import { NgxSpinnerModule } from "ngx-spinner";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import {
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModalModule,
  NgbModule,
  NgbPaginationModule,
  NgbTooltipModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CommonModule, DatePipe } from "@angular/common";
import { ToastrModule } from "ngx-toastr";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { AlertHistoryRoutingModule } from "./alert-history-routing.module";
import { AlertHistoryService } from "src/app/services/alert-history/alert-history.service";
import { AlertHistoryComponent } from './alert-history/alert-history.component';
import {MenubarModule} from 'primeng/menubar';
import {ToolbarModule} from 'primeng/toolbar';
import {ButtonModule} from 'primeng/button';
import {SplitButtonModule} from 'primeng/splitbutton';
import { NgApexchartsModule } from "ng-apexcharts";
import {TableModule} from 'primeng/table';
import {SelectButtonModule} from 'primeng/selectbutton';
import {InputTextModule} from 'primeng/inputtext';
import { SharedModule } from "src/app/shared/shared.module";
import { NgxPermissionsModule } from "ngx-permissions";

@NgModule({
  declarations: [
      AlertHistoryComponent,
  ],

  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AlertHistoryRoutingModule,
    HttpClientModule,
    NgbModule,
    NgbModalModule,
    ToastrModule,
    NgbTooltipModule,
    NgbPaginationModule,
    NgSelectModule,
    NgxSpinnerModule,
    NgbDropdownModule,
    LeafletModule,
    LeafletDrawModule,
    NgbDatepickerModule,
    GooglePlaceModule,
    NgxMaterialTimepickerModule,
    MenubarModule,
    ToolbarModule,
    ButtonModule,
    SplitButtonModule,
    NgApexchartsModule,
    TableModule,
    SelectButtonModule,
    InputTextModule,
    SharedModule,
    NgxPermissionsModule.forChild()
  ],
  // exports: [ButtonComponent],
  exports: [NgApexchartsModule],
  providers: [DatePipe, AlertHistoryService],
  bootstrap: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AlertHistoryModule { }

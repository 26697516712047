import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MannedForecastComponent } from './manned-forecast.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { MannedForecastRoutes } from './manned-forecast.routing.module';
import { MannedPreviewComponent } from './preview/mannedpreview.component';
import { NgbModalModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PreviewModalComponent } from './preview-modal/preview-modal.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@NgModule({
  declarations: [
    MannedForecastComponent,
    MannedPreviewComponent,
    PreviewModalComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    MannedForecastRoutes,
    NgxPermissionsModule.forChild()
  ]
})
export class MannedForecastModule { }

import { Routes, RouterModule } from '@angular/router';
import { AutomaticWeatherReportComponent } from './automatic-weather-report.component';
import { AutomaticPreviewComponent } from './preview/automaticpreview.component';
import { AutomaticReportComponent } from './reportpage/automaticreportpage.component';

const routes: Routes = [
  {
    path: '',
    component: AutomaticWeatherReportComponent,
  },
  {
    path: 'automatic-weather-report-preview',
    component: AutomaticPreviewComponent,
  },
  {
    path: 'automatic-weather-report-details',
    component: AutomaticReportComponent,
  },
];

export const AutomaticWeatherReportRoutes = RouterModule.forChild(routes);

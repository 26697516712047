export class ReportTypesModelAPI {
    automatic: number
}

export interface ReportTypesModel {
    success: boolean
    data: ReportTypesDataModel[]
}

export interface ReportTypesDataModel {
    id: number;
    nameEn: string;
    nameAr: string;
    key: string;
    descriptionEn: string;
    descriptionAr: string;
    titleEn: string;
    titleAr: string;
    automatic: boolean;
}

export class ReportCityListModelAPI {
    report_type_id: number
}

export interface ReportCityListModel {
    success: boolean
    data: ReportCityListDataModel[]
}

export interface ReportCityListDataModel {
    id: number
    name: string
    nameEn: string
    nameAr: string
    lat: string
    long: string
}

export class WeatherParamsListModelAPI {
    report_type_id: number
}

export interface WeatherParamsListModel {
    success: boolean
    data: WeatherParamsListDataModel[]
}

export interface WeatherParamsListDataModel {
    id: number
    nameEn: string
    nameAr: any
    meteoMaticsKey: string
    units?: string
    unitTitle?: string
}

export class GenerateReportModelAPI {
    // parameters: string[];
    parameters_24h: string[];
    parameters_12h: string[];
    locations: number[];
    from_date: string;
    to_date: string;
    // hours: string;
    model: string;
    lang:string;
    is_governate?: boolean;
}

export interface GenerateReportModel {
    success: boolean
    data: GenerateReportDataModel[][]
}

export interface GenerateReportDataModel {
    cityEn: string
    cityAr: string
    // city: city[]
    lat: string
    lon: string
    parameters: Parameter[]
}

export interface city{
    ar: string
    en: string
}

export interface Parameter {
    parameter: string
    dates: Date[]
}

export interface Date {
    date: string
    value: number
}

export const ParametersMap = {
    temperature:['t_max_2m_24h:C','t_min_2m_24h:C'],
    windspeed:'wind_speed_10m:kmh',
    winddirection:"wind_dir_10m:d",
    windpressure:"sfc_pressure:hPa",
    precipitation:"precip_24h:mm",
    humidity:"relative_humidity_2m:p",
    probability:"prob_precip_24h:p",
    slipperyroad:"is_slippery_road_24h:idx",
    weathersymbol:'weather_symbol_24h:idx',
    tmax:'t_max_2m_24h:C',
    tmin:'t_min_2m_24h:C'
}

export class PublishReportModelAPI {
    data: any[] = [];
    report_title: string = '';
    surface_wind: string = '';
    wave_height: string = '';
    state_of_the_sea: string = '';
    surface_wind_ar: string = '';
    wave_height_ar: string = '';
    state_of_the_sea_ar: string = '';
    report_type_id: number;
    lang: string = '';
    locations: number[]
    start_date: string
    end_date:string;
    organizations?: number[];
    channels: string[];
    emails?: string[];
    send_email?: number;
    user_group_ids?: number[];
    is_governate?: boolean;
    report_id?: number;
    constructor(){
      // this.organizations = [0];
    }
}
export class GenerateMannedReport {
  titleEn: string;
  titleAr: string;
  descriptionEn: string;
  descriptionAr: string;
  channels: string[];
  emails?: string[];
  lang: string = '';
  report_type_id: number;
  fileName: string;
  file: string;
  organizations?: number[];
  send_email?: number;
  user_group_ids?: number[];
}
export class PreviewAdvanceCustomReport{
  from_date: string
  to_date: string
  locations: number[]
  model: string
  parameters: string[];
  lang: string;
}
export class PublishAdvanceCustomReport{
  titleEn: string;
  titleAr: string;
  descriptionEn: string;
  descriptionAr: string;
  start_date: string;
  end_date: string;
  data: any[] = [];
  report_type_id: number;
  lang: string = '';
  organizations?: number[];
  channels: string[];
  emails?: string[];
  send_email?: number;
  user_group_ids?: number[];
  report_id?: number;
}

import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { RoundWeatherValuesPipe } from './round-weather-values.pipe';

@Pipe({
  name: 'CurrentParameter'
})
export class CurrentParameterPipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private roundWeatherValue: RoundWeatherValuesPipe){}
  transform(currentTemperature:{date:string;value:number}[] = []): number {
    if(!currentTemperature ||  currentTemperature?.length === 0) return 0;
    let hours = new Date().getHours();
    let minutes = 0;
    let amOrPm = hours >= 12 ? 'PM' : 'AM';

    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours = (hours + 1) % 12;
    }
    hours = (hours % 12) || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');

    let currentDate = `${hours}:${formattedMinutes} ${amOrPm}`;
    let currentTemperatureValue:number;

    for(let i = 0; i < currentTemperature.length; i++){
      const currentTemperatureDate = this.datePipe.transform(currentTemperature[i].date, "h:mm a");
        if(currentTemperatureDate == currentDate){
          currentTemperatureValue  = this.roundWeatherValue.transform(currentTemperature[i].value);
          break;
        }
    }
    return currentTemperatureValue;
  }

}

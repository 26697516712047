import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  constructor() {}
  getCurrentLanguage(){
    return localStorage.getItem('language');
  }
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let currentLanguage = this.getCurrentLanguage();
    if(!currentLanguage){
      currentLanguage = 'ar';
    }
    const langReq = request.clone({
      body: { ...request.body, lang: currentLanguage }
    });
    return next.handle(langReq);
  }
}

import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AlertHistoryComponent } from "./alert-history/alert-history.component";

const routes: Routes = [
  {
    path: "",
    component: AlertHistoryComponent,
  },
  {
    path: "**",
    redirectTo: "home/alert-history",
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AlertHistoryRoutingModule {}

import { Component, ElementRef, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GetListPackageModel, ListApiGroup, ListPackages, ListPackagesModel } from 'src/app/models/package/package.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { CreateUserGroupModelAPI, CreateUserGroupModelDataAPI, ListUserGroupModelAPI } from 'src/app/models/user-groups/user-groups.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { PackageService } from 'src/app/services/package/package.service';
import { UserGroupsService } from 'src/app/services/user-groups/user-groups.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { AddLocationByExcelModalComponent } from './add-location-by-excel-modal/add-location-by-excel-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-group',
  templateUrl: './user-group.component.html',
  styleUrls: ['./user-group.component.scss']
})
export class UserGroupComponent implements OnInit {
  private emailInputChange = new Subject<string>();
  private subscriptions: Subscription = new Subscription();
  language: string;
  userGroupsPage: any;

  isExpanded: boolean[] = [];
  isExpanded2: boolean[] = [];

  isUpsertPackageWindowOpen:boolean = false;
  listPackages:ListPackagesModel;
  apiGroups:ListApiGroup[] = [];
  // editPackage:ListPackagesModel;

  pageSize: number = 10;
  page: number = 1;
  clientsPage: any;
  
  detailpageSize: number = 5;
  detailpage: number = 1;

  isDivVisible: boolean = false;
  deleteData: number;
  filterEmail: string;
  type: string;
  listUserGroups: CreateUserGroupModelAPI[];
  ListPagination: PaginationVariables;

  channelForm: FormGroup
  detailgroup: CreateUserGroupModelAPI = null;
  adduseremail: any;

  ManualGroup: boolean = false;
  groupName = new FormControl(null, {
    validators: Validators.required
  });
  groupType = new FormControl(null, {
    validators: Validators.required
  });

  constructor(
    private userGroupService:UserGroupsService,
    private globalService:GlobalService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private modalService: NgbModal
    ) {
      this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
        this.language = language;
        this.translate.use(language);
        this.translate.get("UserGroups").subscribe((res) => {
          this.userGroupsPage = res;
        });
        this.translate.get("Clients").subscribe((res) => {
          this.clientsPage = res;
        });
      }));
    }

  ngOnInit() {
    this.channelForm = this.fb.group({
      count: this.fb.array([]),
    });
    this.getGroupList();
    // this.getListApiGroup();

    this.emailInputChange.pipe(
      debounceTime(400)
    ).subscribe((filterValue: string) => {
      this.filterEmail = filterValue;
      this.page = 1
      this.getGroupList(this.filterEmail, this.type);
    });
    this.addcount()

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }

  closeAddUserWindow() {
    this.ManualGroup = false;
    this.channelForm.reset()
    this.channelForm = this.fb.group({
      count: this.fb.array([]),
    });
    this.channelForm.enable()
    this.addcount()
    this.groupName.reset()
    this.groupType.reset()
  }
  
  openAddUserWindow() {
    this.ManualGroup = true;
    this.channelForm.reset();
    this.channelForm.enable();
  }

  onAPIfilter(type: string){
    this.type = type;
    this.page = 1;
    this.getGroupList(this.filterEmail, this.type);
  }

  getGroupList(search?: string, type?: string){
    const listUserGroupModelAPI: ListUserGroupModelAPI = new ListUserGroupModelAPI();
    if(search){
      listUserGroupModelAPI.search = search;
    }
    if(type){
      listUserGroupModelAPI.type = type;
    }
    listUserGroupModelAPI.page = this.page;
    this.spinner.show();
    this.userGroupService.listUserGroup(listUserGroupModelAPI)
    .then((res:{success:boolean,data:CreateUserGroupModelAPI[],paginationVariables:PaginationVariables})=>{
      if(res.success){
        this.listUserGroups = res.data;
        this.ListPagination = res.paginationVariables;
      } else {
        this.listUserGroups = [];
        this.ListPagination = null;
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }

  // setEditPackageForm(editPackage:ListPackagesModel){
  //   this.toggleUpsertPackageWindow();
  //   // this.editPackage = editPackage;
  // }

  deletePackage(id:number){
    this.userGroupService.deleteUserGroup(id)
    .then((res:{success:boolean,message:string})=>{
      if(res.success){
        this.toast.success(res.message);
        this.isUpsertPackageWindowOpen = false;
        this.getGroupList();
      }
      else{
        this.toast.error(res.message);
      }
    })
    .catch((err)=>{
      this.globalService.handleError(err);
    })

  }

  toggleUpsertPackageWindow(editgroup?:CreateUserGroupModelAPI){
    const createusergroupmodal = this.modalService.open(AddLocationByExcelModalComponent, {
      backdrop: 'static',
      centered: true,
      animation: true,
      windowClass: 'bulk-locations-modal',
      keyboard: false
    });
    createusergroupmodal.componentInstance.editgroup = editgroup;
    createusergroupmodal.result.then((res: { result: boolean }) => {
      if (res.result) {
        this.getGroupList();
      }
    })
  }

  toggleExpand(index: number) {
    this.isExpanded[index] = !this.isExpanded[index];
  }

  toggleExpand2(index: number) {
    this.isExpanded2[index] = !this.isExpanded2[index];
  }

  onPageChange(pageNumber:number){
    this.page = pageNumber;
    this.getGroupList(this.filterEmail, this.type);
  }

  show(id:number) {
    this.isDivVisible = true;
    this.deleteData = id;
  }

  cancel() {
    this.isDivVisible = false;
  }
  onTypeEmail(event: Event) {
    const input = event.target as HTMLInputElement;
    this.emailInputChange.next(input.value);
  }

  count(): FormArray{
    return this.channelForm.get("count") as FormArray;
  }
  newCount(): FormGroup{
    return this.fb.group({
      firstname: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      lastname: ["", [Validators.required, Validators.pattern(/^[A-Za-z0-9\u0600-\u06FF\s]+$/)]],
      email: ["", [Validators.required, Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,}$/)]]
    });
  }
  addcount(){
    if(this.count().controls.length > 4){
      return;
    }
    this.count().push(this.newCount());
  }
  removeCount(i: number){
    this.count().removeAt(i);
  }

  onSubmit(skip: boolean = false){
    if (this.channelForm.valid) {
      if(skip){
        let result = this.channelForm.controls['count']
        let locationData = []

        for(let i = 0; i < result['controls'].length; i ++){
          locationData.push({
            firstName: result.value[i].firstname,
            lastName: result.value[i].lastname,
            email: result.value[i].email
          })
        }
        
        this.createBulkLocations(locationData)
      } else {
        this.adduseremail.close(this.channelForm.controls['count'])
      }
    } else {
      console.log(this.channelForm);
      const formCount = this.channelForm.controls['count']['controls']
      for (let i = 0; i < formCount.length; i++) {
        if(!formCount[i].valid){
          if(!formCount[i].controls.firstname.value){
            this.toast.error(`${this.userGroupsPage?.Pleaseenterfirstnameinline} ` + (i + 1))
            break
          }
          if(!formCount[i].controls.firstname.valid){
            this.toast.error(`${this.userGroupsPage?.Firstnamecanonlycontainletters} ` + (i + 1))
            break
          }
          if(!formCount[i].controls.lastname.value){
            this.toast.error(`${this.userGroupsPage?.Pleaseenterlastnameinline} ` + (i + 1))
            break
          }
          if(!formCount[i].controls.lastname.valid){
            this.toast.error(`${this.userGroupsPage?.Lastnamecanonlycontainletters} ` + (i + 1))
            break
          }
          if(!formCount[i].controls.email.value){
            this.toast.error(`${this.userGroupsPage?.Pleaseenteremailinline} ` + (i + 1))
            break
          }
          if(!formCount[i].controls.email.valid){
            this.toast.error(`
              ${this.userGroupsPage?.Pleaseenteremailcorrectlyinline} ${i + 1}
              ${this.userGroupsPage?.Exampleformat}
            `)
            break
          }
        }
      }
    }
  }

  createBulkLocations(locationData: CreateUserGroupModelDataAPI[]) {
    const createUserGroupModelAPI: CreateUserGroupModelAPI = new CreateUserGroupModelAPI();
    createUserGroupModelAPI.name = this.groupName.value;
    createUserGroupModelAPI.type = this.groupType.value;
    createUserGroupModelAPI.data = locationData;
    
    this.spinner.show();
    this.userGroupService.createUserGroup(createUserGroupModelAPI)
      .then((res: { success: boolean; message: string }) => {
        if (res.success) {
          this.toast.success(res.message);
          this.closeAddUserWindow();
          this.getGroupList();
        }
        else {
          this.toast.error(res.message);
        }
      }).catch((error: HttpErrorResponse) => {
        if (error.status) {
          this.toast.error(error.error.message);
        }
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
  }

  openEmailModal(content: any, group: CreateUserGroupModelAPI) {
    this.closeAddUserWindow();
    this.adduseremail = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered:true,
      modalDialogClass:'modal-lg',
      backdrop: 'static'
    });
    this.adduseremail.result.then((result: any) => {
      const createUserGroupModelAPI: CreateUserGroupModelAPI = new CreateUserGroupModelAPI();
      createUserGroupModelAPI.id = group?.id;
      createUserGroupModelAPI.name = group?.name;
      createUserGroupModelAPI.type = group?.type;

      let locationData = group?.data

      for(let i = 0; i < result.controls.length; i ++){
        locationData.push({
          firstName: result.value[i].firstname,
          lastName: result.value[i].lastname,
          email: result.value[i].email
        })
      }

      createUserGroupModelAPI.data = locationData;
      this.spinner.show()

      this.userGroupService.updateUserGroup(createUserGroupModelAPI)
      .then((res: { success: boolean; message: string }) => {
        if (res.success) {
          this.toast.success(res.message)
          this.getGroupList()
        }
        else {
          this.toast.error(res.message);
        }
        const channelcount = this.channelForm.controls['count']
        channelcount.value.length = 0
        channelcount['controls'].length = 0
        this.addcount()
      }).catch((error: HttpErrorResponse) => {
        if (error.status) {
          this.toast.error(error.error.message);
        }
        this.globalService.handleError(error);
      })
      .finally(() => {
        this.spinner.hide();
      })
    });
  }

  openDetailModal(content: any, group: CreateUserGroupModelAPI){
    const emaildetails = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      centered:true,
      modalDialogClass:'modal-lg',
      backdrop: 'static'
    });
    this.detailgroup = null
    this.detailgroup = JSON.parse(JSON.stringify(group))
    emaildetails.result.then((result: any) => {
      if(this.detailgroup?.data?.length !== group?.data?.length){
        this.spinner.show()
        this.userGroupService.updateUserGroup(this.detailgroup)
        .then((res: { success: boolean; message: string }) => {
          if (res.success) {
            this.toast.success(res.message)
            this.getGroupList()
          }
          else {
            this.toast.error(res.message);
          }
          this.detailgroup = null
          const channelcount = this.channelForm.controls['count']
          channelcount.value.length = 0
          channelcount['controls'].length = 0
          this.addcount()
        }).catch((error: HttpErrorResponse) => {
          if (error.status) {
            this.toast.error(error.error.message);
          }
          this.globalService.handleError(error);
        })
        .finally(() => {
          this.spinner.hide();
        })
      }
    })
  }

  deleteEntry(index: number){
    this.detailgroup?.data.splice(index, 1)
    this.detailpage = 1
  }
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { GlobalService } from "./shared/utilities/global";
import { NgbModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FullComponent } from "./full/full.component";
import { FeatureModule } from "./features/features.module";
import { AsideComponent } from "./shared/aside/aside.component";
import { HeaderComponent } from "./shared/header/header.component";
import { AuthModule } from "./auth/auth.module";
import { ToastrModule } from "ngx-toastr";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from "@angular/common/http";
import { NgxSpinnerModule } from "ngx-spinner";
import { CommonModule } from "@angular/common";
import { LanguageInterceptor } from "./core/interceptor/language-interceptor.interceptor";
import { ApisService } from "./services/mannedalerts/apis.service";
import { MannedAlertsModel } from "./models/manned-alerts/manned-alerts.model";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgApexchartsModule } from "ng-apexcharts";
import { TokenInterceptor } from './core/interceptor/http.interceptor';
import { PackageService } from './services/package/package.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';
// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, `${environmentBaseUrl.url}/api/ncm/public-portal/translations/`, "");
}

@NgModule({
  declarations: [AppComponent, FullComponent, AsideComponent, HeaderComponent],
  imports: [
    NgxPermissionsModule.forRoot(),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    NgbTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    FeatureModule,
    AuthModule,
    NgxSpinnerModule,
    NgApexchartsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: "toast-bottom-right",
      preventDuplicates: true,
      progressBar: true,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ],
    exports:[NgApexchartsModule],
  providers:
  [
    GlobalService,
    PackageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    ApisService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LanguageInterceptor,
      multi: true
    },
    MannedAlertsModel
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

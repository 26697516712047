import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { UnsavedChangesDialogComponent } from 'src/app/shared/unsaved-changes-dialog/unsaved-changes-dialog.component';
import { RegionComponent } from 'src/app/features/alert-editor/region/region.component';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesGuard implements CanDeactivate<RegionComponent> {

    constructor(private dialog: MatDialog) {}

    canDeactivate(component: RegionComponent): Observable<boolean> | boolean {
      if (component.hasUnsavedChanges()) {
        const dialogRef = this.dialog.open(UnsavedChangesDialogComponent);
        return dialogRef.afterClosed().pipe(
          map((result: 'save' | 'discard' | 'cancel') => {
            if (result === 'save') {
              component.onSave(false, false);
              return true;
            } else if (result === 'discard') {
              return true;
            } else {
              return false;
            }
          })
        );
      } else {
        return true;
      }
    }
}

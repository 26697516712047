import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'GroupKey'
})
export class GroupKeyPipe implements PipeTransform {
  transform(value: any, language?: string): string {

    let lang = localStorage.getItem('language');
    if (language) {
      lang = language;
    }

    if (lang === 'en') {
      switch (value.nameEn) {
        case 'Temperature Minimum':
            return 'Temperature';
        case 'Temperature Maximum':
            return 'Temperature';
        case 'Humidity Min':
            return 'Humidity';
        case 'Humidity Max':
            return 'Humidity';
        case 'First High Tide':
            return 'High Tide';
        case 'Second High Tide':
            return 'High Tide';
        case 'First Low Tide':
            return 'Low Tide';
        case 'Second Low Tide':
            return 'Low Tide';
        default:
            return value.nameEn;
      }
    }
    else {
      switch (value.nameEn) {
        case 'Temperature Minimum':
            return 'درجة حرارة';
        case 'Temperature Maximum':
            return 'درجة حرارة';
        case 'Humidity Min':
            return 'نسبة رطوبة نسبية';
        case 'Humidity Max':
            return 'نسبة رطوبة نسبية';
        case 'First High Tide':
            return 'مرتفع المد';
        case 'Second High Tide':
            return 'مرتفع المد';
        case 'First Low Tide':
            return 'منخفض المد';
        case 'Second Low Tide':
            return 'منخفض المد';
        default:
            return value.nameAr;
      }
    }
  }
}

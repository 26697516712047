<div class="modal-header">
  <h3 class="modalheadtext m-0" id="modal-basic-title">{{ automaticdetailPage?.AddEmailGroups }}</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="modalClose()"></button>
</div>
<div class="modal-body">
  <div class="d-flex align-items-center gap-2 px-3">
    <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"  class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12 w-50" appearance="outline">
      <mat-label>{{ automaticdetailPage?.AddEmailGroups }}</mat-label>
      <mat-select [(ngModel)]="selectedEmailGroups" multiple msInfiniteScroll (infiniteScroll)="getUserGroupsForDropdown()" floatLabel="always">
        <ng-container *ngFor="let group of listUserGroups">
          <mat-option [value]="group?.id">{{ group?.name }}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="previewbtn" (click)="modalSave()">{{ automaticdetailPage?.Save }}</button>
</div>
import { Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
// import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
// import { GlobalService } from "src/app/shared/utilities/global";
// import { ToastrService } from "ngx-toastr";
import { ALERTEDITOR_PAGE_ROUTE, ALERTHISTORY_PAGE_ROUTE, ADD_CLIENTS, ADD_USERS, PROFILE, ALERTDRAFTS_PAGE_ROUTE, MANNED_FORECAST_PAGE_ROUTE, AUTOMATIC_WEATHER_REPORT_PAGE_ROUTE, ARCHIVED_PAGE_ROUTE, PACKAGES } from 'src/app/constants/constants';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { MenuItem, MENU_ITEMS } from './menu-items/menu-items';
import { GlobalService } from '../utilities/global';
import { NgxPermissionsService } from 'ngx-permissions';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  language:string;
  isMobileMenuOpen: boolean = false;
  activeUrl: string;
  searchValue: string;
  user$: Observable<any>;
  user: any;
  archivedpath: any = ARCHIVED_PAGE_ROUTE
  navItems: MenuItem[] =  [];
  // navItems: { name_en: string; name_ar:string; iconName?: string, path?: string }[] = [
  //   { name_en: 'Early Warning System', name_ar: 'نظام الإنذار المبكر', iconName: 'dashboard', path: ALERTHISTORY_PAGE_ROUTE },
  //   { name_en: 'Archived Alerts', name_ar: 'التحذيرات المؤرشفة', iconName: 'dashboard', path: ARCHIVED_PAGE_ROUTE },
  //   { name_en: 'Manned-Forecast', name_ar: 'التوقعات مأهولة', iconName: 'mannedforecast', path: MANNED_FORECAST_PAGE_ROUTE },
  //   { name_en: 'Clients', name_ar:'العملاء', iconName: 'users', path: ADD_CLIENTS },
  //   { name_en: 'Users', name_ar:'المستخدمين', iconName: 'users', path: ADD_USERS },
  //   { name_en: 'Alert Drafts', name_ar: 'مسودة الإنذارات', iconName: 'alert', path: ALERTDRAFTS_PAGE_ROUTE },
  //   { name_en: 'Profile', name_ar:'الملف الشخصي', iconName: 'profile', path: PROFILE },
  //   { name_en: 'Packages', name_ar: 'الحزم', iconName: 'users', path: PACKAGES },
  //   // { name_en: 'Automatic Weather Report', name_ar:'تقرير الطقس التلقائي', iconName: 'automaticweatherreport', path: AUTOMATIC_WEATHER_REPORT_PAGE_ROUTE },
  //   // { name_en: 'Alert Editor', name_ar:'محرر التنبيه', iconName: 'alert', path: ALERTEDITOR_PAGE_ROUTE }
  // ];
  headerPage: any;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private globalService: GlobalService,
    private ngxPermissionsService: NgxPermissionsService,
    private permissionService:PermissionsService
  ) {
    this.getPermissions()
  }

  ngOnInit(): void {
    this.initializeSubscriptions();
    this.getCurrentUrl();
    this.getCurrentLanguage();
  }

  getCurrentUrl(){
    this.activeUrl = this.router.url;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.activeUrl = event.urlAfterRedirects
      }
    });
  }

  getCurrentLanguage(){
    this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Header").subscribe((res) => {
        this.headerPage = res;
      });
    }));
  }

  // initializeMenuItems() {
  //   this.navItems = MENU_ITEMS.map(item => {
  //       return item;
  //   });
  //   this.navItems = this.navItems.filter((item)=> item != null);
  // }

  async loadPermissions(){
    this.permissionService.getPermissionsAndLoadPermissions();
  }

  getPermissions(){
  const permissions$ = this.ngxPermissionsService.permissions$.subscribe((res)=>{
    if (res && Object.keys(res).length > 0) {
      this.initializeMenuItems(res);
      }
      else{
        this.loadPermissions();
      }
    });

    this.subscriptions.add(permissions$);
  }

  initializeMenuItems(permissions) {
    const allPermissions = [...Object.keys(permissions)];
    if (!allPermissions || allPermissions.length === 0) {
        return;
    }
    this.navItems = MENU_ITEMS.map(item => {
        if (item?.permissions && item.permissions.length > 0) {
            if (item.permissions.some(perm => allPermissions.includes(perm))) {
                return item;
            }
        }
        else if (item?.subItems && item.subItems.length > 0) {
            const subItems = item.subItems.filter(subItem => {
                if (subItem?.permissions && subItem.permissions.length > 0) {
                    return subItem.permissions.some(subItemPerm => allPermissions.includes(subItemPerm));
                }
                return true;
            });
            item.subItems = subItems;
            if(item?.subItems?.length > 0){
              return item;
            }
        }
        else {
            return item;
        }
        return null;
    });

    this.navItems = this.navItems.filter((item)=> item != null);
}

  initializeSubscriptions(){
    this.user$ = this.globalService.userInfo$;
  }

  onChangeLanguage(checked: boolean) {
    if (checked) {
      document.body.classList.remove('english-font');
      document.body.classList.add('arabic-font');
      document.body.setAttribute('dir', 'rtl');
    }
    else {
      document.body.classList.remove('arabic-font');
      document.body.classList.add('english-font');
      document.body.setAttribute('dir', 'ltr');
    }
    this.languageService.changeLanguage(checked ? 'ar' : 'en');
  }

  search = (text$: Observable<string>) => {
    return text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) =>
        term.length < 2
          ? []
          : this.navItems.filter((items) => items.name_en.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
          .map((item) => item.name_en)
          .slice(0, 10)
      )
    );
  };

  toggleMobileMenu() {
    const mobileMenu: HTMLElement = document.getElementById('mobile-menu');
    if (!this.isMobileMenuOpen) {
      this.isMobileMenuOpen = true;
      mobileMenu.style.transform = 'translate3d(0px, 0px, 0px)';
    }
    else {
      this.isMobileMenuOpen = false;
      mobileMenu.style.transform = 'translate3d(-100%, 0px, 0px)';
    }
  }

  navigate(navItem) {
    this.spinner.show();
    this.navItems.forEach((item) => {
      if (item.path === navItem.path) {
        if(item.name_en == 'Archived Alerts'){
          const navigationExtras: NavigationExtras = {
            queryParams: {
              archived: true
            }
          };
          this.router.navigate([item.path], navigationExtras);
        }
        else{
          this.router.navigate([item.path]);
        }
      }
      this.spinner.hide();
      return;
    });
  }

  logout() {
    this.globalService.logOut();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

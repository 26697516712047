import { Routes, RouterModule } from '@angular/router';
import { AddUsersComponent } from './add-users.component';

const routes: Routes = [
  {
    path: '',
    component: AddUsersComponent,
  },
];

export const AddUsersRoutes = RouterModule.forChild(routes);
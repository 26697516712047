import { AlertHistoryFilterModel } from './../../models/alert-history-filter/alert-history.filter.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalService } from 'src/app/shared/utilities/global';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: 'root'
})
export class AlertHistoryService {


  private baseUrl:string;
  constructor(private http: HttpClient, private globalService: GlobalService) {
    this.baseUrl = `${environmentBaseUrl.url}/api/ncm/`;
   }


   getAlertHistory(alertHistoryFilterModel: AlertHistoryFilterModel){
    const url = `${this.baseUrl}alert_history`
    return this.http.post(url, alertHistoryFilterModel).toPromise();
   }
}

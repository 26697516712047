import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { RoundWeatherValuesPipe } from './round-weather-values.pipe';

@Pipe({
  name: 'CurrentTime'
})
export class CurrentTimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private roundWeatherValue: RoundWeatherValuesPipe){}
  transform(forecastData:{date:string;value:number}[] = []) {
    if(!forecastData ||  forecastData?.length === 0) return 0;
    let hours = new Date().getHours();
    let minutes = new Date().getMinutes();
    let amOrPm = hours >= 12 ? 'PM' : 'AM';

    minutes = Math.ceil(minutes / 10) * 10;
    if (minutes === 60) {
        minutes = 0;
        hours = (hours + 1) % 12;
    }
    hours = (hours % 12) || 12;
    let currentDate = `${hours}:00 ${amOrPm}`;
    const fromIndex = forecastData.findIndex((data)=> this.datePipe.transform(data.date,'h:mm a') === currentDate)
    forecastData = forecastData.slice(fromIndex,forecastData.length -1);
    forecastData = forecastData.slice(0,24);
    return forecastData;
  }

}

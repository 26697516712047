import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-button",
  templateUrl: "./button.component.html",
  styleUrls: ["./button.component.scss"],
})
export class ButtonComponent implements OnInit {
  @Input() title = "";
  @Input() img = "";
  @Input() link = "";
  @Input() btnclass = "";
  @Input() icls = "";
  @Input() imgcls = "";
  @Output() emitBtnClick = new EventEmitter();
  constructor() {
  }
  ngOnInit(): void {
  }
  BtnClick(event) {
    this.emitBtnClick.emit(event);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arabicDate'
})
export class ArabicDatePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {

    return `${value.split(" ")[0]} ${value.split(" ")[1]}`;

  }

}

import { Observable } from 'rxjs';
import { GlobalService } from "./../../shared/utilities/global";
import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { LocationFilterModel } from 'src/app/models/location-filter/location-filter.model';
import { environmentBaseUrl } from 'src/environments/environmentBaseUrl';

@Injectable({
  providedIn: "root",
})
export class LocationsService {
  baseUrl: string;
  constructor(private globalService: GlobalService, private http: HttpClient) {
    this.baseUrl = `${environmentBaseUrl.url}/api/ncm/`;
  }

  addLocation(location: {
    name: string;
    coordinates: string[];
    title: string;
    address_components: any[];
    severeWeatherAlert: boolean;
  }) {
    const url = `${this.baseUrl}create_location`;
    return this.globalService.PostApi(
      url,
      {
        name: location.name,
        coordinates: location.coordinates,
        title: location.title,
        Authorization: this.globalService.getToken(),
        address_components: location.address_components,
        severeWeatherAlert: location.severeWeatherAlert,
      },
      false
    );
  }

  updateLocation(location: {
    id: number;
    title: string;
    severeWeatherAlert: boolean;
  }) {
    const url = `${this.baseUrl}edit_location`;
    return this.globalService.PostApi(
      url,
      {
        id: location.id,
        title: location.title,
        Authorization: this.globalService.getToken(),
        severeWeatherAlert: location.severeWeatherAlert,
      },
      false
    );
  }

  getLocations(locationFilterModel?: LocationFilterModel) {
    const url = `${this.baseUrl}location/list-location`;
    return this.globalService.PostApi(
      url,
      locationFilterModel ? locationFilterModel : { Authorization : this.globalService.getToken()},
      false
    );
  }
  getLocationById(locationId: number) {
    const url = `${this.baseUrl}view_location`;
    return this.globalService.PostApi(
      url,
      { id: locationId, Authorization: this.globalService.getToken() },
      false
    );
  }



  deleteLocation(locationId: number) {
    const url = `${this.baseUrl}delete_location`;
    return this.globalService.PostApi(
      url,
      { id: locationId, Authorization: this.globalService.getToken() },
      false
    );
  }
  getWeatherMap(url) {
    return this.globalService.GetWeatherApi(
      url
    );
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'src/app/auth/service/authentication.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { GlobalService } from 'src/app/shared/utilities/global';

@Injectable({
  providedIn: 'root',
})
export class PermissionsGuard implements CanActivate {
  permission: any;
  constructor(
    private permissionService: PermissionsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const routeRoles = next.data['permissions']['only'] as Array<string>;
    const redirectTo = next.data['redirectTo'];

    return this.getRoles(routeRoles, redirectTo);
  }

  async getRoles(routeRoles: string[], redirectTo: string): Promise<boolean> {
    let userRoles = await this.permissionService.getPermissions();
    return this.isInRole(userRoles, routeRoles, redirectTo);
  }

  isInRole(userRoles: string[], routeRoles: string[], redirectTo: string): boolean {
    let canAccessRoute = routeRoles.some((rR) => userRoles.includes(rR));
    if (!canAccessRoute) {
      this.authenticationService.getUserPermissions().then((data: any) => {
        this.permission = data
        if(this.permission){
          this.globalService.setPermissions(this.permission)
          this.determineRedirectPath(this.permission)
        }
      })
    }

    return canAccessRoute;
  }

  determineRedirectPath(permission: any) {
    if (permission?.grants?.create_alert) {
      this.router.navigateByUrl("home/alert-history");
    } else if (permission?.grants?.invite_user) {
      this.router.navigateByUrl("home/addclient");
    } else if (permission?.grants?.invite_ncm_user) {
      this.router.navigateByUrl("home/addusers");
    } else if (permission?.grants?.create_package) {
      this.router.navigateByUrl("home/packages");
    } else if (permission?.grants?.generate_report) {
      this.router.navigateByUrl("home/manned-forecast");
    } else {
      this.router.navigateByUrl("home/profile");
    }
  }
}

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'windDirectionCriteria'
})
export class WindDirectionCriteriaPipe implements PipeTransform {

  transform(value: number | string, language?:string): string {
    let lang = localStorage.getItem('language');
    if(language){
        lang = language;
    }

    if(typeof value === 'string' && value === 'N/A'){
      return value;
    }

    if(lang === 'en'){
      if(typeof value === 'string'){
        value = parseFloat(value)
      }

      if (value <= 22.5) {
          return "N";
      }
      else if (value <= 67.5) {
          return "NW";
      }
      else if (value <= 112.5) {
          return "W";
      }
      else if (value <= 157.5) {
          return "SW";
      }
      else if (value <= 202.5) {
          return "S";
      }
      else if (value <= 247.5) {
          return "SE";
      }
      else if (value <= 292.5) {
          return "E";
      }
      else if (value <= 337.5) {
          return "NE";
      }
      else if(value === -999){
        return 'N/A';
      }
      else{
        return "N";
      }
    }
    else{
      if(typeof value === 'string'){
        value = parseFloat(value)
      }

      if (value <= 22.5) {
          return "شمالية";
      }
      else if (value <= 67.5) {
          return "شمالية غربية";
      }
      else if (value <= 112.5) {
          return "غربية";
      }
      else if (value <= 157.5) {
          return "جنوبية غربية";
      }
      else if (value <= 202.5) {
          return "جنوبية";
      }
      else if (value <= 247.5) {
          return "جنوبية شرقية ";
      }
      else if (value <= 292.5) {
          return "شرقية";
      }
      else if (value <= 337.5) {
          return "شمالية شرقية";
      }
      else if(value === -999){
        return "غير متاح";
      }
      else{
        return "شمالية";
      }
    }


}

}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'weatherDescriptionDetailed'
})
export class WeatherDescriptionDetailedPipe implements PipeTransform {
  transform(value: number): string {
    switch (value) {
      case 0:
        return "The weather code could not be determined due to insufficient data or an error in weather information retrieval.";
      case 1:
      case 101:
        return "The weather is characterized by a clear sky, where no significant clouds are visible. The sun shines brightly, and the sky is a vibrant blue.";
      case 2:
      case 102:
        return "The current weather features light cloud cover. While the sun is still visible, it is partially obscured by thin clouds, creating a hazy but pleasant atmosphere.";
      case 3:
      case 103:
        return "The sky appears partly cloudy, presenting a delightful blend of both clouds and clear sky. The sun intermittently peeks through fluffy clouds, offering a dynamic visual experience.";
      case 4:
      case 104:
        return "The weather is cloudy, with an overcast sky. Thick clouds dominate the atmosphere, blocking out direct sunlight and casting a gray and gloomy ambiance.";
      case 5:
      case 105:
        return "The current weather is characterized by rainfall. Raindrops are falling from the sky, creating a soothing pitter-patter sound as they hit the ground.";
      case 6:
      case 106:
        return "The weather conditions include both rain and snow, often referred to as sleet. Precipitation consists of a mixture of raindrops and snowflakes, resulting in varying levels of wetness and slushiness.";
      case 7:
      case 107:
        return "Snow is falling from the sky, covering the landscape in a soft, white blanket. The world is transformed into a winter wonderland as snowflakes gently descend.";
      case 8:
      case 108:
        return "The weather features rain showers, brief periods of heavy rain. Rain showers typically bring temporary, intense downpours that can quickly soak the surroundings.";
      case 9:
      case 109:
        return "Snow showers are occurring, with brief and intermittent bursts of snowfall. These showers can add fresh layers of snow to the existing landscape.";
      case 10:
      case 110:
        return "Sleet showers are happening, combining elements of rain and snow. The precipitation is a mixture of icy raindrops and snowflakes.";
      case 11:
      case 111:
        return "The weather is marked by light fog, which slightly reduces visibility. The atmosphere is hazier, and distant objects may appear more obscure.";
      case 12:
      case 112:
        return "Dense fog has settled in, significantly reducing visibility. The surroundings are shrouded in a thick mist, making it challenging to see beyond a short distance.";
      case 13:
      case 113:
        return "Freezing rain is falling, creating hazardous conditions. This type of precipitation occurs when raindrops freeze upon contact with cold surfaces, leading to icy and slippery conditions.";
      case 14:
      case 114:
        return "Thunderstorms are in progress, featuring lightning, thunder, heavy rainfall, and strong winds. These intense weather events can be accompanied by dramatic displays of nature's power.";
      case 15:
      case 115:
        return "Drizzle is occurring, with fine and light raindrops falling gently. While it may not lead to heavy precipitation, drizzle can create a persistent dampness in the environment.";
      case 16:
      case 116:
        return "A sandstorm is in effect, with strong winds blowing sand particles across the landscape. Visibility is severely reduced, and protective measures should be taken to shield against blowing sand.";
      default:
        return "Unknown weather description";
    }
  }
}

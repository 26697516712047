<div #content dir="rtl">
  <div class="d-flex justify-content-center align-items-center">
    <div id="x-content-post" class="x-bg d-flex flex-column" [style.background-image]="getBackgroundImageUrl()">
      <div class="d-flex flex-column alertActionDiv" [ngClass]="notificationList?.alertTypeEn === 'orange' ? 'action-orange' : 'action-red_yellow'">
        <ng-container *ngFor="let alert of notificationList?.alertActions">
          <h4 class="alertActionText">
            {{alert?.descriptionAr}}
          </h4>
        </ng-container>
      </div>
      <div class="d-flex flex-column reg-gov-div">
        <h4 class="region">
          منطقة
        </h4>
        <h4 class="regionName">
          {{ notificationList?.regions?.name_ar ? notificationList?.regions?.name_ar.replace('منطقة', '').trim() : '' }}
        </h4>
        <h4 class="govNames mt-2">
          <ng-container *ngFor="let gov of notificationList?.governorates; let i=index; let last=last">
              {{gov?.nameAr}}<span *ngIf="!last"> - </span>
          </ng-container>
        </h4>
      </div>
      <div class="alertStatusImg-div">
        <img [src]="'/assets/x-preview/'+fetchimage(notificationList?.alertStatusEn)+'.webp'" alt="">
      </div>
      <div class="d-flex flex-column date-div startdate">
        <h4>{{notificationList?.startDate | date : 'EEEE YYYY.M.d' : '' : 'ar'}}</h4>
        <h4>الساعة {{notificationList?.startTime.split(':')[0]}}:{{notificationList?.startTime.split(':')[1]}}</h4>
      </div>
      <div class="d-flex flex-column date-div enddate">
        <h4>{{notificationList?.endDate | date : 'EEEE YYYY.M.d' : '' : 'ar'}}</h4>
        <h4>الساعة {{notificationList?.endTime.split(':')[0]}}:{{notificationList?.endTime.split(':')[1]}}</h4>
      </div>
      <div class="d-flex flex-column align-items-center endtxt">
        <h4 class="endtxt-first">يُتوقع بمشيئة االله تعالى..</h4>
        <h4 class="endtxt-second">استمرار الحالة {{notificationList?.alertStatusAr}}</h4>
        <h4 class="endtxt-third">التأثيرات المصاحبة : 
          <ng-container *ngFor="let alertH of notificationList?.alertHazard; let i=index; let last=last">
            {{alertH?.nameAr}}<span *ngIf="!last"> - </span>
          </ng-container>
        </h4>
      </div>
      <div class="d-flex align-items-center justify-content-center endtxts">
        <h4 class="endtxt-fourth" *ngIf="isUpdate">آخر تحديث : {{today | date : 'YYYY/MM/dd - HH:mm'}}</h4>
      </div>
    </div>
  </div>
</div>
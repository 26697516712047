import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'VisibilityCriteria'
})
export class VisibilityCriteriaPipe implements PipeTransform {

  transform(value: number): string {
    if (value > 10) {
        return "> 10";
    }
    else if (value <= 10) {
        return `${value}`;
    }
    else{
      return `${value}`;
    }
}

}

import { Injectable } from "@angular/core";
import { NgxSpinnerService } from "ngx-spinner";
import { BehaviorSubject, Observable } from "rxjs";
import { Router, NavigationEnd } from '@angular/router';
import { TITLE_TRANSLATIONS } from "src/app/constants/constants";

@Injectable({
  providedIn: "root",
})
export class LanguageService {
  private languageSource: BehaviorSubject<string>;
  currentLanguage: Observable<string>;

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router
  ) {

    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      this.languageSource = new BehaviorSubject(storedLanguage);
    } else {
      this.languageSource = new BehaviorSubject("ar");
      localStorage.setItem("language", "ar");
    }
    this.currentLanguage = this.languageSource.asObservable();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updatePageTitle();
      }
    });
  }

  private updatePageTitle() {
    const currentLanguage = this.languageSource.getValue();
    const currentUrl = this.router.url;
    let title: string;
  
    if(currentUrl.includes('/home/alert-editor/add-region')){
      title = TITLE_TRANSLATIONS['/home/alert-editor/add-region']?.[currentLanguage] || TITLE_TRANSLATIONS['default']?.[currentLanguage];
    } else if(currentUrl.includes('/home/automatic-weather-report/automatic-weather-report-details')) {
      title = TITLE_TRANSLATIONS['/home/automatic-weather-report/automatic-weather-report-details']?.[currentLanguage] || TITLE_TRANSLATIONS['default']?.[currentLanguage];
    } else if(currentUrl.includes('/home/automatic-weather-report/automatic-weather-report-preview') || currentUrl.includes('/home/manned-forecast/manned-forecast-preview')) {
      title = TITLE_TRANSLATIONS['/home/automatic-weather-report/automatic-weather-report-preview']?.[currentLanguage] || TITLE_TRANSLATIONS['default']?.[currentLanguage];
    } else {
      title = TITLE_TRANSLATIONS[currentUrl]?.[currentLanguage] || TITLE_TRANSLATIONS['default']?.[currentLanguage];
    }
    
    document.title = title;
  }

  changeLanguage(language: string) {
    this.spinner.show();
    this.languageSource.next(language);
    localStorage.setItem("language", language);
    this.updatePageTitle();
    setTimeout(() => {
      this.spinner.hide();
    }, 500);
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slipperyCriteria'
})
export class SlipperyCriteriaPipe implements PipeTransform {

  transform(value: number, lang:string): string {
    if(lang == 'en'){
      if (value == 0) {
        return "Not Slippery";
    }
    else if (value == 1) {
        return "Slippery";
    }
    else{
      return "Not Slippery";
    }
    }
    if(lang == 'ar'){
      if (value == 0) {
        return "ليس زلقا";
    }
    else if (value == 1) {
        return "زلق";
    }
    else{
      return "ليس زلقا";
    }
    }
    else{
      if (value == 0) {
        return "Not Slippery";
    }
    else if (value == 1) {
        return "Slippery";
    }
    else{
      return "Not Slippery";
    }
    }
}

}

import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Router } from 'express-serve-static-core';
import { ToastrService } from 'ngx-toastr';
import { LoginModel } from 'src/app/models/login/login.model';
import { LanguageService } from 'src/app/services/language/language.service';
import { GlobalService } from 'src/app/shared/utilities/global';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit, OnDestroy {
  login: any;
  forgotPassword: any;
  loginForm: FormGroup;
  isChecked: boolean;
  language: string;
  submitted: boolean = false;
  currentlanguage: string;
  forgetEmailForm: FormGroup;
  isLanguageChecked: boolean = true;
  private subscription:Subscription = new Subscription();
  constructor(
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,
    private globalService: GlobalService
  ) {
    this.currentlanguage = localStorage.getItem("language");
    if (this.currentlanguage) {
      if (this.currentlanguage === "en") {
        this.isChecked = false;
      } else {
        this.isChecked = true;
      }
    } else {
      this.isChecked = false;
    }
    this.getCurrentLanguage()
  }

  ngOnInit() {
    this.forgetEmailForm = this.formBuilder.group({
      email: [
        null,
      ],
    });
  }

  // get forgetEmailFormControls() {
  //   return this.forgetEmailForm.controls;
  // }

  getCurrentLanguage(){
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.subscription.add(this.translate.get("ForgotPassword").subscribe((res) => {
        this.forgotPassword = res;
      }));
    }));
    if(this.language === 'en'){
      this.isLanguageChecked = false;
    }
    else{
      this.isLanguageChecked = true;
    }
  }

  onChangeLanguage(checked: boolean) {
    if (checked) {
      this.languageService.changeLanguage("ar");
      document.body.classList.remove("english-font");
      document.body.classList.add("arabic-font");
      document.body.setAttribute("dir", "rtl");
    } else {
      this.languageService.changeLanguage("en");
      document.body.classList.remove("arabic-font");
      document.body.classList.add("english-font");
      document.body.setAttribute("dir", "ltr");
    }
  }

  onSubmit() {
    this.submitted = true;
    if (!this.forgetEmailForm.controls?.['email']?.value) {
      this.toast.error(this.forgotPassword?.emailRequired);
      return;
    }
    if (!this.forgetEmailForm.controls?.['email'].value.includes('@')) {
      this.toast.error(this.forgotPassword?.emailIncorrect);
      return;
    }
    this.globalService.showLoader();
    const email = this.forgetEmailForm.controls?.['email']?.value.toLowerCase();
    this.authenticationService.forgotPassword(email).then(
      (res: any) => {
        this.globalService.hideLoader()
        if(res?.success){
          this.toast.success(res.message);
        }
        else{
          this.toast.error(res.message);
        }
      },
    ).catch((error: HttpErrorResponse)=>{
      this.globalService.hideLoader()
      console.log(error);
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}

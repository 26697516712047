<div>
  <div class="modal-header mx-3">
    <h3 id="exampleModalLabel" class="modalheadtext m-0">{{ automaticpreviewPage?.EditWeatherParameters }}</h3>
    <!-- <input class="searchcityinput" [placeholder]="automaticpreviewPage?.Searchbycity"> -->
    <!-- <h5 class="modal-title" >Modal title</h5> -->
    <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].tmax]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #tempMaxId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].tmax]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.TemperatureMaximum }}</label>
          <span class="myspan" (click)="tempMaxId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].tmin]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #tempMinId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].tmin]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.TemperatureMinimum }}</label>
          <span class="myspan" (click)="tempMinId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].windspeed]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #windSpeedDayId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].windspeed]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.WindSpeedDay }}</label>
          <span class="myspan" (click)="windSpeedDayId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].windspeed]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #windSpeedNightId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].windspeed]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.WindSpeedNight }}</label>
          <span class="myspan" (click)="windSpeedNightId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].winddirection]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
            <mat-label>{{ automaticpreviewPage?.WindDirectionDay }}</mat-label>
            <mat-select [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].winddirection]?.[0].value">
              <mat-option *ngFor="let option of windDirectionOptions" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].winddirection]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="custom-dropdown col" appearance="outline">
            <mat-label>{{ automaticpreviewPage?.WindDirectionNight }}</mat-label>
            <mat-select [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].winddirection]?.[1].value">
              <mat-option *ngFor="let option of windDirectionOptions" [value]="option.value">
                {{ option.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].waveheight]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #waveHeightDayId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].waveheight]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.WaveHeightDay }}</label>
          <span class="myspan" (click)="waveHeightDayId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].waveheight]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #waveHeightNightId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].waveheight]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.WaveHeightNight }}</label>
          <span class="myspan" (click)="waveHeightNightId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].weathersymbol]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <!-- <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #weatherConditionDayId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.WeatherConditionDay }}</label>
          <span class="myspan" (click)="weatherConditionDayId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span> -->
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{ automaticpreviewPage?.WeatherConditionDay }}</mat-label>
          <mat-select [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[0].value">
            <ng-container *ngFor="let symbol of filtersymbols">
              <mat-option [value]="+symbol?.iconValue">
                {{language === 'en' ? symbol?.nameEn : symbol?.nameAr}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].weathersymbol]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <!-- <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #weatherConditionNightId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.WeatherConditionNight }}</label>
          <span class="myspan" (click)="weatherConditionNightId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span> -->
          <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
          class="custom-dropdown col" appearance="outline">
          <mat-label>{{ automaticpreviewPage?.WeatherConditionNight }} </mat-label>
          <mat-select [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].weathersymbol]?.[1].value">
            <ng-container *ngFor="let symbol of filtersymbolsNight">
              <mat-option [value]="+symbol?.iconValue">
                {{language === 'en' ? symbol?.nameEn : symbol?.nameAr}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].visibility]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #visibilityDayId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].visibility]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.HorizontalVisbilityDay }}</label>
          <span class="myspan" (click)="visibilityDayId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].visibility]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #visibilityNightId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].visibility]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.HorizontalVisbilityNight }}</label>
          <span class="myspan" (click)="visibilityNightId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].firsthightide]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #highTideFirstId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].firsthightide]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.HighTideFirst }}</label>
          <span class="myspan" (click)="highTideFirstId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].secondhightide]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #highTideSecondId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].secondhightide]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.HighTideSecond }}</label>
          <span class="myspan" (click)="highTideSecondId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].firstlowtide]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #lowTideFirstId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].firstlowtide]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.LowTideFirst }}</label>
          <span class="myspan" (click)="lowTideFirstId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].secondlowtide]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #lowTideSecondId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].secondlowtide]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.LowTideSecond }}</label>
          <span class="myspan" (click)="lowTideSecondId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].probability]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #Probability12hh [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].probability]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Probability }} - {{automaticWeather?.Day }}</label>
          <span class="myspan" (click)="Probability12hh.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].probability]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #Probability12hhh [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].probability]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Probability }} - {{automaticWeather?.Night }}</label>
          <span class="myspan" (click)="Probability12hhh.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].slipperyroad]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #SlipperyRoad12h [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].slipperyroad]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.SlipperyRoad }} - {{automaticWeather?.Day }}</label>
          <span class="myspan" (click)="SlipperyRoad12h.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].slipperyroad]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #SlipperyRoad12hh [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].slipperyroad]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.SlipperyRoad }} - {{automaticWeather?.Night }}</label>
          <span class="myspan" (click)="SlipperyRoad12hh.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].precipitation]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #precip12 [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].precipitation]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Precipitation }} - {{automaticWeather?.Day }}</label>
          <span class="myspan" (click)="precip12.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].precipitation]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #precip12day [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].precipitation]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Precipitation }} - {{automaticWeather?.Night }}</label>
          <span class="myspan" (click)="precip12day.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].humidity]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #humidityDayId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].humidity]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Humidity }} - {{automaticWeather?.Day }}</label>
          <span class="myspan" (click)="humidityDayId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].humidity]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #humidityNightId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].humidity]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Humidity }} - {{automaticWeather?.Night }}</label>
          <span class="myspan" (click)="humidityNightId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].pressure]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #pressureDayId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].pressure]?.[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Pressure }} - {{automaticWeather?.Day }}</label>
          <span class="myspan" (click)="pressureDayId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData?.parameters[reportTypeMapping[reporttypekey].pressure]">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #pressureNightId [(ngModel)]="previewData?.parameters[reportTypeMapping[reporttypekey].pressure]?.[1].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Pressure }} - {{automaticWeather?.Night }}</label>
          <span class="myspan" (click)="pressureNightId.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div>
      <!-- <div class="col-lg-4 col-md-6 col-sm-6 col-12" *ngIf="previewData[firstIndex]?.parameters?.['Precipitation']?.dates[0]?.value != null">
        <div class="input-field d-flex align-items-center gap-2 mb-3">
          <input [ngClass]="language == 'en' ? 'inputclass' : 'inputclass-ar'" type="text" #preci [(ngModel)]="previewData[firstIndex]?.parameters?.['Precipitation']?.dates[0].value">
          <label [ngClass]="language == 'en' ? 'input-field-label' : 'input-field-label-ar'">{{ automaticpreviewPage?.Precipitation }}</label>
          <span class="myspan" (click)="preci.focus()"><img src="../../../../assets/mannedforecast/editmodal-icon.png"></span>
        </div>
      </div> -->



  <div class="modal-footer">
    <!-- <button (click)="onClose()" data-bs-dismiss="modal" type="button" class="canceltxt">
      {{ automaticpreviewPage?.Cancel }}
    </button> -->
    <button (click)="onClose()" data-bs-dismiss="modal" type="button" class="publishbtn">
      {{ automaticpreviewPage?.Close }}
    </button>
    <button (click)="onSave()" type="button" class="previewbtn">
      {{ automaticpreviewPage?.Save }}
    </button>
    <!-- <button type="button" class="btn btn-secondary" >Close</button>
    <button type="button" class="btn btn-primary">Save changes</button> -->
  </div>
</div>

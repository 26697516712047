import { Routes, RouterModule } from '@angular/router';
import { AddClientComponent } from './add-client.component';

const routes: Routes = [
  {
    path: '',
    component: AddClientComponent,
  },
];

export const AddClientRoutes = RouterModule.forChild(routes);
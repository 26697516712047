import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string): string {
    // Split the date and time parts and return only the date and time
    const parts = value.split(' ');
    return parts[0] + ' ' + parts[1];
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgbDatepicker, NgbDatepickerModule, NgbModalModule, NgbModule, NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipModule } from 'primeng/chip';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { ArabicDatePipe } from '../pipes/arabic-date.pipe';
import { DateDifferencePipe } from '../pipes/Date-difference/date-difference.pipe';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { LeafletDrawModule } from '@asymmetrik/ngx-leaflet-draw';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { ToolbarModule } from 'primeng/toolbar';
import { ColorConversionPipe } from '../pipes/color-conversion.pipe';
import { ColorFormatPipe } from '../pipes/color-format.pipe';
import { CurrentParameterPipe } from '../pipes/current-parameter.pipe';
import { CurrentTemperaturePipe } from '../pipes/current-temperature.pipe';
import { CurrentTimePipe } from '../pipes/current-time.pipe';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { RoundPrecipitationValuePipe } from '../pipes/round-precipitation-value.pipe';
import { RoundWeatherValuesPipe } from '../pipes/round-weather-values.pipe';
import { RoundWindDirectionValuePipe } from '../pipes/round-wind-direction-value.pipe';
import { SlipperyCriteriaPipe } from '../pipes/slippery-criteria.pipe';
import { TimeAdjustmentPipe } from '../pipes/time-adjustment.pipe';
import { WeatherDescriptionDetailedPipe } from '../pipes/weather-description-detailed.pipe';
import { WeatherDescriptionDetailedPipeAR } from '../pipes/weather-description-detailedAR.pipe';
import { WeatherDescriptionPipe } from '../pipes/weather-description.pipe';
import { WeatherDescriptionPipeAR } from '../pipes/weather-descriptionAR.pipe';
import { WindDirectionCriteriaPipe } from '../pipes/wind-direction-criteria.pipe';
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { ParameterNamesPipe } from '../pipes/parameter-names.pipe';
import { GroupKeyPipe } from '../pipes/group-key-name.pipe';
import { HighLowTideCriteriaPipe } from '../pipes/highlowtide-criteria.pipe';
import { VisibilityCriteriaPipe } from '../pipes/visibility-criteria.pipe';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { EmailGroupsComponent } from './email-groups/email-groups.component';
import { SelectAllDirective } from './directives/selectAll.directive';
import { MatSelectFilterDirective } from './directives/MatSelectFilterDirective';
import { UnsavedChangesDialogComponent } from './unsaved-changes-dialog/unsaved-changes-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [
    DateDifferencePipe,
    ArabicDatePipe,
    ColorConversionPipe,
    ColorFormatPipe,
    CurrentParameterPipe,
    CurrentTemperaturePipe,
    CurrentTimePipe,
    DateFormatPipe,
    RoundPrecipitationValuePipe,
    RoundWeatherValuesPipe,
    RoundWindDirectionValuePipe,
    SlipperyCriteriaPipe,
    TimeAdjustmentPipe,
    WeatherDescriptionDetailedPipe,
    WeatherDescriptionDetailedPipeAR,
    WeatherDescriptionPipe,
    WeatherDescriptionPipeAR,
    WindDirectionCriteriaPipe,
    ParameterNamesPipe,
    GroupKeyPipe,
    HighLowTideCriteriaPipe,
    PrivacyPolicyModalComponent,
    VisibilityCriteriaPipe,
    EmailGroupsComponent,
    UnsavedChangesDialogComponent
  ],
  imports: [
    MatDialogModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    CheckboxModule,
    NgbPaginationModule,
    CalendarModule,
    ChipModule,
    NgxMaterialTimepickerModule,
    LeafletModule,
    LeafletDrawModule,
    GooglePlaceModule,
    ToastrModule,
    NgSelectModule,
    NgxSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToolbarModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbModalModule,
    MatSelectInfiniteScrollModule,
    SelectAllDirective,
    MatSelectFilterDirective,
    NgbTooltipModule,
    NgbModule,
  ],
  exports: [
    NgbModule,
    MatSelectFilterDirective,
    SelectAllDirective,
    EmailGroupsComponent,
    MatSelectInfiniteScrollModule,
    GroupKeyPipe,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgbModalModule,
    PrivacyPolicyModalComponent,
    DateDifferencePipe,
    ArabicDatePipe,
    ColorConversionPipe,
    ColorFormatPipe,
    CurrentParameterPipe,
    CurrentTemperaturePipe,
    CurrentTimePipe,
    DateFormatPipe,
    RoundPrecipitationValuePipe,
    RoundWeatherValuesPipe,
    RoundWindDirectionValuePipe,
    SlipperyCriteriaPipe,
    TimeAdjustmentPipe,
    WeatherDescriptionDetailedPipe,
    WeatherDescriptionDetailedPipeAR,
    WeatherDescriptionPipe,
    WeatherDescriptionPipeAR,
    WindDirectionCriteriaPipe,
    ParameterNamesPipe,
    HighLowTideCriteriaPipe,
    VisibilityCriteriaPipe,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    CheckboxModule,
    NgbPaginationModule,
    CalendarModule,
    ChipModule,
    NgxMaterialTimepickerModule,
    LeafletModule,
    LeafletDrawModule,
    GooglePlaceModule,
    ToastrModule,
    NgSelectModule,
    NgxSpinnerModule,
    MatInputModule,
    MatIconModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ToolbarModule,
  ],
  providers: [
    SelectAllDirective,
    DateDifferencePipe,
    ArabicDatePipe,
    ColorConversionPipe,
    ColorFormatPipe,
    CurrentParameterPipe,
    CurrentTemperaturePipe,
    CurrentTimePipe,
    DateFormatPipe,
    HighLowTideCriteriaPipe,
    RoundPrecipitationValuePipe,
    RoundWeatherValuesPipe,
    RoundWindDirectionValuePipe,
    SlipperyCriteriaPipe,
    TimeAdjustmentPipe,
    WeatherDescriptionDetailedPipe,
    WeatherDescriptionDetailedPipeAR,
    WeatherDescriptionPipe,
    WeatherDescriptionPipeAR,
    WindDirectionCriteriaPipe,
    VisibilityCriteriaPipe
  ]
})
export class SharedModule { }

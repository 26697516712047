import { LanguageService } from './../../../services/language/language.service';
import { Component, OnInit, inject } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EWSNotificationModel, ListEwsNotificationDataModel } from 'src/app/models/EWSnotification/EWSnotification.model';
import { EWSNotificationListModel, notificationListDataModel } from 'src/app/models/alert-history-filter/alert-history.filter.model';
import { PaginationVariables } from 'src/app/models/pagination/pagination.model';
import { AlertService } from 'src/app/services/alert-service/alert-service.service';

export class notificationListModel {
  data:notificationListDataModel[];
  paginationVariables:PaginationVariables;
  success:boolean;
}
@Component({
  selector: 'app-draft',
  templateUrl: './draft.component.html',
  styleUrls: ['./draft.component.scss']
})
export class DraftComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();
  language:string;
  notificationList:notificationListModel = new notificationListModel();
  draftPage: any;

  pageSize: number = 10;
  page: number = 1;

  isPublish: boolean = false;
  publishId: number;

  calendar = inject(NgbCalendar);
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  isExpanded: boolean[] = [];
  isView: boolean = false;
  constructor(
    private alertService: AlertService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private languageService: LanguageService,
    private translate: TranslateService
    ) {
      this.subscriptions.add(this.languageService.currentLanguage.subscribe((language: string) => {
        this.language = language;
        this.translate.use(language);
        this.translate.get("Dashboard").subscribe((res) => {
          this.draftPage = res;
        });
      }));
     }

  ngOnInit(): void {
    this.getUnPublishedNotification()

    // setTimeout(() => {
    //   const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    //   if (isSafari) {
    //     // Array of table header selectors
    //     const selectors = [
    //       '.table-container table thead',
    //       '.p-datatable .p-datatable-thead',
    //       '.AddUserTable table thead',
    //       '.cssTable table thead',
    //       '.customTable thead',
    //       '.autoTable thead',
    //       '.maintable thead'
    //     ];

    //     // Loop through each selector and apply the background style if the element is found
    //     selectors.forEach(selector => {
    //       const thead = document.querySelector(selector);
    //       if (thead instanceof HTMLElement) {
    //         thead.style.background = '#0051AC';
    //       }
    //     });
    //   }
    // }, 500);
  }

  onPageChange(pageNumber:number){
    this.page = pageNumber;
    this.getUnPublishedNotification();
  }

  getUnPublishedNotification(){
    const ewsNotificationListModel: EWSNotificationListModel = new EWSNotificationListModel()
    ewsNotificationListModel.unpublished = true

    if(this.fromDate && this.toDate){
      ewsNotificationListModel.fromDate = this.fromDate.year + '-' + this.fromDate.month + '-' + this.fromDate.day;
      ewsNotificationListModel.toDate = this.toDate.year + '-' + this.toDate.month + '-' + this.toDate.day;
    }

    ewsNotificationListModel.page = this.page
    ewsNotificationListModel.page_size = this.pageSize

    this.spinner.show()
    this.alertService.getEwsNotifications(ewsNotificationListModel)
    .then((res:any)=>{
     this.notificationList = res;
    })
    .catch((error) => {
      this.spinner.hide();
      if (error.status === 401) {
          this.toast.error('Session Expired');
        this.router.navigateByUrl('auth/login');
      }
      console.log("error", error);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }


  publishEwsNotification(id:number){
    this.spinner.show();
    let published = {
      id,
      published: true
    }
    this.alertService.publishEwsNotification(published)
    .then((res:{success:boolean; data:any})=>{
      if(res.success){
        this.spinner.hide();
        this.router.navigateByUrl('home/alert-history');
      }
    })
    .catch((error) => {
      this.spinner.hide();
      if (error.status === 401) {
          this.toast.error('Session Expired');
        this.router.navigateByUrl('auth/login');
      }
      console.log("error", error);
    })
    .finally(()=>{
      this.spinner.hide();
    })
  }

  editAlert(list:EWSNotificationModel){
    const navigationExtras: NavigationExtras = {
      queryParams: {
        list: JSON.stringify(list),
        isUpdate:true,
        isAlreadyPublished:false,
        isDraft: true
      }
    };
    this.router.navigate(['home/alert-editor/add-region'], navigationExtras);
  }
  showpublish(id: number) {
    this.isPublish = true;
    this.publishId = id;
  }

  cancelpublish() {
    this.isPublish = false;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && (date.after(this.fromDate) || date.equals(this.fromDate))) {
      this.toDate = date;
      this.page = 1;
      this.getUnPublishedNotification();
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  clearFilter() {
    if(this.fromDate && this.toDate){
      this.fromDate = null;
      this.toDate = null;
      this.page = 1;
      this.getUnPublishedNotification();
    }
  }

  formatDate(date: NgbDate): string {
    return `${date.year}-${this.padNumber(date.month)}-${this.padNumber(date.day)}`;
  }

  padNumber(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  get dateRangeText(): string {
    if (this.fromDate && this.toDate) {
      return `${this.formatDate(this.fromDate)} to ${this.formatDate(this.toDate)}`;
    } else if (this.fromDate) {
      return `${this.formatDate(this.fromDate)}`;
    }
    return '';
  }
  toggleExpand(index: number) {
    this.isExpanded[index] = !this.isExpanded[index];
  }
  onAlertRowClick(list: ListEwsNotificationDataModel, isView: boolean) {
    this.isView = isView;
    if(!isView){
      this.isExpanded = [];
      // this.HistoryTable = true;
    }

    // this.alertId = list?.id;
    // this.getAlertHistory(list?.id);
    // this.getEwsNotificationEmails(id);

    // if (list?.file?.length > 0) {
    //   const lastSlashIndex = list.file.lastIndexOf('/');
    //   let fileName = list.file.substring(lastSlashIndex + 1);

    //   fileName = fileName.replace(/^\d*\/?/, '');
    //   fileName = fileName.replaceAll('%20', ' ');
    //   fileName = fileName.replaceAll('%28', '(').replaceAll('%29', ')');

    //   this.fileName = fileName;
    // }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

<div class="form">
  <div ngbNav #nav="ngbNav" [(activeId)]="activeNav" [destroyOnHide]="false">
    <div [ngbNavItem]="navItems[0]">
      <ng-template ngbNavContent>
        <div class="pkgHd-1 mb-3">
          {{packagesPage?.PackagesAndAPI}}
        </div>
      
        <div class="d-flex gap-5">
          <div (click)="onNavChange('Packages')" class="switching-btn">
            <p>{{packagesPage?.Packages}}</p>
            <span class="circle"></span>
          </div>
          <div (click)="onNavChange('APIgroups')" class="switching-btn">
            <p>{{packagesPage?.API || 'API'}}</p>
            <span class="circle"></span>
          </div>
        </div>
      </ng-template>
    </div>
    <div [ngbNavItem]="navItems[1]" [destroyOnHide]="true">
      <ng-template ngbNavContent>
        <div class="pkgHd-1 mb-3">
          {{packagesPage?.PackagesAndAPI}}
        </div>

        <div class="d-flex gap-5 mb-5">
          <div (click)="onNavChange('Packages')" class="switching-btn active">
            <p>{{packagesPage?.Packages}}</p>
            <span class="circle"></span>
          </div>
          <div (click)="onNavChange('APIgroups')" [ngClass]="disableAPIgroups ? 'disable' : ''" class="switching-btn">
            <p>{{packagesPage?.API || 'API'}}</p>
            <span class="circle"></span>
          </div>
        </div>
      
        <form [formGroup]="packageFormGroup">
          <div class="pkgHd-1 mb-3">
            {{packagesPage?.AddPackageDetails || "Add Package Details"}}
          </div>
          <div class="form-group-container d-flex flex-wrap gap-3">
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
              <mat-label>{{packagesPage?.Name}}</mat-label>
              <input formControlName="name" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
              appearance="outline">
              <mat-label>{{packagesPage?.PriceInSAR}}</mat-label>
              <input (keypress)="preventAlphabets($event)" formControlName="price" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
              appearance="outline">
              <mat-label>{{packagesPage?.MaxLocations}}</mat-label>
              <input (keypress)="preventAlphabets($event)" formControlName="max_locations" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
              appearance="outline">
              <mat-label>{{packagesPage?.MaxUsers}}</mat-label>
              <input (keypress)="preventAlphabets($event)" formControlName="max_users" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
              appearance="outline">
              <mat-label>{{packagesPage?.TenureinYears}}</mat-label>
              <input (keypress)="preventAlphabets($event)" (input)="validateInput($event)" formControlName="tenure" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
              <mat-label>{{packagesPage?.ApiGroup}}</mat-label>
              <mat-select formControlName="api_groups" multiple>
                <mat-select-trigger>
                  <ng-container *ngFor="let ugroups of apiGroups">
                    <ng-container *ngIf="packageFormGroup.controls.api_groups.value?.[0] === undefined">
                      <ng-container *ngIf="packageFormGroup.controls.api_groups.value?.[1] === ugroups?.id">
                        {{ugroups?.name}}
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="packageFormGroup.controls.api_groups.value?.[0] !== undefined">
                      <ng-container *ngIf="packageFormGroup.controls.api_groups.value?.[0] === ugroups?.id">
                        {{ugroups?.name}}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <span class="additional-selection" *ngIf="(packageFormGroup.controls.api_groups.value?.length || 0) > 1">
                    <ng-container *ngIf="packageFormGroup.controls.api_groups.value?.[0] === undefined">
                      (+{{ (packageFormGroup.controls.api_groups.value?.length || 0) - 2 }}
                      {{ packageFormGroup.controls.api_groups.value?.length === 2 ? "other" : "others" }})
                    </ng-container>
                    <ng-container *ngIf="packageFormGroup.controls.api_groups.value?.[0] !== undefined">
                      (+{{ (packageFormGroup.controls.api_groups.value?.length || 0) - 1 }}
                      {{ packageFormGroup.controls.api_groups.value?.length === 2 ? "other" : "others" }})
                    </ng-container>
                  </span>
                </mat-select-trigger>
                <mat-option selectAll [allValues]="allAPIgIds" style="min-height: 40px;">{{packagesPage?.SelectAll}}</mat-option>
                <input matInput [formControl]="filterAPIgroups" [placeholder]="packagesPage?.Search" matSelectFilter
                  [section]="'ApiGroup'" [control]="filterAPIgroups" [items]="apiGroups" [language]="language"
                  (filteredItems)="updateFilteredItems($event, 'ApiGroup')" class="packageinputfilter">
                <ng-container *ngFor="let group of filterapiGroups">
                  <mat-option [value]="group.id" *ngIf="group?.name">{{ language == 'en' ? group?.name : group?.name_ar
                    }}</mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
      
          <div class="d-flex justify-content-start align-items-center gap-4 mt-30 flex-wrap">
            <button (click)="onSubmit()" class="save-btn">
              <i class="far fa-save"></i>
              {{packagesPage?.Save}}
            </button>
            <button (click)="closeWindow.emit()" class="cancel-btn">
              <i class="fas fa-times"></i>
              {{packagesPage?.Cancel}}
            </button>
          </div>
        </form>
      </ng-template>
    </div>
    <div [ngbNavItem]="navItems[2]" [destroyOnHide]="true">
      <ng-template ngbNavContent>
        <div class="pkgHd-1 mb-3">
          {{packagesPage?.PackagesAndAPI}}
        </div>
        
        <div class="d-flex gap-5 mb-5">
          <div (click)="onNavChange('Packages')" [ngClass]="disablePackages ? 'disable' : ''" class="switching-btn">
            <p>{{packagesPage?.Packages}}</p>
            <span class="circle"></span>
          </div>
          <div (click)="onNavChange('APIgroups')" class="switching-btn active">
            <p>{{packagesPage?.API || 'API'}}</p>
            <span class="circle"></span>
          </div>
        </div>
      
        <form [formGroup]="APIFormGroup">
          <div class="pkgHd-1 mb-3">
            {{packagesPage?.AddAPIDetails || "Add API Details"}}
          </div>
          <div class="form-group-container d-flex flex-wrap gap-3">
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'" class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
              <mat-label>{{packagesPage?.Name}}</mat-label>
              <input formControlName="name" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
              appearance="outline">
              <mat-label>{{packagesPage?.PriceInSAR}}</mat-label>
              <input (keypress)="preventAlphabets($event)" formControlName="price" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="mat-input-field-container sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12"
              appearance="outline">
              <mat-label>{{packagesPage?.TenureinYears}}</mat-label>
              <input (keypress)="preventAlphabets($event)" (input)="validateInput($event)" formControlName="tenure" type="text" matInput>
            </mat-form-field>
      
            <mat-form-field [dir]="language === 'en' ? 'ltr' : 'rtl'"
              class="custom-dropdown sameHeight pack-top-mat mb-2 col-xl-2 col-lg-4 col-md-6 col-12" appearance="outline">
              <mat-label>{{packagesPage?.ThrottlingPolicy || 'Throttling Policy'}}</mat-label>
              <mat-select formControlName="throttling">
                <input matInput [formControl]="filterThrottling" [placeholder]="packagesPage?.Search" matSelectFilter
                  [section]="'ThrottlingPolicy'" [control]="filterThrottling" [items]="throttlingData" [language]="language"
                  (filteredItems)="updateFilteredItems($event, 'ThrottlingPolicy')" class="packageinputfilter">
                <ng-container *ngFor="let group of filterthrottlingData">
                  <mat-option [value]="group.id">
                    {{ group?.police_display_name }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
      
          <div class="d-flex justify-content-start align-items-center gap-4 mt-30 flex-wrap">
            <button (click)="onSubmitAPI()" class="save-btn">
              <i class="far fa-save"></i>
              {{packagesPage?.Save}}
            </button>
            <button (click)="closeWindow.emit()" class="cancel-btn">
              <i class="fas fa-times"></i>
              {{packagesPage?.Cancel}}
            </button>
          </div>
        </form>
      </ng-template>
    </div>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
<div class="main-container">
  <div class="page-titlealert">
    <i class="fas fa-caret-right"></i>
    <p class="active">{{ mannedpreviewPage?.MannedForecasts }}</p>
  </div>
  <!-- <div class="locationBG justify-content-between">
    <div class="addLocHeading">
      <img class="headerIcon" src="../../../assets/mannedforecast/mannedforecast-icon.png" />
      <h3>{{ mannedpreviewPage?.MannedForecasts }}</h3>
    </div>
  </div> -->
  <p-toolbar class="row dome" style="margin: inherit;">
    <div class="p-toolbar-group-left col justify-content-between">
      <div class="d-flex gap-2 align-items-center">
        <img src="../../../assets/mannedforecast/mannedforecast-icon.png" style="scale: 0.7;">
        <div class="alert-history">{{ mannedpreviewPage?.AutomaticWeatherReports }}</div>
      </div>
    </div>
  </p-toolbar>
  <div class="previewTablediv">
    <div class="previewTable">
      <div class="tableheading row pt-3 pb-2 mx-5 align-items-center">
        <h3 class="col-lg-11 col-md-6 col-sm-6 col-6 p-0">{{ mannedpreviewPage?.TenDayForecast }}</h3>
        <div class="copy-excel-print col-lg-1 col-md-6 col-sm-6 col-6 px-0 d-none">
          <button class="col-lg-6 col-md-6 col-sm-6 col-6" [ngbTooltip]="mannedpreviewPage?.Pdf" tooltipClass="custom-tooltip">
            <img src="../../../../assets/mannedforecast/pdfpreview-icon.svg">
          </button>
          <button class="col-lg-6 col-md-6 col-sm-6 col-6" [ngbTooltip]="mannedpreviewPage?.Print" tooltipClass="custom-tooltip">
            <img src="../../../../assets/mannedforecast/printpreview-icon.svg">
          </button>
        </div>
      </div>

      <div class="maintable mx-5 mb-5 mt-3">
        <table class="w-100">
          <thead>
            <tr>
              <th>
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/locationspreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Locations }}</p>
                </div>
              </th>
              <th>
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/daterangepreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.DateRange }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.weathersymbol]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/currentweatherpreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.CurrentWeather }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.tmin]">
                <div class="d-flex flex-column align-items-center mx-auto" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/temperaturepreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.TemperatureMinimum }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.tmax]">
                <div class="d-flex flex-column align-items-center mx-auto" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/temperaturepreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.TemperatureMaximum }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.windspeed]">
                <div class="d-flex flex-column align-items-center mx-auto" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/windspeedpreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.WindSpeed }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.winddirection]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/winddirectionpreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.WindDirection }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.windpressure]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/windpressurepreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Pressure }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.precipitation]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/precipitationpreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Precipitation }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.humidity]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/humiditypreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Humidity }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.probability]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/probabilitypreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Probability }}</p>
                </div>
              </th>
              <th *ngIf="allPreviewData?.[0].parameters[ParametersMap.slipperyroad]">
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/slipperyroadpreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.SlipperyRoad }}</p>
                </div>
              </th>
              <!-- <th *ngIf="allPreviewData[0].parameters[ParametersMap.dust]">
                <div class="gap-1 pt-1" style="display: grid; justify-items: center;">
                  <img src="../../../../assets/mannedforecast/dustpreview-icon.png">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Dust }}</p>
                </div>
              </th> -->
              <th>
                <div class="d-flex flex-column align-items-center" style="width: max-content;">
                  <img src="../../../../assets/mannedforecast/actionspreview-icon.png" style="height: 35px;">
                  <p class="tableheadtext">{{ mannedpreviewPage?.Actions }}</p>
                </div>
              </th>
            </tr>
          </thead>
          <tbody class="accordion" id="accordionExample">
            <ng-container class="accordion-item" *ngFor="let item of allPreviewData; index as i">
              <tr class="accordion-header m-0" [id]="'headingOne'+i">
                  <!-- <div class="d-flex flex-row w-100 align-items-center justify-content-between"> -->
                  <td class="accordion-button accrdnheadertext" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="true" [attr.aria-controls]="'collapseOne'+i" *ngIf="language == 'en'">{{ allPreviewData[i]?.cityEn }}</td>
                  <td class="accordion-button accrdnheadertext" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="true" [attr.aria-controls]="'collapseOne'+i" *ngIf="language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.cityAr }}</td>

                  <td class="accrdnheadertext" *ngIf="language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.weathersymbol]?.[0]?.date | date: 'dd/MM/yyyy':"":"en" }}</td>
                  <td class="accrdnheadertext" *ngIf="language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.weathersymbol]?.[0]?.date | date: 'yyyy/MM/dd':"":"ar" }}</td>

                  <td class="accrdnheadertext" *ngIf="item?.parameters[ParametersMap.weathersymbol] && language == 'en'"><img style="width: 46px; height: 46px;" [src]="'../../../../assets/NCM_gray icons/'+ item?.parameters[ParametersMap.weathersymbol]?.[0]?.value +'.svg'"> 
                    {{ item?.parameters[ParametersMap.weathersymbol]?.[0]?.nameEn ? item?.parameters[ParametersMap.weathersymbol]?.[0]?.nameEn : Numberify(item?.parameters[ParametersMap.weathersymbol]?.[0]?.value) | weatherDescription }}
                  </td>
                  <td class="accrdnheadertext" *ngIf="item?.parameters[ParametersMap.weathersymbol] && language == 'ar'"><img style="width: 46px; height: 46px;" [src]="'../../../../assets/NCM_gray icons/'+ item?.parameters[ParametersMap.weathersymbol]?.[0]?.value +'.svg'">
                    {{ item?.parameters[ParametersMap.weathersymbol]?.[0]?.nameAr ? item?.parameters[ParametersMap.weathersymbol]?.[0]?.nameAr : Numberify(item?.parameters[ParametersMap.weathersymbol]?.[0]?.value) | weatherDescriptionAR }}
                  </td>

                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.tmin] && language == 'en'">
                    {{ allPreviewData[i]?.parameters[ParametersMap.tmin]?.[0]?.value }}°</td>

                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.tmax]  && language == 'ar'" >
                    {{ allPreviewData[i]?.parameters[ParametersMap.tmax]?.[0]?.value }}° </td>

                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.tmax] && language == 'en'"> {{ allPreviewData[i]?.parameters[ParametersMap.tmax]?.[0]?.value }}°</td>
                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.tmax] && language == 'ar'" >
                    {{ allPreviewData[i]?.parameters[ParametersMap.tmax]?.[0]?.value }}°
                  </td>

                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.windspeed] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.windspeed]?.[0]?.value }} {{ mannedpreviewPage?.kmhr }}</td>
                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.windspeed] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.windspeed]?.[0]?.value }} {{ mannedpreviewPage?.kmhr }}</td>

                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.winddirection] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.winddirection]?.[0]?.value | windDirectionCriteria }}</td>
                  <td class="accrdnheadertext text-center" *ngIf="allPreviewData[i]?.parameters[ParametersMap.winddirection] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.winddirection]?.[0]?.value | windDirectionCriteria }}</td>

                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.windpressure] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.windpressure]?.[0]?.value }}hPa</td>
                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.windpressure] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.windpressure]?.[0]?.value }}hPa</td>

                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.precipitation] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.precipitation]?.[0]?.value }}%</td>
                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.precipitation] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.precipitation]?.[0]?.value }}%</td>

                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.humidity] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.humidity]?.[0]?.value }}%</td>
                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.humidity] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.humidity]?.[0]?.value }}%</td>

                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.probability] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.probability]?.[0]?.value }}%</td>
                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.probability] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.probability]?.[0]?.value }}%</td>

                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.slipperyroad] && language == 'en'">{{ allPreviewData[i]?.parameters[ParametersMap.slipperyroad]?.[0]?.value | slipperyCriteria: language }}</td>
                  <td class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.slipperyroad] && language == 'ar'" style="text-align: right;">{{ allPreviewData[i]?.parameters[ParametersMap.slipperyroad]?.[0]?.value | slipperyCriteria: language }}</td>
                  <!-- <p class="accrdnheadertext" *ngIf="allPreviewData[i]?.parameters[ParametersMap.dust]">{{ allPreviewData[i]?.parameters[ParametersMap.dust]?.[0]?.value }}</p> -->
                  <td class="">
                    <!-- <p style="color: transparent;">a</p> -->
                    <img src="../../../../assets/mannedforecast/editpreview-icon.png" style="height: 1.6rem;">
                  </td>
                  <!-- </div> -->
              </tr>
              <!-- <div> class="accordion-body"-->
                <tr [id]="'collapseOne'+i" class="accordion-collapse " [ngClass]="i == 0 ? 'show':'collapse'" [attr.aria-labelledby]="'headingOne'+i" data-bs-parent="#accordionExample"
                  *ngFor="let bodydata of item.parameters[ParametersMap.weathersymbol]; index as j">
                  <td class="accrdnbodytext">{{ item?.city }}</td>
                  <td class="accrdnbodytext" *ngIf="language == 'en'">{{ item?.parameters[ParametersMap.weathersymbol]?.[j]?.date | date: 'dd/MM/yyyy':"":"en" }}</td>
                  <td class="accrdnbodytext" *ngIf="language == 'ar'">{{ item?.parameters[ParametersMap.weathersymbol]?.[j]?.date | date: 'yyyy/MM/dd':"":"ar" }}</td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.weathersymbol] && language == 'en'"><img style="width: 46px; height: 46px;" [src]="'../../../../assets/NCM_gray icons/'+ item?.parameters[ParametersMap.weathersymbol]?.[j]?.value +'.svg'"> 
                    {{ item?.parameters[ParametersMap.weathersymbol]?.[j]?.nameEn ? item?.parameters[ParametersMap.weathersymbol]?.[j]?.nameEn : Numberify(item?.parameters[ParametersMap.weathersymbol]?.[j]?.value) | weatherDescription }}
                  </td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.weathersymbol] && language == 'ar'"><img style="width: 46px; height: 46px;" [src]="'../../../../assets/NCM_gray icons/'+ item?.parameters[ParametersMap.weathersymbol]?.[j]?.value +'.svg'">
                    {{ item?.parameters[ParametersMap.weathersymbol]?.[j]?.nameAr ? item?.parameters[ParametersMap.weathersymbol]?.[j]?.nameAr : Numberify(item?.parameters[ParametersMap.weathersymbol]?.[j]?.value) | weatherDescriptionAR }}
                  </td>
                  <td class="accrdnbodytext  text-center" *ngIf="item?.parameters[ParametersMap.tmin]">
                    {{ item?.parameters[ParametersMap.tmin]?.[j]?.value }}°
                  </td>
                  <td class="accrdnbodytext text-center" *ngIf="item?.parameters[ParametersMap.tmax]">
                    {{ item?.parameters[ParametersMap.tmax]?.[j]?.value }}°
                  </td>
                  <td class="accrdnbodytext text-center" *ngIf="item?.parameters[ParametersMap.windspeed]">{{ item?.parameters[ParametersMap.windspeed]?.[j]?.value }} {{ mannedpreviewPage?.kmhr }}</td>
                  <td class="accrdnbodytext text-center" *ngIf="item?.parameters[ParametersMap.winddirection]">{{ item?.parameters[ParametersMap.winddirection]?.[j]?.value | windDirectionCriteria }}</td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.windpressure]">{{ item?.parameters[ParametersMap.windpressure]?.[j]?.value }}hPa</td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.precipitation]">{{ item?.parameters[ParametersMap.precipitation]?.[j]?.value }}%</td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.humidity]">{{ item?.parameters[ParametersMap.humidity]?.[j]?.value }}%</td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.probability]">{{ item?.parameters[ParametersMap.probability]?.[j]?.value }}%</td>
                  <td class="accrdnbodytext" *ngIf="item?.parameters[ParametersMap.slipperyroad]">{{ item?.parameters[ParametersMap.slipperyroad]?.[j]?.value | slipperyCriteria: language }}</td>
                  <!-- <p class="accrdnbodytext">{{ item?.parameters[ParametersMap.dust]?.[j]?.value }}</p> -->
                  <td class="">
                    <!-- (click)="show()" -->
                    <button type="button" class="" (click)="openPreviewModal(allPreviewData,i,j)">
                      <img src="../../../../assets/mannedforecast/editpreview-icon.png">
                    </button>
                  </td>
                </tr>
              <!-- </div> -->
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="d-flex justify-content-end align-items-center flex-wrap gap-2">
    <!-- <button (click)="goToMannedForecast()" type="button" class="canceltxt">
      {{ mannedpreviewPage?.Cancel }}
    </button> -->
    <button (click)="goToMannedForecast()" type="button" class="publishbtn">
      {{ mannedpreviewPage?.Back }}
    </button>
    <button [disabled]="isReportPublishing" (click)="publishReport()" type="button" class="previewbtn">
      {{ mannedpreviewPage?.Publish }}
    </button>
  </div>
</div>




<!-- <h1 mat-dialog-title>Unsaved Changes</h1>
<div mat-dialog-content>
  <p>You have unsaved changes. Do you want to save them before leaving?</p>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onSave()">Save</button>
  <button mat-button (click)="onDiscard()">Discard</button>
  <button mat-button (click)="onCancel()">Cancel</button>
</div> -->
<div class="deletemodalbg">
    <div class="deletemodal unsaved">
      <i class="far fa-times-circle dltcross" (click)="onCancel()"></i>
      <h5 style="font-weight: 500; text-align: center;">
        {{clientsPage?.UnsavedChanges}}
      </h5>
      <p style="font-size: 2em; font-weight: 400; color: #0051AC; text-align: center; margin-bottom: 2vw;">
        {{clientsPage?.Youhaveunsavedchanges}}.
        <br>
        {{clientsPage?.Doyouwanttosavethembeforeleaving}}
      </p>
      <div class="polyBtnDiv d-flex align-items-center justify-content-center gap-5 btn-div">
        <button class="save-btn" (click)="onSave()">
          <i class="fas fa-check"></i>
          {{clientsPage?.Yes}}
        </button>
        <button class="cancel-btn" (click)="onDiscard()">
          <i class="fas fa-times"></i>
          {{clientsPage?.No}}
        </button>
      </div>
    </div>
</div>
<div id="aside" class="aside">
  <div (click)="toggleNav()" [ngClass]="!isNavOpen ? 'justify-content-center' : 'justify-content-end'"
    class="row justify-content-end custom-border-bottom pd-1" style="padding-right: 0 !important;">
    <ng-container *ngIf="language === 'en'">
      <img *ngIf="isNavOpen" class="menu-toggle" src="../../../assets/menu-toggle-open.svg" alt="close menu"
        style="margin-right: 1.2vw; width: 3.5rem;" />
      <img *ngIf="!isNavOpen" class="menu-toggle" src="../../../assets/menu-toggle-close.svg" alt="open menu"
        style="width: 3.5rem" />
    </ng-container>
    <ng-container *ngIf="language === 'ar'">
      <img *ngIf="!isNavOpen" class="menu-toggle" src="../../../assets/menu-toggle-open.svg" alt="close menu"
        style="margin-right: 1.2vw; width: 3.5rem" />
      <img *ngIf="isNavOpen" class="menu-toggle" src="../../../assets/menu-toggle-close.svg" alt="open menu"
        style="width: 3.5rem" />
    </ng-container>
  </div>
  <div [ngClass]="!isNavOpen ? 'px-1point4' : 'px-0'" class="row justify-content-center">

    <button placement="right"
      (click)="navigate('home/dashboard', 0)"
      class="mx-auto col-9 d-flex gap-2 align-items-center py-4 custom-border-bottom pd-1">
      <img class="sidebar-icon" src="../../../assets/dashboard.PNG" alt="dashboard icon" />
      <div *ngIf="isNavOpen" [class.linkActive]="isActive(links[0].url)" class="sidebar-title">
        {{ aside?.Dashboard }}
      </div>
    </button>


    <button
    [ngClass]="permissions?.list_organization ? '' : 'disabled'"
      [disableTooltip]="permissions?.list_organization"
      placement="right"
      tooltipClass="custom-tooltip-right"
      [ngbTooltip]="contact"
      (click)="permissions?.list_organization ? navigate('home/clients', 2) : null"
      class="mx-auto col-9 d-flex gap-2 justify-content-between align-items-center pd-1">
      <div class="d-flex gap-2 align-items-center">
        <i class="fas fa-user cool-grey ms-2"></i>
        <div *ngIf="isNavOpen" [class.linkActive]="isActive(links[2].url)" class="sidebar-title ms-2">
          {{ aside?.clients }}
        </div>
      </div>
      <!-- <div *ngIf="isNavOpen">
        <i class="fa fa-angle-down down"></i>
      </div> -->
    </button>


    <button
    [ngClass]="permissions?.list_ncm_user ? '' : 'disabled'"
    [disableTooltip]="permissions?.list_ncm_user"
    tooltipClass="custom-tooltip-right"
      type="button"
      placement="right"
      [ngbTooltip]="contact"
      (click)="permissions?.list_ncm_user ? navigate('home/users', 3) : null"
      class="mx-auto col-9 d-flex gap-2 justify-content-between align-items-center pd-1">
      <div class="d-flex gap-2 align-items-center">
        <img class="sidebar-icon" src="../../../assets/users.PNG" alt="dashboard icon" />
        <div *ngIf="isNavOpen" [class.linkActive]="isActive(links[3].url)" class="sidebar-title">
          {{ aside?.Users }}
        </div>
      </div>
      <!-- <div *ngIf="isNavOpen">
        <i class="fa fa-angle-down down"></i>
      </div> -->
    </button>

    <!-- <button placement="right" [disableTooltip]="permissions?.get_weather" [ngbTooltip]="contact"
      tooltipClass="custom-tooltip-right" [ngClass]="!permissions?.get_weather ? 'disabled' : ''"
      (click)="permissions?.get_weather ? navigate('home/public-users', 8) : null" class="
       col-9 d-flex gap-2 justify-content-between align-items-center pd-1">
      <div class="d-flex gap-2 align-items-center">
        <i class="fas fa-user cool-grey ms-2"></i>
        <div [class.linkActive]="isActive(links[8].url)" *ngIf="isNavOpen" class="sidebar-title">
          Public Users
        </div>
      </div>
      <!-- <div *ngIf="isNavOpen">
        <i class="fa fa-angle-down down"></i>
      </div>
    </button> -->

    <button placement="right"
      (click)="navigate('home/locations', 4)"
      class="mx-auto col-9 d-flex gap-2 justify-content-between align-items-center pd-1 custom-border-bottom">
      <div class="d-flex gap-2 align-items-center">
        <img class="sidebar-icon" src="../../../assets/locations.PNG" alt="dashboard icon" />
        <div *ngIf="isNavOpen" [class.linkActive]="isActive(links[4].url)" class="sidebar-title">
          {{ aside?.Locations }}
        </div>
      </div>
      <!-- <div *ngIf="isNavOpen">
        <i class="fa fa-angle-down down"></i>
      </div> -->
    </button>

    <button (click)="navigate('home/profile', 5)"
      class="mx-auto col-9 d-flex gap-2 align-items-center pd-1 custom-border-bottom">
      <img class="sidebar-icon" src="../../../assets/profile.PNG" alt="dashboard icon" />
      <div *ngIf="isNavOpen" [class.linkActive]="isActive(links[5].url)" class="sidebar-title">
        {{ aside?.Profile }}
      </div>
    </button>

    <button (click)="navigate('auth/login')"
      class="mx-auto col-9 d-flex gap-2 align-items-center pd-1 custom-border-bottom">
      <img class="sidebar-icon" src="../../../assets/logout.PNG" alt="dashboard icon" />
      <div *ngIf="isNavOpen" class="sidebar-title">{{ aside?.Logout }}</div>
    </button>
  </div>
</div>



<ng-template #contact>
  <p class="tooltip-message">Contact Your Administrator</p>
</ng-template>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorFormat'
})
export class ColorFormatPipe implements PipeTransform {
  transform(colorName: string): string {
    switch (colorName) {
      case 'Orange':
        return '#FF6600';
      case 'Red':
        return '#F60000';
      case 'Yellow':
        return '#FCB825';
      // Add more color mappings as needed
      default:
        return colorName; // Return the input color if no match found
    }
  }
}

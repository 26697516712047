<div class="modal-header">
  <div class="modal-title">
    <ng-container *ngIf="policy">
      <h5>{{policyPage?.PrivacyPolicy}}</h5>
    </ng-container>
    <ng-container *ngIf="terms">
      <h5>{{policyPage?.TermsOfUse}}</h5>
    </ng-container>
  </div>
  <div (click)="closeModal()" class="header modal-close-icon">
    <i class="far fa-times-circle"></i>
  </div>
</div>
<div class="body">
    <ng-container *ngIf="terms">
      <div [innerHTML]="language  === 'en' ? termsEn : termsAr"></div>
    </ng-container>
    <ng-container *ngIf="policy">
      <div [innerHTML]="language  === 'en' ? policyEn : policyAr"></div>
    </ng-container>
</div>


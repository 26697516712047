<div class="d-flex h-100 w-100">
  <div class="w-100 h-100 d-flex flex-column" id="right-side">

    <header>
      <app-header></app-header>
    </header>

    <section class="section-main f1 flex-column m-0">
      <router-outlet></router-outlet>
    </section>

    <footer>
      <p style="margin: 0; font: normal normal 500 1vw Roboto !important;" class="cool-grey">{{full?.Copyright2022NationalcenterofMeteorologyAllrightsreserved}}</p>
    </footer>

  </div>
</div>

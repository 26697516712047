import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-unsaved-changes-dialog',
  templateUrl: './unsaved-changes-dialog.component.html',
  styleUrls: ['./unsaved-changes-dialog.component.scss']
})
export class UnsavedChangesDialogComponent implements OnInit {
  private subscription: Subscription = new Subscription();
  language: string;
  clientsPage: any;

  constructor(
    private translate: TranslateService,
    private languageService: LanguageService,
    public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>
  ) {}

  ngOnInit(): void {
    this.getCurrentLanguage();
  }

  getCurrentLanguage() {
    this.subscription.add(this.languageService.currentLanguage.subscribe((language: string) => {
      this.language = language;
      this.translate.use(language);
      this.translate.get("Clients").subscribe((res) => {
        this.clientsPage = res;
      });
    }));
  }

  onSave(): void {
    this.dialogRef.close('save');
  }

  onDiscard(): void {
    this.dialogRef.close('discard');
  }

  onCancel(): void {
    this.dialogRef.close('cancel');
  }
}
